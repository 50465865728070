import { lazy } from 'react';

import { useModal } from '~/components/modal';

const DeleteWorkspaceModal = lazy(() => import('~/features/workspaces/modals/DeleteWorkspaceModal'));

function useDeleteWorkspace() {
  const { open } = useModal(DeleteWorkspaceModal);
  return open;
}

export { useDeleteWorkspace };
