import { styled } from '~/utils/styling';

const MetaSticker = styled('div', {
  padding: '$wee',
  borderRadius: '$tiny',
  lineHeight: 1,
  fontSize: '$xs',
  background: '$dark-1000',
  color: '$light-1000',
  fontWeight: '$bold'
});

export { MetaSticker };
