import { useCallback } from 'react';

import { useRouter } from '~/utils/routing/useRouter';

import { useControlledModal } from './useControlledModal';

type UseRoutedModalOptions = {
  queryParam: string;
  props?: { [key: string]: any };
  close?: (args: any) => void | Promise<void>;
  skip?: boolean;
};

function useRoutedModal(Component: Parameters<typeof useControlledModal>[0], options: UseRoutedModalOptions) {
  const router = useRouter();

  const isOpen = router.query[options.queryParam] && !options.skip ? options.props || true : false;
  const close = useCallback(async () => {
    // TODO: see if this can be simplified like we did with useRoutedAction
    // ref https://github.com/VouchAU/vouch-mono/pull/542#discussion_r1125764465
    const urlPath = window?.location?.pathname;
    const urlQuery = new URLSearchParams(window?.location?.search || {});
    const urlQueryEntries = urlQuery.entries();

    const newQuery = { ...Object.fromEntries(urlQueryEntries) };
    delete newQuery[options.queryParam];
    const newRoute = { pathname: urlPath, query: newQuery };

    await options.close?.({ route: newRoute });
    router.push(newRoute);
  }, [router, options]);

  return useControlledModal(Component, { isOpen, close });
}

export { useRoutedModal };
