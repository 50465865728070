import { useMemo, type ComponentProps } from 'react';

import { ListItemHeader } from '~/components/header';
import { MaybeUndefined } from '~/features/global/components/MaybeUndefined';
import { useI18n } from '~/utils/i18n';

type CampaignListItemHeaderProps = {
  data: any;
  actions: ComponentProps<typeof ListItemHeader>['actions'];
  size?: 'tiny' | 'small' | 'medium' | 'large';
};

function CampaignListItemHeader({ data, actions, size = 'medium' }: CampaignListItemHeaderProps) {
  const { simplePlural, formatRelativeTimeRangeShort } = useI18n();

  const numQuestions = simplePlural(data?.questions?.items?.length, 'one {# question} other {# questions}');

  const meta = useMemo(() => {
    let meta: ComponentProps<typeof ListItemHeader>['meta'] = [];

    if (data.status === 'LIVE') {
      meta = meta.concat([{ label: data?.private ? 'Private' : 'Public' }]);
    }

    meta = meta.concat([
      { label: `Created ${formatRelativeTimeRangeShort(new Date(data?.createdAt))} ago` },
      { label: numQuestions }
    ]);

    if (data.status !== 'DRAFT') {
      const numResponses = simplePlural(data?.responseCount, 'one {# response} other {# responses}');
      meta.push({ label: numResponses });
    }

    return meta;
  }, [
    data?.createdAt,
    data?.private,
    data?.responseCount,
    data.status,
    formatRelativeTimeRangeShort,
    numQuestions,
    simplePlural
  ]);

  return (
    <ListItemHeader
      title={<MaybeUndefined label={data?.name} fallback="Untitled request" />}
      meta={meta}
      actions={actions}
      gap={false}
      size={size}
    />
  );
}

export { CampaignListItemHeader };
