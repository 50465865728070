import { useQuery } from '~/utils/graphql';
import { usePermissions } from '~/utils/permissions';

import { GET_SUBSCRIPTION_INVOICES_QUERY } from '~/queries/get-subscription-invoices-query';
import { GET_SUBSCRIPTION_QUERY } from '~/queries/get-subscription-query';

function useSubscriptionPlan() {
  const permissions = usePermissions();
  const isAllowed = permissions.canUseFeature('billing:read');

  const subscriptionQuery = useQuery(GET_SUBSCRIPTION_QUERY, { skip: !isAllowed });
  const invoicesQuery = useQuery(GET_SUBSCRIPTION_INVOICES_QUERY, { skip: !isAllowed });

  // We don't want to show "upgrade" messages when the user is already on the highest plan
  const canUpgrade = !permissions.isTeams;

  return {
    loading: isAllowed ? subscriptionQuery?.loading || invoicesQuery?.loading : false,
    data: isAllowed ? subscriptionQuery?.data?.subscription : undefined,
    invoices: isAllowed ? invoicesQuery?.data?.invoices : undefined,
    canUpgrade
  };
}

export { useSubscriptionPlan };
