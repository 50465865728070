import { gql } from '~/utils/codegen';

export const CREATE_PLAYLIST_MUTATION = gql(/* GraphQL */ `
  mutation CreatePlaylist($name: String!, $items: [PlaylistAnswerInput], $type: VouchType) {
    createPlaylist(name: $name, items: $items, type: $type) {
      ...TemplateVouch
      id
      name
      status
      questions {
        items {
          id
          title
          answer {
            id
            label
            vouch {
              hashId
            }
            question {
              title
              ordinality
            }
          }
        }
      }
    }
  }
`);
