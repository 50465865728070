import { gql } from '~/utils/codegen';

export const UPDATE_ENTITY_MUTATION = gql(/* GraphQL */ `
  mutation UpdateEntity($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      id
      name
    }
  }
`);
