import { useCallback, useEffect, useMemo } from 'react';

import { useRouter } from '~/utils/routing/useRouter';

type UseRoutedModalOptions = {
  queryParam: string;
  action: (value: string | string[]) => void;
};

function useRoutedAction(options: UseRoutedModalOptions) {
  const router = useRouter();

  const value = useMemo(() => router.query[options.queryParam], [router, options.queryParam]);

  const onFired = useCallback(async () => {
    const newQuery = { ...router.query };
    delete newQuery[options.queryParam];
    router.push({ ...router, query: newQuery });
  }, [router, options]);

  const fireAction = useCallback(async () => {
    if (!value) {
      return;
    }
    await options.action(value);
    await onFired();
  }, [options, onFired, value]);

  useEffect(() => {
    fireAction();
  }, [fireAction]);
}

export { useRoutedAction };
