import { LoadingSpinner } from '~/components/loading-spinner';
import { styled } from '~/utils/styling';

const Container = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingTop: '10rem'
});

function FallbackModal() {
  return (
    <Container>
      <LoadingSpinner color="$light-500" />
    </Container>
  );
}

export { FallbackModal };
