import { gql } from '~/utils/codegen';

export const GET_CAMPAIGN_QUESTIONS_QUERY = gql(/* GraphQL */ `
  query GetCampaignQuestions($id: ID!) {
    campaign(id: $id) {
      id
      name
      hashId
      status
      questions {
        items {
          id
          referenceId
          ordinality
          required
          title
          settings {
            optional
            orientation
            type
            maxDuration
            maxTakes
            note
          }
        }
      }
    }
  }
`);
