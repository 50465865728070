import { useCallback } from 'react';

import { useShareActions } from '~/features/share/actions';
import { useAnalyticsEvent } from '~/utils/analytics';
import { getFacebookShareUrl, getLinkedInShareUrl, getTwitterShareUrl } from '~/utils/share';
import { getAppUrl } from '~/utils/urls';

import type { ShareItems } from '~/features/share/types';

function useGetShareItems() {
  const shareActions = useShareActions();
  const event = useAnalyticsEvent();

  return useCallback(
    ({ data }: { data: any }) => {
      const url = getAppUrl(data?.settings?.urls?.watch);
      const title = data.name;

      const twitterUrl = getTwitterShareUrl({ url, title });
      const facebookUrl = getFacebookShareUrl({ url, title });
      const linkedInUrl = getLinkedInShareUrl({ url, title });

      const canShare = typeof navigator !== 'undefined' ? navigator?.canShare?.({ url, title }) : false;

      return {
        url,
        title,
        qrCode: {
          onClick: () => {
            event('playlist_share', { playlist_id: data?.id, medium: 'download_embed_code' });
            shareActions.shareQRCode({ url });
          }
        },
        otherApps: {
          onClick: canShare
            ? () => {
                event('playlist_share', { playlist_id: data?.id, medium: 'native_apps' });
                navigator?.share?.({ title, url }).catch(() => {
                  // Do nothing, `navigator.share` throws when the user cancels
                });
              }
            : undefined
        },
        twitter: {
          onClick: () => {
            event('playlist_share', { playlist_id: data?.id, medium: 'Twitter' });
            window.open(twitterUrl);
          }
        },
        facebook: {
          onClick: () => {
            event('playlist_share', { playlist_id: data?.id, medium: 'Facebook' });
            window.open(facebookUrl);
          }
        },
        linkedIn: {
          onClick: () => {
            event('playlist_share', { playlist_id: data?.id, medium: 'LinkedIn' });
            window.open(linkedInUrl);
          }
        },
        urlShare: () => {
          event('playlist_shared', {
            vouch_id: data?.id,
            campaign_id: data?.campaignId || null,
            medium: 'Url',
            type: null
          });
        }
      } as ShareItems;
    },
    [event, shareActions]
  );
}

export { useGetShareItems };
