import { useEffect, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';

import { Tooltip } from '~/components/tooltip';

import type { ComponentProps, ReactNode, MutableRefObject } from 'react';

type ChildrenArg = { ref: MutableRefObject<any> | ReturnType<typeof mergeRefs> };

type Children = (args: ChildrenArg | Parameters<ComponentProps<typeof Tooltip>['children']>) => ReactNode;

type TruncateWithTooltipProps = Omit<ComponentProps<typeof Tooltip>, 'content' | 'children'> & {
  tooltip: ComponentProps<typeof Tooltip>['content'];
  children: Children;
};

function TruncateWithTooltip({ children, tooltip, ...props }: TruncateWithTooltipProps) {
  const ref = useRef<any>();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (ref.current && ref.current.offsetHeight < ref.current.scrollHeight) {
      setShowTooltip(true);
    }
  }, []);

  // eslint-disable-next-line react/no-children-prop
  return (
    <>
      {showTooltip ? (
        <Tooltip content={tooltip} {...props}>
          {(tooltipProps) =>
            children({
              ...tooltipProps,
              ref: mergeRefs([ref, tooltipProps.ref])
            })
          }
          {}
        </Tooltip>
      ) : (
        children({ ref })
      )}
    </>
  );
}

export { TruncateWithTooltip };
