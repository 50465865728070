import { useCallback } from 'react';

import { useLazyQuery } from '~/utils/graphql';

import { GET_VOUCH_ANSWERS_CROPPING_QUERY } from '~/queries/get-vouch-answers-cropping-query';

function useGetAnswerVouchAnswers() {
  const [fetchVouch] = useLazyQuery(GET_VOUCH_ANSWERS_CROPPING_QUERY);

  const getAnswers = useCallback(
    async ({ ids }: { ids: string[] }) => {
      const res = await Promise.all(ids.map((id) => fetchVouch({ variables: { id } })));
      const items = res.map((r) => r.data?.vouch);
      const answers = items.flatMap((item) => {
        const questions = item?.questions?.items;
        return questions?.map((question: any) => ({
          id: question?.answer?.id,
          settings: question?.answer?.settings
        }));
      });

      return answers.map((answer) => ({
        id: answer?.id,
        settings: {
          endOffset: answer?.settings?.endOffset,
          startOffset: answer?.settings?.startOffset,
          crop: {
            x: answer?.settings?.crop?.x,
            y: answer?.settings?.crop?.y,
            width: answer?.settings?.crop?.width,
            height: answer?.settings?.crop?.height
          }
        }
      }));
    },
    [fetchVouch]
  );

  return { getAnswers };
}

export { useGetAnswerVouchAnswers };
