import { useCallback, useEffect, useRef } from 'react';

import { IconButton } from '~/components/button';
import { Icon } from '~/components/icon';
import { styled } from '~/utils/styling';

import type { ReactNode, ComponentProps } from 'react';

const Container = styled('div', {
  padding: '$tiny',
  borderRadius: '$tiny',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  pointerEvents: 'all',
  maxWidth: '28rem',
  color: '$$notificationColor',
  background: '$$notificationBackground',

  '& button': {
    $$buttonHoverBackground: '$$notificationButtonHoverBackground'
  },

  variants: {
    type: {
      default: {
        $$notificationColor: '$colors$s-brand-50',
        $$notificationBackground: '$colors$s-brand-600',
        $$notificationButtonHoverBackground: '$colors$s-brand-50'
      },
      error: {
        $$notificationColor: '$colors$s-danger-50',
        $$notificationBackground: '$colors$s-danger-500',
        $$notificationButtonHoverBackground: '$colors$s-danger-50'
      }
    }
  }
});

const IconContainer = styled('div', {
  padding: 0,
  borderRadius: '50%',
  height: '$tiny',
  width: '$tiny',
  background: '$light-1000',
  color: '$dark-1000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    display: 'flex'
  }
});

const Message = styled('span', {
  padding: '0 $small 0 $wee'
});

const Title = styled('span', {
  padding: '0 $small 0 $wee',
  fontWeight: '$bold'
});

const TextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '$tiny'
});

type NotificationProps = {
  id: string;
  type?: 'default' | 'error';
  message: ReactNode;
  timeout?: number;
  hide?: () => void;
  icon?: ComponentProps<typeof Icon>['name'];
  title?: string;
};

function Notification({ id, type = 'default', message, hide, timeout = 5000, icon, title }: NotificationProps) {
  // TODO: discuss actual logic for pausing and resuming toasts with the designers
  // e.g. we might want to pause all toasts when hovering any etc.
  // https://vouch.atlassian.net/browse/VCH-2519
  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    timer.current = setTimeout(() => hide?.(), timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePause = useCallback(() => {
    clearTimeout(timer.current);
  }, []);

  const handleResume = useCallback(() => {
    timer.current = setTimeout(() => hide?.(), timeout);
  }, [timeout, hide]);

  return (
    <Container
      role="alert"
      type={type}
      onMouseOver={handlePause}
      onMouseOut={handleResume}
      onFocus={handlePause}
      onBlur={handleResume}
      data-notification-id={id}
    >
      {icon && (
        <IconContainer>
          <Icon name={icon} />
        </IconContainer>
      )}
      <TextContainer>
        {title && <Title>{title}</Title>}
        <Message>{message}</Message>
      </TextContainer>
      <IconButton color="light" size="small" icon="cross" aria-label="Hide notification" onClick={hide} />
    </Container>
  );
}

export { Notification };
