import { gql } from '~/utils/codegen';

export const CREATE_CAMPAIGN_MUTATION = gql(/* GraphQL */ `
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      id
      hashId
      name
    }
  }
`);
