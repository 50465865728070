import '@formatjs/intl-relativetimeformat';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/en-AU';

import { useCallback, useMemo } from 'react';
import { IntlProvider, useIntl } from 'react-intl';

import type { PropsWithChildren } from 'react';
import type { FormatNumberOptions } from 'react-intl';

type I18nProviderProps = PropsWithChildren<{
  locale?: string;
  messages?: { [key: string]: string };
}>;

function I18nProvider({ locale = 'en-AU', messages = {}, children }: I18nProviderProps) {
  const handleError = useCallback((err: any) => {
    // For now we don't care about missing translations, since we're only using this for
    // pluralisation and formatting
    if (err.code === 'MISSING_TRANSLATION') {
      return;
    }
    throw err;
  }, []);

  return (
    <IntlProvider locale={locale} messages={messages} onError={handleError}>
      {children}
    </IntlProvider>
  );
}

const UNITS = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000
} as const;

function useI18n() {
  const intl = useIntl();
  return useMemo(
    () => ({
      ...intl,

      simplePlural: (count = 0, template: string) =>
        intl.formatMessage(
          {
            // We are currently not translating so we don't need unique id's yet
            id: 'simple-plural',
            defaultMessage: `{count, plural, ${template}}`
          },
          { count }
        ),

      formatCurrency: (value: number, currency = 'AUD', options: FormatNumberOptions = {}) =>
        intl.formatNumber(value, { style: 'currency', signDisplay: 'auto', currency, ...options }),

      formatCurrencyShort: (value: number, currency = 'AUD', options: FormatNumberOptions = {}) =>
        intl.formatNumber(value, {
          style: 'currency',
          signDisplay: 'auto',
          currency,
          // Some older browsers set the default minimum digits to 2 and throw an error if the minimum
          // is larger than the maximum, so we set both here, see https://stackoverflow.com/a/41045289
          minimumFractionDigits: value % 1 !== 0 ? 2 : 0,
          maximumFractionDigits: value % 1 !== 0 ? 2 : 0,
          ...options
        }),

      formatDateLong: (value: Date) => intl.formatDate(value, { dateStyle: 'long' }),
      formatMonthDay: (value: Date) => intl.formatDate(value, { month: 'long', day: 'numeric' }),
      formatDateTime: (value: Date) => intl.formatTime(value, { dateStyle: 'medium', timeStyle: 'short' }),
      formatDateTimeLong: (value: Date) => intl.formatTime(value, { dateStyle: 'long', timeStyle: 'short' }),
      formatDateTimeFull: (value: Date) => intl.formatTime(value, { dateStyle: 'full', timeStyle: 'short' }),

      formatDateShort: (value: Date) => {
        const now = new Date();
        const sameYear = value.getFullYear() === now.getFullYear();
        const sameDay = sameYear && value.getMonth() === now.getMonth() && value.getDate() === now.getDate();

        if (sameDay) {
          return intl.formatTime(value, { timeStyle: 'short' });
        }

        if (sameYear) {
          return intl.formatDate(value, { month: 'short', day: 'numeric' });
        }

        return intl.formatDate(value, { year: '2-digit', month: 'short', day: 'numeric' });
      },

      // Relative time range implementation based on this stackoverflow
      // https://stackoverflow.com/a/53800501
      formatRelativeTimeRange: (from: Date, to: Date = new Date()) => {
        const elapsed = from.getTime() - to.getTime();
        if (elapsed <= 0 && elapsed > -30000) {
          return 'moments ago';
        }
        if (elapsed > 0 && elapsed < 30000) {
          return 'right now';
        }

        for (const u in UNITS) {
          if (Math.abs(elapsed) > UNITS[u as keyof typeof UNITS] || u === 'second') {
            return intl.formatRelativeTime(
              // Round toward zero so we don't show confusing numbers
              // e.g. `-1.51` hours should say "1 hour ago" not "2 hours ago"
              // e.g. `3.75` days should say "in 3 days" not "in 4 days"
              elapsed > 0
                ? Math.floor(elapsed / UNITS[u as keyof typeof UNITS])
                : Math.ceil(elapsed / UNITS[u as keyof typeof UNITS]),
              u as Intl.RelativeTimeFormatUnit
            );
          }
        }
      },

      formatRelativeTimeRangeShort: (from: Date, to: Date = new Date()) => {
        const elapsed = from.getTime() - to.getTime();
        for (const u in UNITS) {
          if (Math.abs(elapsed) > UNITS[u as keyof typeof UNITS] || u === 'second') {
            // TODO: this is not internationalised right now, would be good to see if there is a way to get
            // unit short forms through the Intl API as well
            // https://vouch.atlassian.net/browse/VCH-2512

            // Round toward zero so we don't show confusing numbers
            // e.g. `-1.51` hours should say "1h ago" not "2h ago"
            // e.g. `3.75` days should say "in 3d" not "in 4d"
            const value =
              elapsed > 0
                ? Math.floor(elapsed / UNITS[u as keyof typeof UNITS])
                : Math.ceil(elapsed / UNITS[u as keyof typeof UNITS]);
            return `${Math.abs(value)}${u === 'month' ? u.substring(0, 2) : u.substring(0, 1)}`;
          }
        }
      },

      diff: (date: Date, format: 'ms' | 'week' | keyof typeof UNITS = 'ms', compare: Date = new Date()) => {
        const diffMs = date.getTime() - compare.getTime();

        switch (format) {
          case 'ms':
            return diffMs;
          case 'second':
            return diffMs / UNITS.second;
          case 'minute':
            return diffMs / UNITS.minute;
          case 'hour':
            return diffMs / UNITS.hour;
          case 'day':
            return diffMs / UNITS.day;
          case 'week':
            return diffMs / (UNITS.day * 7);
          case 'month':
            return diffMs / UNITS.month;
          case 'year':
            return diffMs / UNITS.year;
        }
      }
    }),
    [intl]
  );
}

export { I18nProvider, useI18n };
