import { useMemo } from 'react';

import { useAnswerPlayerModal } from '~/features/answers/actions/useAnswerPlayerModal';

function useAnswerActions() {
  const playerModal = useAnswerPlayerModal();

  return useMemo(
    () => ({
      playerModal
    }),
    [playerModal]
  );
}

export { useAnswerActions };
