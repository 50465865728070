import { lazy } from 'react';

import { useModal } from '~/components/modal';

const AIPlaylistModal = lazy(() => import('~/features/campaigns/modals/CreateAIPlaylistModal'));

function useCreateAIPlaylist() {
  const { open } = useModal(AIPlaylistModal);
  return open;
}

export { useCreateAIPlaylist };
