import awsExports from '~/config/aws-exports';

type SignUpArg = {
  affiliateId?: string;
  email: string;
  password?: string;
  hashId?: string;
};

async function signUp({ affiliateId, email, password, hashId }: SignUpArg) {
  const publicApi = awsExports?.aws_cloud_logic_custom?.find?.((apis) => apis?.name === 'vouchpublicapi');
  const baseUrl = publicApi?.endpoint ? `${publicApi?.endpoint}/api` : undefined;

  if (!baseUrl) {
    throw new Error('Public API not found');
  }

  const response = await fetch(`${baseUrl}/signup`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      affiliateId,
      email,
      password,
      hashId
    })
  });

  if (response.status >= 400) {
    // TODO: this was taken from CW, we should move these error messages to the BE
    // https://vouch.atlassian.net/browse/VCH-2511
    if (response.status === 400) {
      throw new Error('A user with that email address already exists.');
    }

    if (response.status === 403) {
      throw new Error('This account has been disabled. Please see your account administrator.');
    }

    throw new Error('Authentication failed');
  }
}

export { signUp };
