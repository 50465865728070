import { MetaSticker } from '~/components/sticker/MetaSticker';
import { getResolutionLabel } from '~/features/answers/utils/getResolutionLabel';

type ResolutionStickerProps = {
  value?: string;
};

function ResolutionSticker({ value }: ResolutionStickerProps) {
  const label = getResolutionLabel({ resolution: value });
  if (!label) {
    return null;
  }

  return <MetaSticker>{label}</MetaSticker>;
}

export { ResolutionSticker };
