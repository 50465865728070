import { lazy } from 'react';

import { useModal } from '~/components/modal';

const BulkMovePlaylistsModal = lazy(() => import('~/features/playlists/modals/BulkMovePlaylistsModal'));

function useBulkMovePlaylists() {
  const { open } = useModal(BulkMovePlaylistsModal);
  return open;
}

export { useBulkMovePlaylists };
