import { createContext, Suspense, useContext, useEffect, useMemo, useState } from 'react';

import { ErrorBoundary, ErrorModal } from '~/components/error-boundary';
import { FallbackModal } from '~/components/modal/FallbackModal';
import { styled } from '~/utils/styling';

import type { PropsWithChildren } from 'react';

const Backdrop = styled('div', {
  position: 'fixed',
  zIndex: 200,
  inset: 0,
  background: '$dark-200',
  padding: '$medium',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',

  '@bp-small': {
    padding: '$large'
  }
});

type ModalPassthroughProps = {
  close: () => void;
};

type ModalInstance = {
  id: string;
  Component: any;
  props: any;
  onClose?: () => void | Promise<void>;
  close: () => void;
};

type ModalContextValue = {
  modals: ModalInstance[];
  addModal?: (args: Omit<ModalInstance, 'close'>) => ModalInstance;
};

const ModalContext = createContext<ModalContextValue>({ modals: [] });

function ModalProvider({ children }: PropsWithChildren<Record<never, any>>) {
  const [modals, setModals] = useState<ModalContextValue['modals']>([]);

  const value = useMemo<ModalContextValue>(
    () => ({
      modals,
      addModal: (modal) => {
        const enhancedModal = {
          ...modal,
          close: () => {
            modal?.onClose?.();
            setModals((state) => state.filter((d) => modal.id !== d.id));
          }
        };
        setModals((state) => [...state, enhancedModal]);
        return enhancedModal;
      }
    }),
    [modals]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (modals.length > 0) {
        window.document.body.classList.add('noscroll');
      } else {
        window.document.body.classList.remove('noscroll');
      }
    }
  }, [modals.length]);

  return (
    <ModalContext.Provider value={value}>
      <div id="modals" />
      {children}

      {modals.map((modal) => {
        return (
          <Backdrop data-tether-target key={modal.id}>
            <ErrorBoundary fallback={(props) => <ErrorModal {...props} close={modal.close} />}>
              <Suspense fallback={<FallbackModal />}>
                <modal.Component {...modal.props} close={modal.close} />
              </Suspense>
            </ErrorBoundary>
          </Backdrop>
        );
      })}
    </ModalContext.Provider>
  );
}

function useModalContext() {
  return useContext(ModalContext);
}

export { useModalContext, ModalContext, ModalProvider };
export type { ModalPassthroughProps };
