import { lazy } from 'react';

import { useModal } from '~/components/modal';

const BulkRemoveMembersModal = lazy(() => import('~/features/team/modals/BulkRemoveMembersModal'));

function useBulkRemoveMembers() {
  const { open } = useModal(BulkRemoveMembersModal);
  return open;
}

export { useBulkRemoveMembers };
