import { MetaSticker } from '~/components/sticker/MetaSticker';
import { formatDuration } from '~/utils/video';

type DurationStickerProps = {
  value?: number;
};

function DurationSticker({ value }: DurationStickerProps) {
  return <MetaSticker>{formatDuration(value || 0)}</MetaSticker>;
}

export { DurationSticker };
