import { useSubscriptionPlan } from '~/features/billing/utils/useSubscriptionPlan';
import { useAuth } from '~/utils/auth';

function useTrial() {
  const auth = useAuth();
  const subscription = useSubscriptionPlan();

  const hasTrialed = !!auth?.entity?.billing?.trial;
  const isTrialing = auth?.entity?.billing?.status === 'TRIALING';
  const hasUpgraded = !!subscription.data?.update?.price?.amount;
  const isTrialEligible = !hasTrialed && !hasUpgraded;

  return {
    hasTrialed,
    isTrialing,
    hasUpgraded,
    isTrialEligible
  };
}

export { useTrial };
