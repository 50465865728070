import { forwardRef } from 'react';

import { Avatar } from '~/components/avatar';
import { Box } from '~/components/box';
import { Text } from '~/components/text';
import { styled } from '~/utils/styling';

import type { ComponentProps, ForwardedRef } from 'react';
import type { Maybe } from '~/utils/codegen/graphql';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
  border: '0 none',
  background: 'transparent',
  gap: '$tiny',
  width: '100%',
  overflow: 'hidden'
});

const WrapName = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  flexShrink: 0,
  lineHeight: 1.1,
  overflow: 'hidden'
});

type ProfileProps = ComponentProps<typeof Container> & {
  avatarSrc?: Maybe<string>;
  name?: Maybe<string>;
  email?: Maybe<string>;
};

const Profile = forwardRef(function Profile(
  { avatarSrc, name, email, ...props }: ProfileProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <Container ref={ref} {...props}>
      <Avatar imageSrc={avatarSrc} size="medium" name={name} round />
      <WrapName>
        <Text size="small" weight="bold" truncate>
          {name}
        </Text>
        {email && (
          <Box>
            <Text size="small" color="grey" truncate>
              {email}
            </Text>
          </Box>
        )}
      </WrapName>
    </Container>
  );
});

export { Profile };
