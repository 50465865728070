function getReportingMetadata() {
  if (typeof window === 'undefined') {
    return {};
  }

  const [country, region] = Intl.DateTimeFormat().resolvedOptions().timeZone?.split?.('/') || [];

  const utmParams: Record<string, string> = {};
  Array.from(new URLSearchParams(location.search)).forEach(([key, value]) => {
    if (/utm/.test(key)) {
      const utmKey = key.toLowerCase().replace(/[-_][a-z0-9]/g, (group) => group.slice(-1).toUpperCase());
      // NOTE: we encode the value here to make sure the final values all conform with the http header
      // limitations, see https://stackoverflow.com/a/45239523 for details on the spec
      utmParams[utmKey] = encodeURIComponent(value);
    }
  });

  // HACK: we want to remove hash params from the url, because they can lead to 413 http errors when too long
  // e.g. we're using the url hash for applied filters. We do want to preserve query params though, since they
  // can actually change the page we're showing (e.g. for settings, modals, etc)
  const currentUrlObj = new URL(window.location.href);
  currentUrlObj.hash = '';

  return {
    source: 'admin',
    time: new Date(),
    region,
    country,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    referrer: window.document.referrer,
    currentUrl: currentUrlObj.toString(),
    ...utmParams
  };
}

export { getReportingMetadata };
