import { forwardRef } from 'react';

import type { ComponentProps } from 'react';

import { TextInput } from './TextInput';

type EmailInputProps = Omit<ComponentProps<typeof TextInput>, 'type'>;

const EmailInput = forwardRef<HTMLInputElement, EmailInputProps>(function EmailInput(props, ref) {
  return <TextInput {...props} ref={ref} type="email" />;
});

export { EmailInput };
