import * as Sentry from '@sentry/nextjs';
import { useMemo } from 'react';

import { useAuth } from '~/utils/auth';
import { marker, styled } from '~/utils/styling';

import type { ComponentProps } from 'react';
import type { Item } from '~/components/action-menu';

import { WorkspaceSwitcherItem } from '../components/WorkspaceSwitcherItem';

const Title = styled('h3', {
  ...marker({ color: 'grey' }),
  padding: '$tiny $tiny $wee'
});

type WorkspaceSwitcherItemPassthroughProps = Omit<ComponentProps<typeof WorkspaceSwitcherItem>, 'workspace'>;

function useWorkspaces(options?: { itemProps: WorkspaceSwitcherItemPassthroughProps }) {
  const auth = useAuth();

  const sortedEntities = useMemo(() => {
    const entities = auth?.entities?.items || [];

    // We want the entities to be in logical order, meaning child workspaces should all show
    // after the parent workspace etc.
    const sortByName = (a: any, b: any) => (a.name > b.name ? 1 : -1);

    const parents = entities?.filter?.((e) => !e?.parentId).sort(sortByName) || [];
    const orphans =
      entities?.filter?.((e) => e?.parentId && !entities.find((p) => p?.id === e?.parentId)).sort(sortByName) || [];

    return [
      ...orphans,
      ...parents.reduce<typeof entities>(
        (all, entity) =>
          all.concat([entity, ...entities.filter((e) => e?.parentId === entity?.spaceId).sort(sortByName)]),
        []
      )
    ].filter((entity) => {
      // If an entity doesn't have a name, we assume it's dangling/invalid and shouldn't be displayed
      // as an option in the workspace select - see https://vouch.atlassian.net/browse/VCH-3616
      if (!entity?.name) {
        Sentry.captureMessage('Workspaces: dangling entity', { level: 'warning', extra: { entity } });
        return false;
      }

      return true;
    });
  }, [auth?.entities]);

  const workspaceItems = useMemo<Item[]>(() => {
    const items =
      sortedEntities?.map?.((workspace: any) => ({
        isCustom: true,
        Content: () => (
          <WorkspaceSwitcherItem tabIndex={-1} data-focusable={true} workspace={workspace} {...options?.itemProps} />
        )
      })) || [];

    return [{ isCustom: true, Content: () => <Title>Spaces</Title> }].concat(items);
  }, [sortedEntities, options]);

  return useMemo(
    () => ({
      sortedEntities,
      workspaceItems
    }),
    [sortedEntities, workspaceItems]
  );
}

export { useWorkspaces };
