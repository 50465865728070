import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { useConfirmationModal } from '~/components/modal';
import { useErrorNotification, useNotification } from '~/components/notification';
import { usePlanUsage } from '~/features/billing/utils/usePlanUsage';
import { useAnalyticsEvent } from '~/utils/analytics';
import { refetchOrEvict, useMutation } from '~/utils/graphql';

import { GET_CAMPAIGN_QUERY } from '~/queries/get-campaign-query';
import { SEARCH_ENTITY_QUERY } from '~/queries/search-entity-query';
import { UPDATE_CAMPAIGN_MUTATION } from '~/queries/update-campaign-mutation';

function usePauseCampaign() {
  const usage = usePlanUsage();
  const client = useApolloClient();
  const event = useAnalyticsEvent();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN_MUTATION);

  const confirm = useConfirmationModal();

  return useCallback(
    ({ id }: { id: string }) => {
      confirm.open({
        id: 'pause-campaign',
        title: 'Pause request',
        message: (
          <>
            You’re about to pause this request. This will turn off your request and you will no longer receive any new
            responses. The responses you’ve collected won’t be affected.
          </>
        ),
        confirmLabel: 'Pause request',
        onConfirm: async () => {
          try {
            await updateCampaign({ variables: { input: { id, status: 'PAUSED' } } });
            await Promise.all([
              usage.refresh(),
              refetchOrEvict({ client, include: [GET_CAMPAIGN_QUERY, SEARCH_ENTITY_QUERY] })
            ]);
            event('campaign_paused', { campaign_id: id });
            notification.show({ message: 'Request has been paused' });
          } catch (e) {
            errorNotification.show(e);
          }
        }
      });
    },
    [confirm, updateCampaign, usage, client, event, notification, errorNotification]
  );
}

export { usePauseCampaign };
