import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { useConfirmationModal } from '~/components/modal';
import { useNotification } from '~/components/notification';
import { useErrorNotification } from '~/components/notification';
import { useAnalyticsEvent } from '~/utils/analytics';
import { refetchOrEvict, useMutation } from '~/utils/graphql';

import { GET_CAMPAIGN_SETTINGS_QUERY } from '~/queries/get-campaign-settings-query';
import { REMOVE_CAMPAIGN_COVER_MUTATION } from '~/queries/remove-campaign-cover-mutation';

function useRemoveCoverVideo() {
  const client = useApolloClient();
  const event = useAnalyticsEvent();
  const confirm = useConfirmationModal();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const [removeCampaignCover] = useMutation(REMOVE_CAMPAIGN_COVER_MUTATION);

  return useCallback(
    ({ id }: { id: string }) => {
      confirm.open({
        id: 'remove-campaign-cover-video',
        title: 'Remove cover video',
        message: (
          <>
            Are you sure you want to remove the cover video? It will get detached from this request, but will still
            remain in your library.
          </>
        ),
        confirmLabel: 'Yes, remove cover video',
        onConfirm: async () => {
          try {
            await removeCampaignCover({ variables: { id } });
            await refetchOrEvict({ client, include: [GET_CAMPAIGN_SETTINGS_QUERY] });
            event('campaign_cover_removed', { campaign_id: id });
            notification.show({ message: 'Cover video successfully removed.' });
          } catch (e) {
            errorNotification.show(e);
          }
        }
      });
    },
    [confirm, removeCampaignCover, client, event, notification, errorNotification]
  );
}

export { useRemoveCoverVideo };
