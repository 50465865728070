import { gql } from '~/utils/codegen';

export const GET_VOUCH_ANSWERS_CROPPING_QUERY = gql(/* GraphQL */ `
  query GetVouchAnswersCropping($id: ID!) {
    vouch(id: $id) {
      id
      questions {
        items {
          id
          answer {
            id
            settings {
              crop {
                x
                y
                width
                height
              }
            }
            metadata {
              duration
              aspectRatio
            }
            ...VideoAnswer
          }
        }
      }
    }
  }
`);
