import { styled } from '~/utils/styling';

const Container = styled('div', {
  content: ' ',
  position: 'fixed',
  zIndex: 200,
  top: 0,
  left: 0,
  right: 0,
  height: '.4rem',
  backgroundImage: `repeating-linear-gradient(
    -55deg,
    #4ECC4D,
    #4ECC4D  20px,
    #45B644 20px,
    #45B644 40px
  )`
});

const Label = styled('span', {
  position: 'fixed',
  top: 0,
  zIndex: 200,
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '.1rem $small',
  fontFamily: '$fontFamilies$mono',
  fontWeight: '$bold',
  fontSize: '$xs',
  letterSpacing: '$large',
  display: 'flex',
  justifyContent: 'center',
  color: '$light-1000',
  background: '#379f3c',
  boxShadow: '$small',
  pointerEvents: 'none',
  borderRadius: '0 0 $tiny $tiny'
});

type ConstructionBannerProps = {
  env: string;
};

function ConstructionBanner({ env }: ConstructionBannerProps) {
  return (
    <Container>
      <Label>{env}</Label>
    </Container>
  );
}

export { ConstructionBanner };
