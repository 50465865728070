import { useCreateRecording } from '~/features/global/actions/useCreateRecording';
import { useRoutedAction } from '~/features/global/utils/useRoutedAction';

function useRoutedActions() {
  // Globally triggered actions
  const createRecording = useCreateRecording();
  useRoutedAction({
    action: (value) => createRecording({ recordingType: value === 'screen' ? 'SCREEN' : 'VIDEO', type: 'RECORDING' }),
    queryParam: 'instantRecord'
  });
}

export { useRoutedActions };
