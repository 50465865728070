import { useCallback } from 'react';

import { Popout } from '~/components/popout';

import type { Items } from './ActionMenuContent';
import type { ComponentProps } from 'react';
import type { Item } from '~/components/popout';

import { ActionMenuContent } from './ActionMenuContent';

type ActionMenuProps = Omit<ComponentProps<typeof Popout>, 'Content' | 'children'> & {
  items: Items;
  searchPlaceholder?: string;
  children: ComponentProps<typeof Popout>['children'];
  menuHeight?: ComponentProps<typeof ActionMenuContent>['menuHeight'];
  loading?: boolean;
};

function ActionMenu({ items, children, searchPlaceholder, menuHeight, loading, ...props }: ActionMenuProps) {
  const Content = useCallback(
    ({ setVisible, element, update }: any) => (
      <ActionMenuContent
        setVisible={setVisible}
        element={element}
        items={items}
        searchPlaceholder={searchPlaceholder}
        menuHeight={menuHeight}
        loading={loading}
        onContentChange={update}
      />
    ),
    [items, searchPlaceholder, menuHeight, loading]
  );

  return (
    <Popout Content={Content} {...props}>
      {children}
    </Popout>
  );
}

export { ActionMenu };
export type { Item };
