import { LoadingSpinner } from '~/components/loading-spinner/LoadingSpinner';
import { P } from '~/components/text';
import { styled } from '~/utils/styling';

import type { ComponentProps, ReactNode } from 'react';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '$small',
  minHeight: '6rem',

  variants: {
    horizontal: {
      true: {
        flexDirection: 'row',
        gap: '$tiny'
      }
    },
    color: {
      dark: {
        color: '$dark-200'
      },
      light: {
        color: '$light-200'
      }
    }
  },

  defaultVariants: {
    color: 'dark'
  }
});

type LoadingMessageProps = Omit<ComponentProps<typeof Container>, 'children'> & {
  message: ReactNode;
};

function LoadingMessage({ message, ...props }: LoadingMessageProps) {
  return (
    <Container {...props}>
      <LoadingSpinner />
      <P size="small">{message}</P>
    </Container>
  );
}

export { LoadingMessage };
