import { ExtensionMasthead } from '~/features/global/components/masthead/ExtensionMasthead';
import { useAuth } from '~/utils/auth';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { PropsWithChildren } from 'react';

const Container = styled('div', {
  background: '$grey-100',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1
});

type ExtensionLayoutProps = PropsWithChildren<Record<never, any>> & {
  onRecord?: () => any;
};

export function ExtensionLayout({ onRecord, children }: ExtensionLayoutProps) {
  const auth = useAuth();
  const router = useRouter();

  // Redirect the user if they are not logged in
  if (!auth?.username) {
    if (typeof window !== 'undefined') {
      router.replace({
        pathname: '/sign-in',
        query: router.pathname === '/dashboard/extension/search' ? {} : { redirect: router.asPath }
      });
    }
    return null;
  }

  if (auth?.username && !auth?.entity?.name) {
    if (typeof window !== 'undefined') {
      router.replace('/account-setup/complete');
    }
    return null;
  }

  return (
    <Container>
      <ExtensionMasthead onRecord={onRecord} />
      {children}
    </Container>
  );
}
