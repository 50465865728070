import { lazy } from 'react';

import { useModal } from '~/components/modal';

const RenameCampaignModal = lazy(() => import('~/features/campaigns/modals/RenameCampaignModal'));

function useRenameCampaign() {
  const { open } = useModal(RenameCampaignModal);
  return open;
}

export { useRenameCampaign };
