type MarkerArg = {
  color?: 'grey';
};

function marker({ color }: MarkerArg = {}) {
  return {
    fontSize: '$fontSizes$xs',
    lineHeight: '$lineHeights$xs',
    textTransform: 'uppercase',
    letterSpacing: '$letterSpacings$large',
    fontWeight: '$fontWeights$bold',
    ...(color === 'grey' ? { color: '$dark-600' } : {})
  };
}

export { marker };
