import { useEffect, useRef } from 'react';

import { useModal } from '~/components/modal';

type UseControlledModalOptions = {
  isOpen: any;
  close: () => void | Promise<void>;
};

function useControlledModal(Component: Parameters<typeof useModal>[0], options: UseControlledModalOptions) {
  const modal = useModal(Component, { onClose: options.close });

  const instance = useRef<any>();
  const open = useRef(false);

  const shouldOpen = !!options.isOpen;
  useEffect(
    () => {
      if (shouldOpen && !open.current) {
        instance.current = options.isOpen === true ? modal.open() : modal.open(options.isOpen);
        open.current = true;
        return;
      }
      if (!shouldOpen && open.current) {
        instance.current?.close?.();
        open.current = false;
      }
    },
    // Since `isOpen` is likely going to be an inline object whenever we want to pass props through to the
    // modal, we don't want to rerun this effect on every render, initial props are only relevant when opening
    // the modal anyway
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldOpen, options.close, modal]
  );

  return modal;
}

export { useControlledModal };
