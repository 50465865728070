import { forwardRef, useMemo, useState } from 'react';

import { LoadingSpinner } from '~/components/loading-spinner';
import { styled } from '~/utils/styling';

import type { ComponentProps, ForwardedRef, ReactNode } from 'react';

const Container = styled('div', {
  position: 'relative',
  fontWeight: '$bold',
  overflow: 'hidden',
  flexShrink: 0,
  backgroundColor: '$$avatarBackground',
  color: '$$avatarColor',

  variants: {
    size: {
      tiny: {
        width: '1rem',
        height: '1rem',
        borderRadius: '$wee',
        fontSize: '$xs'
      },
      small: {
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '$wee',
        fontSize: '$xs'
      },
      medium: {
        width: '2rem',
        height: '2rem',
        borderRadius: '$tiny',
        fontSize: '$large'
      },
      large: {
        width: '3rem',
        height: '3rem',
        borderRadius: '$small',
        fontSize: '$xl'
      },
      huge: {
        width: '6rem',
        height: '6rem',
        borderRadius: '$medium',
        fontSize: '$xxl'
      }
    },

    color: {
      teal: {
        $$avatarBackground: '$colors$teal-100',
        $$avatarColor: '$colors$teal-600'
      },
      purple: {
        $$avatarBackground: '$colors$purple-100',
        $$avatarColor: '$colors$purple-700'
      },
      neutral: {
        $$avatarBackground: '$colors$dark-80',
        $$avatarColor: '$colors$dark-1000'
      }
    },

    round: {
      true: {
        borderRadius: '50% !important'
      }
    },

    // Fixes issues with background color bleeding around image
    transparent: {
      true: {
        backgroundColor: 'transparent'
      }
    }
  }
});

const Initials = styled('span', {
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',

  variants: {
    loading: {
      true: {
        opacity: 0
      }
    },

    size: {
      tiny: {
        '& span': {
          display: 'none',

          '&:first-child': {
            display: 'flex'
          }
        }
      },
      small: {},
      medium: {},
      large: {},
      huge: {}
    }
  }
});

const Image = styled('img', {
  position: 'absolute',
  zIndex: 1,
  inset: 0,
  objectFit: 'contain',
  width: '100%',
  height: '100%'
});

const Loading = styled('div', {
  position: 'absolute',
  zIndex: 2,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'currentColor',

  variants: {
    hasImage: {
      true: {
        color: '$light-1000'
      }
    }
  }
});

type AvatarProps = Omit<ComponentProps<typeof Container>, 'children' | 'ref' | 'name' | 'size'> & {
  size?: ComponentProps<typeof Container>['size'];
  color?: ComponentProps<typeof Container>['color'];
  round?: ComponentProps<typeof Container>['round'];
  name?: string | null;
  imageSrc?: string | null;
  loading?: boolean;
  fallback?: ReactNode;
};

const Avatar = forwardRef(function Avatar(
  { size = 'medium', color = 'teal', round, name, fallback, imageSrc, loading, ...props }: AvatarProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [imageError, setImageError] = useState(false);

  const initials = useMemo(() => {
    if (!name) return undefined;
    const names = name.trim().split(' ');
    return names.length === 1 ? names[0][0] || undefined : `${names[0][0]}${names[names.length - 1][0]}`;
  }, [name]);

  const showImage = !!imageSrc && !imageError;
  const initialsString = initials || fallback || '?';

  return (
    <Container ref={ref} {...props} size={size} color={color} round={round} transparent={showImage}>
      {showImage ? (
        <Image
          role="img"
          alt={`${name} avatar`}
          src={imageSrc}
          loading="lazy"
          onError={(e) => {
            e.stopPropagation();
            setImageError(true);
          }}
        />
      ) : (
        <Initials size={size} loading={loading} data-testid="initials">
          {typeof initialsString === 'string'
            ? initialsString.split('').map((char, i) => <span key={i}>{char}</span>)
            : initialsString}
        </Initials>
      )}
      {loading && (
        <Loading hasImage={!!imageSrc}>
          <LoadingSpinner size="small" />
        </Loading>
      )}
    </Container>
  );
});

export { Avatar };
