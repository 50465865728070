import { ListItemCard } from '~/components/card';
import { styled } from '~/utils/styling';

import { CampaignListItemHeader } from './CampaignListItemHeader';
import { CampaignStatusSticker } from '../CampaignStatusSticker';

const StyledCard = styled(ListItemCard, {
  '&&': {
    overflow: 'visible',
    position: 'relative',
    borderRadius: '$small',
    padding: '$medium',

    '&:before': {
      content: ' ',
      position: 'absolute',
      inset: 0,
      borderRadius: '$small',
      border: '$borderWidths$thin solid $dark-80'
    },

    '&:hover, &:focus': {
      boxShadow: 'none',

      '&:before': {
        border: '$borderWidths$thin solid $dark-200'
      }
    }
  },

  variants: {
    selected: {
      true: {
        '&&': {
          '&:before, &:hover:before, &:focus:before': {
            border: '.2rem solid $teal-600'
          }
        }
      }
    }
  }
});

type CampaignSelectionListItemProps = {
  data: any;
  selected: boolean;
  onSelect: any;
};

function CampaignSelectionListItem({ data, selected, onSelect }: CampaignSelectionListItemProps) {
  return (
    <StyledCard
      selected={selected}
      header={
        <CampaignListItemHeader data={data} size="tiny" actions={<CampaignStatusSticker status={data?.status} />} />
      }
      onClick={onSelect}
    />
  );
}

export { CampaignSelectionListItem };
