import { Button } from '~/components/button';
import { useErrorMessage } from '~/components/error-boundary/useErrorMessage';
import { Modal } from '~/components/modal';
import { P } from '~/components/text';

import type { FallbackRender } from '@sentry/react/types/errorboundary';
import type { ModalPassthroughProps } from '~/components/modal';

type ErrorModalProps = ModalPassthroughProps &
  Partial<Omit<Parameters<FallbackRender>[0], 'error'>> & {
    error: Error & { code?: number };
  };

function ErrorModal({ error, componentStack, resetError, close }: ErrorModalProps) {
  if (__DEV__ && typeof window !== 'undefined') {
    console.error('Caught error', { error, componentStack });
  }

  const { title, description, showRefresh } = useErrorMessage(error);

  return (
    <Modal
      id="error"
      title={title}
      onClose={close}
      actions={
        showRefresh &&
        resetError && (
          <Button color="grey" onClick={() => resetError()}>
            Refresh
          </Button>
        )
      }
    >
      <P color="grey">{description}</P>
    </Modal>
  );
}

export { ErrorModal };
