import { lazy } from 'react';

import { useModal } from '~/components/modal';

const AddToPlaylistModal = lazy(() => import('~/features/playlists/modals/AddToPlaylistModal'));

function useAddToPlaylist() {
  const { open } = useModal(AddToPlaylistModal);
  return open;
}

export { useAddToPlaylist };
