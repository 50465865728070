import { useMemo } from 'react';

import { useCreateWorkspace } from './useCreateWorkspace';
import { useDeleteWorkspace } from './useDeleteWorkspace';
import { useRenameWorkspace } from './useRenameWorkspace';

function useWorkspaceActions() {
  const createWorkspace = useCreateWorkspace();
  const renameWorkspace = useRenameWorkspace();
  const deleteWorkspace = useDeleteWorkspace();

  return useMemo(
    () => ({
      createWorkspace,
      renameWorkspace,
      deleteWorkspace
    }),
    [createWorkspace, renameWorkspace, deleteWorkspace]
  );
}

export { useWorkspaceActions };
