import { lazy } from 'react';

import { useModal } from '~/components/modal';

const BulkDuplicatePlaylistsModal = lazy(() => import('~/features/playlists/modals/BulkDuplicatePlaylistsModal'));

function useBulkDuplicatePlaylists() {
  const { open } = useModal(BulkDuplicatePlaylistsModal);
  return open;
}

export { useBulkDuplicatePlaylists };
