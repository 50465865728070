import { lazy } from 'react';

import { useModal } from '~/components/modal';

const RenamePlaylistModal = lazy(() => import('~/features/playlists/modals/RenamePlaylistModal'));

function useRenamePlaylist() {
  const { open } = useModal(RenamePlaylistModal);
  return open;
}

export { useRenamePlaylist };
