/* eslint-disable max-lines */
import { gql } from '~/utils/codegen';

export const SEARCH_ENTITY_QUERY = gql(/* GraphQL */ `
  query SearchEntity(
    $query: String!
    $populate: Boolean
    $limit: Int
    $filter: EntitySearchFilter
    $nextToken: String
    $sort: EntitySearchSort
  ) {
    searchEntity(
      query: $query
      populate: $populate
      limit: $limit
      filter: $filter
      nextToken: $nextToken
      sort: $sort
    ) {
      count
      nextToken
      items {
        ... on SearchDocumentCampaign {
          id
          type
          title
          createdAt
          updatedAt
          populate {
            campaign {
              id
              hashId
              name
              status
              answerCount
              responseCount
              account {
                id
                name
              }
              tags {
                items {
                  id
                  tag {
                    id
                    name
                  }
                }
              }
              questions {
                items {
                  id
                }
              }
            }
          }
        }
        ... on SearchDocumentQandA {
          id
          type
          transcriptions {
            language
            text
          }
          answerLabel
          campaignId
          contactName
          contactEmail
          clientName
          contactDepartment
          contactLocation
          contactRole
          questionText
          vouchId
          title
          contactActiveStatus
          createdAt
          updatedAt
          populate {
            question {
              id
              title
              vouch {
                id
                name
                type
                hashId
                insights {
                  summary
                }
                settings {
                  urls {
                    watch
                  }
                }
                campaign {
                  id
                  name
                }
                media {
                  preview
                  thumbnail
                }
                questions {
                  items {
                    id
                  }
                }
              }
              ordinality
              answer {
                ...VideoAnswer
                id
                transcription {
                  insights {
                    summary
                  }
                }
                tags {
                  items {
                    id
                    tag {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        ... on SearchDocumentVouch {
          id
          type
          campaignId
          contactEmail
          contactName
          clientName
          contactDepartment
          contactLocation
          contactRole
          title
          createdAt
          updatedAt
          contactActiveStatus
          answers {
            id
            transcriptions {
              language
              text
            }
          }
          populate {
            vouch {
              id
              name
              type
              hashId
              status
              approvalStatus
              insights {
                title
                summary
              }
              settings {
                urls {
                  watch
                }
              }
              contact {
                id
                email
                name
                department
                roleTitle
                location
                client {
                  id
                  name
                }
              }
              campaign {
                id
                name
              }
              tags {
                items {
                  id
                  tag {
                    id
                    name
                  }
                }
              }
              account {
                id
                name
              }
              questions {
                items {
                  title
                  answer {
                    ...VideoAnswer
                    id
                    transcription {
                      insights {
                        summary
                      }
                    }
                  }
                }
              }
              media {
                preview
                thumbnail
              }
            }
          }
        }
        ... on SearchDocumentClip {
          id
          title
          type
          createdAt
          updatedAt
          entityId
          answerId
          vouchId
          startTime
          endTime
          contactEmail
          contactName
          clientName
          contactDepartment
          contactLocation
          contactRole
          transcriptions {
            language
            text
          }
          populate {
            clip {
              id
              answer {
                ...VideoAnswer
                id
                question {
                  id
                  title
                }
                vouch {
                  id
                  name
                  type
                }
              }
              tags {
                items {
                  id
                  tag {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        ... on SearchDocumentContact {
          id
          title
          type
          entityId
          contactEmail
          contactName
          clientId
          clientName
          contactDepartment
          contactLocation
          contactRole
          createdAt
          updatedAt
          contactActiveStatus
          populate {
            contact {
              id
              entityId
              name
              email
              clientId
              accountId
              anonymous
              roleTitle
              department
              phone
              pronouns
              location
              idNumber
              activeStatus
              deactivatedAt
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`);
