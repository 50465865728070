import { lazy } from 'react';

import { useModal } from '~/components/modal';

const BulkUpdateRoleModal = lazy(() => import('~/features/team/modals/BulkUpdateRoleModal'));

function useBulkUpdateRole() {
  const { open } = useModal(BulkUpdateRoleModal);
  return open;
}

export { useBulkUpdateRole };
