import { useCallback } from 'react';

import { useAnalyticsEvent, useHubspotChat } from '~/utils/analytics';

function useContactUs() {
  const event = useAnalyticsEvent();
  const hubspotChat = useHubspotChat();

  return useCallback(
    async ({ type }: { type: string }) => {
      event('contacted_support', { type, mode: hubspotChat.isEnabled ? 'hubspot' : 'email' });

      if (!hubspotChat.isEnabled) {
        window.open('mailto:help@vouchfor.com');
        return;
      }

      return hubspotChat.open();
    },
    [event, hubspotChat]
  );
}

export { useContactUs };
