const shared = {
  idle: {},
  interactive: {
    transition:
      'background $timings$normal, color $timings$normal, transform $timings$normal, box-shadow $timings$normal',
    cursor: 'pointer',

    // TODO: discuss with design, using this for all intents will remove any form of hierarchie from the
    // page/element once things are disabled
    '&[disabled], &[aria-disabled]': {
      '&, &:hover, &:focus': {
        background: '$dark-80',
        color: '$dark-200',
        cursor: 'default'
      }
    }
  }
};

const surfaceVariants = {
  // Crimson
  primary: {
    idle: {
      background: '$s-brand-800',
      color: '$s-brand-200'
    },

    interactive: {
      '&:hover, &:focus': {
        background: '$s-brand-900',
        color: '$s-brand-200'
      }
    }
  },

  // Blush
  secondary: {
    idle: {
      background: '$s-brand-100',
      color: '$s-brand-800'
    },

    interactive: {
      '&:hover, &:focus': {
        background: '$s-brand-200',
        color: '$s-brand-800'
      }
    }
  },

  // Dark
  dark: {
    idle: {
      background: '$dark-800',
      color: '$light-1000'
    },

    interactive: {
      '&:hover, &:focus': {
        background: '$dark-1000',
        color: '$light-1000'
      }
    }
  },

  // Dark tinted
  'dark-tinted': {
    idle: {
      background: '$dark-80',
      color: '$dark-1000'
    },

    interactive: {
      '&:hover, &:focus': {
        background: '$dark-200',
        color: '$dark-1000'
      }
    }
  },

  // Light
  light: {
    idle: {
      background: '$light-1000',
      color: '$dark-1000'
    },

    interactive: {
      boxShadow: '$none',

      '&:hover, &:focus': {
        background: '$light-1000',
        color: '$dark-1000',
        boxShadow: '$medium'
      }
    }
  }
};

type SurfaceArg = {
  intent: keyof typeof surfaceVariants;
  interactive?: boolean;
};

function surface({ intent, interactive }: SurfaceArg) {
  return {
    ...shared.idle,
    ...surfaceVariants[intent].idle,

    ...(interactive
      ? {
          ...shared.interactive,
          ...surfaceVariants[intent].interactive
        }
      : {})
  };
}

export { surface };
