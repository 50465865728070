async function refreshSession() {
  const { Auth } = await import('aws-amplify');
  const user = await Auth.currentAuthenticatedUser();

  const refreshed = await new Promise<{ user: any; session: any }>((resolve, reject) => {
    user?.refreshSession?.(user?.signInUserSession?.refreshToken, (error: any, session: any) => {
      if (error) {
        reject(new Error(error?.message || error || 'Session refresh failed'));
      } else {
        resolve({ user, session });
      }
    });
  });

  await Auth.updateUserAttributes(refreshed.user, {});
  return refreshed;
}

export { refreshSession };
