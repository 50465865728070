import { CampaignSelectionListItem } from '~/features/campaigns/components/list/CampaignSelectionListItem';
import { styled } from '~/utils/styling';

import type { FieldPassthroughProps } from '~/components/form';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$wee'
});

type CampaignSelectionInputInputProps = FieldPassthroughProps & {
  campaigns?: any;
  multi?: boolean;
};

function CampaignSelectionInput({ campaigns, getValue, setValue, multi }: CampaignSelectionInputInputProps) {
  const value = getValue?.();
  return (
    <Container>
      {campaigns?.map((campaign: any) => {
        const selected = multi ? !!value?.find((val: any) => val?.id === campaign.id) : campaign?.id === value?.id;
        return (
          <CampaignSelectionListItem
            data={campaign}
            key={campaign.id}
            selected={selected}
            onSelect={() =>
              multi
                ? setValue?.(selected ? value.filter((val: any) => val?.id !== campaign.id) : [...value, campaign])
                : setValue?.(campaign)
            }
          />
        );
      })}
    </Container>
  );
}

export { CampaignSelectionInput };
