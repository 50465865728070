import { ActionMenu } from '~/components/action-menu';
import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';

import { Button } from './Button';
import { IconButton } from './IconButton';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& > *:nth-child(1)': {
    borderTopRightRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
    flex: 1
  },

  '& > *:nth-child(2)': {
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    marginLeft: '$borderWidths$thin'
  },

  '& > *': {
    zIndex: 1,

    '&:hover, &:focus, &:active': {
      zIndex: 2
    }
  }
});

type SplitButtonProps = ComponentProps<typeof Button> & {
  items: ComponentProps<typeof ActionMenu>['items'];
  placement?: ComponentProps<typeof ActionMenu>['placement'];
};

function SplitButton({ items, color, placement = 'bottom-end', size, ...props }: SplitButtonProps) {
  return (
    <Container>
      <Button color={color} size={size} {...props} />
      <ActionMenu items={items} placement={placement}>
        {(actionMenuProps) => (
          <IconButton round={false} icon="chevron-down" color={color} size={size} {...actionMenuProps} />
        )}
      </ActionMenu>
    </Container>
  );
}

export { SplitButton };
