import { useApolloClient } from '@apollo/client';
import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useNotification } from '~/components/notification';
import { useErrorNotification } from '~/components/notification';
import { refetchOrEvict, useLazyQuery, useMutation } from '~/utils/graphql';
import { useRouter } from '~/utils/routing/useRouter';

import { APPEND_PLAYLIST_MUTATION } from '~/queries/append-playlist-mutation';
import { GET_VOUCH_ANSWERS_CROPPING_QUERY } from '~/queries/get-vouch-answers-cropping-query';
import { GET_VOUCH_QUERY } from '~/queries/get-vouch-query';
import { SEARCH_ENTITY_QUERY } from '~/queries/search-entity-query';

const AddFromLibraryModal = lazy(() => import('~/features/playlists/modals/AddFromLibraryModal'));

function useAddFromLibrary() {
  const client = useApolloClient();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const { push } = useRouter();
  const { open } = useModal(AddFromLibraryModal);

  const [fetchVouch] = useLazyQuery(GET_VOUCH_ANSWERS_CROPPING_QUERY);
  const [appendPlaylist] = useMutation(APPEND_PLAYLIST_MUTATION);

  const handleSubmit = useCallback(
    async ({ selected, id, redirect }: { selected: string[]; id: string; redirect?: boolean }) => {
      try {
        const answers = await Promise.all(selected.map((id) => fetchVouch({ variables: { id } })))
          .then((res) => res.map((r) => r.data?.vouch))
          .then((res) =>
            res.flatMap((item) => {
              const questions = item?.questions?.items || [];
              return questions
                .filter((question) => question.answer?.id)
                .map((question: any) => ({
                  id: question?.answer?.id,
                  settings: question?.answer?.settings
                }));
            })
          );

        const items = answers.map((answer) => ({
          id: answer.id,
          settings: {
            startOffset: answer?.settings?.startOffset || 0,
            endOffset: answer?.settings?.endOffset || 0,
            crop: answer?.settings?.crop
              ? {
                  width: answer?.settings?.crop?.width,
                  height: answer?.settings?.crop?.height,
                  x: answer?.settings?.crop?.x,
                  y: answer?.settings?.crop?.y
                }
              : undefined
          }
        }));

        await appendPlaylist({ variables: { id, items: items } });
        await refetchOrEvict({ client, include: [GET_VOUCH_QUERY, SEARCH_ENTITY_QUERY] });
        if (redirect) {
          await push(`/dashboard/playlists/${id}`);
        }
        notification.show({ message: 'Videos successfully added to playlist' });
      } catch (e) {
        errorNotification.show(e);
      }
    },
    [appendPlaylist, client, errorNotification, fetchVouch, notification, push]
  );

  return useCallback(
    ({ id, redirect = true }: { id: string; redirect?: boolean }) => {
      open({
        title: 'Search your library to add to your playlist',
        submitLabel: 'Add to playlist',
        onSubmit: ({ selected }) => handleSubmit({ selected, id, redirect })
      });
    },
    [handleSubmit, open]
  );
}

export { useAddFromLibrary };
