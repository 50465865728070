import { lazy } from 'react';

import { useModal } from '~/components/modal';

const SSOSignupLinkModal = lazy(() => import('~/features/team/modals/SSOSignupLinkModal'));

function useSSOSignupLink() {
  const { open } = useModal(SSOSignupLinkModal);
  return open;
}

export { useSSOSignupLink };
