import { lazy } from 'react';

import { useModal } from '~/components/modal';

const BulkDeleteCampaignsModal = lazy(() => import('~/features/campaigns/modals/BulkDeleteCampaignsModal'));

function useBulkDeleteCampaigns() {
  const { open } = useModal(BulkDeleteCampaignsModal);
  return open;
}

export { useBulkDeleteCampaigns };
