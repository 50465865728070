async function changePassword({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) {
  const { Auth } = await import('aws-amplify');

  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, currentPassword, newPassword);

  // For security reasons, we invalidate ALL tokens issues to the user when they change their password
  // We then log them in again to create a new fresh token that stays valid
  await Auth.signOut({ global: true });
  await Auth.signIn({ username: user?.attributes?.email, password: newPassword });
}

export { changePassword };
