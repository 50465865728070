import { useEffect, useState } from 'react';

type State = {
  // Static extension values
  runtimeId: string;
  blobFrameUrl: string;
  previewFrameUrl: string;

  // Recording states
  // TODO: allow for multiple takes (= blobs) being stored
  showCountdown: boolean;
  countdownStarted: number | null;
  recordingStarted: number | null;
  recordingTabId: number | null;
  constraints: any | null;
  hasBlob: boolean;

  // Other misc values
  updateAvailable: boolean;
  hasOffscreen: boolean;
  user: any | null;
  error: string | null;
};

function useGlobalState() {
  const [globalState, setGlobalState] = useState<State | undefined>(undefined);

  // Message handler for comms with the extension content script
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      switch (event.data.type) {
        case 'CONTENT__GLOBAL_STATE_CHANGED': {
          setGlobalState(event.data.data);
          break;
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [globalState]);

  // Get global state on mount
  useEffect(() => {
    window.parent?.postMessage?.({ type: 'EXTERNAL__GET_GLOBAL_STATE' }, '*');
  }, []);

  return globalState;
}

export { useGlobalState };
