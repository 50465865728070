import { forwardRef } from 'react';

import { styled, truncate } from '~/utils/styling';

import type { ComponentProps, ForwardedRef, ReactNode } from 'react';

const StyledText = styled('span', {
  variants: {
    untitled: {
      true: {
        opacity: 0.4
      }
    },
    truncate: {
      true: {
        ...truncate({ lines: 1 })
      }
    }
  }
});

type MaybeUndefinedProps = ComponentProps<typeof StyledText> & {
  label?: ReactNode;
  fallback?: ReactNode;
  truncate?: boolean;
};

const MaybeUndefined = forwardRef(function MaybeUndefined(
  { label = '', fallback = 'Untitled', truncate, ...props }: MaybeUndefinedProps,
  ref: ForwardedRef<HTMLSpanElement>
) {
  return (
    <StyledText ref={ref} untitled={!label} truncate={truncate} {...props}>
      {label || fallback}
    </StyledText>
  );
});

export { MaybeUndefined };
