import { useApolloClient } from '@apollo/client';
import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useErrorNotification, useNotification } from '~/components/notification';
import { useAnalyticsEvent } from '~/utils/analytics';
import { refetchOrEvict, useMutation } from '~/utils/graphql';

import { GET_CAMPAIGN_COVER_SETTINGS_QUERY } from '~/queries/get-campaign-cover-settings-query';
import { GET_CAMPAIGN_SETTINGS_QUERY } from '~/queries/get-campaign-settings-query';
import { SET_CAMPAIGN_COVER_MUTATION } from '~/queries/set-campaign-cover-mutation';

const UploadVideoModal = lazy(() => import('~/features/global/modals/UploadVideoModal'));

function useUploadCoverVideo() {
  const client = useApolloClient();
  const event = useAnalyticsEvent();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const { open } = useModal(UploadVideoModal);

  const [setCampaignCover] = useMutation(SET_CAMPAIGN_COVER_MUTATION);

  return useCallback(
    ({ id }: { id: string }) => {
      open({
        type: 'COVER_UPLOAD',
        redirect: false,
        onSubmit: async ({ vouch }) => {
          try {
            await setCampaignCover({ variables: { id, coverVouchId: vouch?.id } });
            await refetchOrEvict({ client, include: [GET_CAMPAIGN_SETTINGS_QUERY, GET_CAMPAIGN_COVER_SETTINGS_QUERY] });
            event('campaign_cover_created', { campaign_id: id, medium: 'upload' });
            notification.show({ message: 'Cover video set successfully' });
          } catch (e) {
            errorNotification.show(e);
          }
        }
      });
    },
    [open, setCampaignCover, client, event, notification, errorNotification]
  );
}

export { useUploadCoverVideo };
