import { ButtonBar, IconButton } from '~/components/button';
import { Meta } from '~/components/meta';
import { Heading } from '~/components/text';
import { styled } from '~/utils/styling';

import type { ComponentProps, ReactNode } from 'react';
import type { Avatar } from '~/components/avatar';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$medium',
  alignItems: 'flex-start',
  minWidth: 0,
  width: '100%',

  '@bp-medium': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

const WrapTitle = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  minWidth: 0,
  width: '100%'
});

const TitleContent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',

  variants: {
    stacked: {
      true: {
        flexDirection: 'column-reverse',

        '@bp-small': {
          flexDirection: 'row'
        }
      }
    }
  }
});

const Title = styled(Heading, {
  display: 'inline-flex',
  flexDirection: 'column',
  width: '100%',

  '&&': {
    lineHeight: 1.2,
    paddingBottom: '$wee'
  },

  '@bp-small': {
    display: 'inline'
  }
});

const TitleMain = styled('span', {
  display: 'inline-flex',
  alignItems: 'baseline',
  paddingRight: '$small',
  gap: '$small',
  border: 'none'
});

const TitleMeta = styled('span', {
  display: 'inline-flex',
  color: '$dark-600',
  fontWeight: '$regular'
});

const Actions = styled(ButtonBar, {
  flexWrap: 'nowrap',
  paddingLeft: '$small',

  variants: {
    absolute: {
      true: {
        position: 'absolute',
        top: 0,
        right: 0
      }
    },
    stacked: {
      true: {
        width: '100%',
        paddingBottom: '$tiny'
      }
    }
  }
});

type HeaderProps = Omit<ComponentProps<typeof Container>, 'title'> & {
  size?: 'tiny' | 'small' | 'medium' | 'large';
  gap?: boolean;
  headingLevel?: 1 | 2;
  avatar?: ReactNode;
  title?: ReactNode;
  titleMeta?: ReactNode;
  stickers?: ReactNode;
  meta?: { key?: string | number; label: ReactNode }[];
  actions?: ReactNode;
  onEditTitle?: () => void;
  stacked?: ComponentProps<typeof TitleContent>['stacked'];
};

type HeadingSizeMap = {
  [key in 'tiny' | 'small' | 'medium' | 'large']: {
    avatar: ComponentProps<typeof Avatar>['size'];
    heading: ComponentProps<typeof Heading>['size'];
  };
};

const headerSizeMap: HeadingSizeMap = {
  large: {
    avatar: 'large',
    heading: 'large'
  },
  medium: {
    avatar: 'large',
    heading: 'medium'
  },
  small: {
    avatar: 'medium',
    heading: 'small'
  },
  tiny: {
    avatar: 'small',
    heading: 'tiny'
  }
};

function Header({
  size = 'large',
  headingLevel = 1,
  avatar,
  title,
  titleMeta,
  stickers,
  meta,
  actions,
  onEditTitle,
  stacked,
  ...props
}: HeaderProps) {
  return (
    <Container {...props}>
      {avatar}
      <TitleContent stacked={stacked}>
        <WrapTitle>
          <Title level={headingLevel} size={headerSizeMap[size].heading}>
            <TitleMain>
              {title}
              {onEditTitle && <IconButton color="grey" icon="edit" aria-label="Edit title" onClick={onEditTitle} />}
            </TitleMain>
            {titleMeta && <TitleMeta>{titleMeta}</TitleMeta>}
          </Title>
          <Meta stickers={stickers} items={meta} />
        </WrapTitle>
        {actions && (
          <Actions stacked={stacked} absolute={!!avatar} align="end">
            {actions}
          </Actions>
        )}
      </TitleContent>
    </Container>
  );
}

export { Header };
