import { getUids } from '~/utils/getUids';

import type { Account, Entity, Maybe, User } from '~/utils/codegen/graphql';

let inspector: any;

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_AVO_API_KEY) {
  // NOTE: using require here to conditionally import it, worth looking at a cleaner way to do this
  // eslint-disable-next-line
  const Inspector = require('avo-inspector');

  const inspectorEnv =
    process.env.NEXT_PUBLIC_ENV === 'production'
      ? 'prod'
      : process.env.NEXT_PUBLIC_ENV === 'staging'
      ? 'staging'
      : 'dev';

  inspector = new Inspector.AvoInspector({
    apiKey: process.env.NEXT_PUBLIC_AVO_API_KEY,
    env: inspectorEnv,
    version: process.env.NEXT_PUBLIC_RELEASE,
    appName: 'Vouch Admin'
  });

  inspector.enableLogging(process.env.NODE_ENV !== 'production');
}

const resetAttributes = {
  id: undefined,
  vouch_id: undefined,
  video_id: undefined,
  campaign_id: undefined,
  campaign_name: undefined,
  template_id: undefined,
  template_name: undefined,
  template_type: undefined,
  template_title: undefined,
  share_type: undefined,
  playlist_id: undefined,
  playlist_name: undefined,
  download_id: undefined,
  answer_id: undefined,
  response_id: undefined,
  question_id: undefined,
  client_id: undefined,
  client_name: undefined,
  contact_id: undefined,
  contact_name: undefined,
  contact_email: undefined,
  contact_emails: [],
  contact_role_title: undefined,
  workspace_id: undefined,
  workspace_name: undefined,
  text: undefined,
  auth_method: undefined,
  embed_type: undefined,
  medium: undefined,
  type: undefined,
  mode: undefined,
  operation: undefined,
  variables: undefined,
  start: undefined,
  end: undefined,
  duration: undefined,
  metric_id: undefined,
  metric_name: undefined,
  metric_value: undefined,
  metric_score: undefined,
  status: undefined,
  tabs_id: undefined,
  tab_id: undefined,
  menu_id: undefined,
  menu_item_id: undefined,
  modal_id: undefined,
  form_id: undefined,
  button_id: undefined,
  questions: [],
  crop: undefined,
  new_ordinality: undefined,
  old_ordinality: undefined,
  split_offset: undefined,
  title: undefined,
  end_offset: undefined,
  start_offset: undefined,
  sidebar_name: undefined
};

type GtmEventData = {
  'gtm.start'?: number;
};

type AnalyticsValue = Maybe<string> | (Maybe<string> | undefined)[] | number;

type CustomEventData = { [key in keyof typeof resetAttributes]?: AnalyticsValue } & {
  user_id?: AnalyticsValue;
  user_email?: AnalyticsValue;
  user_name?: AnalyticsValue;
  customer_id?: AnalyticsValue;
  customer_name?: AnalyticsValue;
  use_cases?: AnalyticsValue;
  referred_by?: AnalyticsValue;
  product_name?: AnalyticsValue;
  billing_cycle?: AnalyticsValue;
  framework?: AnalyticsValue;
  app_info?: {
    framework?: AnalyticsValue;
    version?: AnalyticsValue;
    environment?: AnalyticsValue;
    context?: AnalyticsValue;
  };

  uid_client?: AnalyticsValue;
  uid_tab?: AnalyticsValue;
  uid_pageload?: AnalyticsValue;
  uid_request?: AnalyticsValue;
  uid_visitor?: AnalyticsValue;

  // Proposed new attributes to deprecate the old ones
  user?: Partial<User>;
  account?: Partial<Account>;
  entity?: Partial<Entity>;
};

type WebVitalsData = {
  metric_id?: string;
  metric_value?: number;
  metric_name?: string;
  value?: number;
};

type PurchaseData = {
  transaction_id?: string;
  value?: number;
  currency?: string;
  shipping?: number;
  items?: {
    name?: string;
    brand?: string;
    quantity?: number;
    price?: number;
  }[];
};

type EventAuthData = null | {
  entity?: Maybe<RecursivePartial<Entity>>;
  account?: Maybe<RecursivePartial<Account>>;
  user?: Maybe<RecursivePartial<User>>;
};

type FinalEventData = GtmEventData | CustomEventData | WebVitalsData | PurchaseData;

function event(name: string, data: FinalEventData = {}, authData: EventAuthData = {}) {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];

    const uids = getUids();
    const finalData: FinalEventData = {
      ...resetAttributes,

      // User data
      customer_id: authData?.entity?.id,
      customer_name: authData?.entity?.name,
      user_id: authData?.account?.id,
      user_email: authData?.account?.email,
      user_name: authData?.account?.name,
      use_cases: authData?.account?.useCases,

      // Proposed new attributes to deprecate the old ones
      user: { id: authData?.user?.id },
      account: { id: authData?.account?.id, email: authData?.account?.email },
      entity: { id: authData?.entity?.id, name: authData?.entity?.name, parentId: authData?.entity?.parentId },

      // Uids for logging
      uid_client: uids.client,
      uid_tab: uids.tab,
      uid_pageload: uids.pageload,
      uid_request: uids.request,
      uid_visitor: uids.visitor,

      // App info
      app_info: {
        framework: 'next',
        version: process.env.NEXT_PUBLIC_RELEASE as string,
        environment: process.env.NEXT_PUBLIC_ENV as string,
        context: 'admin'
      },

      ...data
    };

    const eventData = { event: name, ...finalData };
    window.dataLayer.push(eventData);

    inspector?.trackSchemaFromEvent?.(name, finalData);
  }
}

export { event };
