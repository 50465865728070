import { useEffect, useRef } from 'react';

import { useAnalyticsEvent } from '~/utils/analytics/useAnalyticsEvent';

type EventFn = ReturnType<typeof useAnalyticsEvent>;

function useAnalyticsOnMount(name: Parameters<EventFn>[0], data?: Parameters<EventFn>[1]) {
  const event = useAnalyticsEvent();

  const mounted = useRef(false);
  useEffect(
    () => {
      if (!mounted.current) {
        event(name, data);
        mounted.current = true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export { useAnalyticsOnMount };
