import { useCallback, lazy } from 'react';

import { useModal } from '~/components/modal';
import { useNotification, useErrorNotification } from '~/components/notification';
import { useMutation } from '~/utils/graphql';

import { INDIVIDUAL_SIGNUP_MUTATION } from '~/queries/individual-signup-mutation';

const ResendTeamMemberInviteModal = lazy(() => import('~/features/team/modals/ResendTeamMemberInviteModal'));

function useResendTeamMemberInvite() {
  const notification = useNotification();
  const errorNotification = useErrorNotification();
  const [individualSignup] = useMutation(INDIVIDUAL_SIGNUP_MUTATION);
  const { open } = useModal(ResendTeamMemberInviteModal);

  return useCallback(
    async ({ email }: { email: string }) => {
      try {
        const { data: mutationData } = await individualSignup({
          variables: {
            email
          }
        });
        notification.show({ message: 'Sign up link settings have been updated' });
        open({ signUpLink: mutationData?.individualSignup?.url || '' });
      } catch (e) {
        errorNotification.show(e);
      }
    },
    [errorNotification, individualSignup, notification, open]
  );
}

export { useResendTeamMemberInvite };
