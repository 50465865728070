import { useCallback } from 'react';

import { useAnswerActions } from '~/features/answers/actions';

type PlayPlaylistArgs = {
  answers: any[];
  title: string;
  startIndex?: number;
};

function usePlayPlaylist() {
  const answerActions = useAnswerActions();

  return useCallback(
    ({ answers }: PlayPlaylistArgs) => {
      answerActions.playerModal({ answers });
    },
    [answerActions]
  );
}

export { usePlayPlaylist };
