import { lazy } from 'react';

import { useModal } from '~/components/modal';

const InviteContactsModal = lazy(() => import('~/features/campaigns/modals/InviteContactsModal'));

function useInviteContacts() {
  const { open } = useModal(InviteContactsModal);
  return open;
}

export { useInviteContacts };
