import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { useFlags } from '~/utils/feature-flags';

import type { ReactNode } from 'react';

const MAX_WAITING_TIMEOUT_MS = 17500;

type CustomThumbnailExpectVouchUpdateContextValue = {
  startWaiting: () => void;
  finishWaiting: () => void;
  isWaiting: boolean;
};

const CustomThumbnailExpectVouchUpdateContext = createContext<CustomThumbnailExpectVouchUpdateContextValue>({
  startWaiting: () => {
    throw new Error('Not in a CustomThumbnailExpectVouchUpdateContext');
  },
  finishWaiting: () => {
    throw new Error('Not in a CustomThumbnailExpectVouchUpdateContext');
  },
  isWaiting: false
});

function CustomThumbnailExpectVouchUpdateProvider({ children }: { children: ReactNode }) {
  const flags = useFlags(['custom-thumbnail']);
  const customThumbnailEnabled = flags?.['custom-thumbnail']?.enabled;
  const ref = useRef<NodeJS.Timeout>();
  const [isWaiting, setIsWaiting] = useState(false);

  const startWaiting = useCallback(() => {
    setIsWaiting(true);

    ref.current = setTimeout(() => {
      // ensure that we don't hang in a waiting state indefinitely
      setIsWaiting(false);
    }, MAX_WAITING_TIMEOUT_MS);

    return () => {
      clearTimeout(ref.current);
    };
  }, []);

  const finishWaiting = useCallback(() => {
    setIsWaiting(false);
    clearTimeout(ref.current);
  }, []);

  const value = useMemo(() => {
    if (!customThumbnailEnabled) {
      return {
        startWaiting: () => {
          return;
        },
        finishWaiting: () => {
          return;
        },
        isWaiting: false
      };
    }
    return {
      startWaiting,
      finishWaiting,
      isWaiting
    };
  }, [startWaiting, finishWaiting, isWaiting, customThumbnailEnabled]);

  return (
    <CustomThumbnailExpectVouchUpdateContext.Provider value={value}>
      {children}
    </CustomThumbnailExpectVouchUpdateContext.Provider>
  );
}

function useCustomThumbnailExpectVouchUpdate() {
  return useContext(CustomThumbnailExpectVouchUpdateContext);
}

export {
  CustomThumbnailExpectVouchUpdateProvider as CustomThumbnailExpectVouchUpdateProvider,
  useCustomThumbnailExpectVouchUpdate as useCustomThumbnailExpectVouchUpdate
};
