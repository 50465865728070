import { styled } from '~/utils/styling';

const ButtonBar = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$tiny',

  variants: {
    align: {
      start: {
        justifyContent: 'flex-start'
      },
      center: {
        justifyContent: 'center'
      },
      end: {
        justifyContent: 'flex-end'
      }
    }
  },

  defaultVariants: {
    align: 'start'
  }
});

export { ButtonBar };
