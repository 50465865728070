import { lazy } from 'react';

import { useModal } from '~/components/modal';
import { usePlanUsage } from '~/features/billing/utils/usePlanUsage';
import CampaignLimitModal from '~/features/campaigns/modals/CampaignLimitModal';

import type { ComponentProps } from 'react';
import type { ModalPassthroughProps } from '~/components/modal';

const CampaignSetLiveConfirmationModal = lazy(
  () => import('~/features/campaigns/modals/CampaignSetLiveConfirmationModal')
);

function useSetLiveConfirmation() {
  const usage = usePlanUsage();
  const isAtLimit = !!usage?.limit?.campaigns && usage.usage.campaigns >= usage.limit.campaigns;

  const { open } = useModal(CampaignSetLiveConfirmationModal);
  const { open: openLimitModal } = useModal(CampaignLimitModal);

  // HACK: we do limit detection here cause for now it's easier to control the modal flow, but this should
  // get a cleanup when we release the new campaigns UX!
  if (isAtLimit) {
    return ({
      data,
      ...args
    }: Omit<ComponentProps<typeof CampaignSetLiveConfirmationModal>, keyof ModalPassthroughProps>) => {
      openLimitModal({ id: data.id, onSubmit: () => open({ data, ...args }) });
    };
  }

  return open;
}

export { useSetLiveConfirmation };
