import { Spacer } from '~/components/box';
import { Heading } from '~/components/text';
import { styled } from '~/utils/styling';

import type { ReactNode } from 'react';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const Content = styled('div', {
  width: '100%',
  maxWidth: '60rem'
});

const Title = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$medium',
  paddingRight: '$medium'
});

const Count = styled('span', {
  color: '$dark-600',
  marginLeft: '$small',
  fontWeight: '$regular'
});

const Actions = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$tiny',
  flex: 1
});

type ListHeaderProps = {
  title: ReactNode;
  description?: ReactNode;
  count?: number | null;
  actions?: ReactNode;
  filters?: ReactNode;
};

function ListHeader({ title, description, count, actions, filters }: ListHeaderProps) {
  return (
    <>
      <Container>
        <Title>
          <Heading level={1}>
            {title}
            {count !== undefined && <Count>{count}</Count>}
          </Heading>

          {filters}
        </Title>

        {actions && <Actions>{actions}</Actions>}
      </Container>

      {description && (
        <>
          <Spacer h="tiny" />
          <Content>{description}</Content>
        </>
      )}
    </>
  );
}

export { ListHeader };
