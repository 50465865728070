import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { useConfirmationModal } from '~/components/modal';
import { useNotification } from '~/components/notification';
import { useErrorNotification } from '~/components/notification';
import { useAnalyticsEvent } from '~/utils/analytics';
import { refetchOrEvict, useMutation } from '~/utils/graphql';
import { useRouter } from '~/utils/routing/useRouter';

import { DELETE_VOUCH_MUTATION } from '~/queries/delete-vouch-mutation';
import { SEARCH_ENTITY_QUERY } from '~/queries/search-entity-query';

function useDeletePlaylist() {
  const router = useRouter();
  const client = useApolloClient();
  const event = useAnalyticsEvent();

  const [deleteVouch] = useMutation(DELETE_VOUCH_MUTATION);

  const confirm = useConfirmationModal();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  return useCallback(
    ({ id, redirect = true }: { id: string; redirect?: boolean }) =>
      confirm.open({
        id: 'delete-playlist-modal',
        title: 'Delete playlist',
        message: <>Are you sure you want to delete this playlist? This action cannot be reverted.</>,
        confirmLabel: 'Yes, delete forever',
        onConfirm: async () => {
          try {
            await deleteVouch({ variables: { id } });
            event('playlist_deleted', { playlist_id: id });

            if (redirect) {
              await router.replace('/dashboard/playlists');
            }

            // Manually refetch queries, so that the details screen doesn't throw a 404 before
            // we redirect the user
            await refetchOrEvict({ client, include: [SEARCH_ENTITY_QUERY] });

            notification.show({ message: 'Playlist has been deleted successfully' });
          } catch (e) {
            errorNotification.show(e);
          }
        }
      }),
    [confirm, deleteVouch, event, notification, router, errorNotification, client]
  );
}

export { useDeletePlaylist };
