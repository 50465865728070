import { styled } from '~/utils/styling';

const Masthead = styled('header', {
  height: '3.8rem',
  width: '100%',
  padding: '0 $small',
  background: '$light-1000',
  borderBottomWidth: '$thin',
  borderBottomStyle: 'solid',
  borderBottomColor: '$dark-80',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  zIndex: 10,
  overflow: 'hidden'
});

export { Masthead };
