import { gql } from '~/utils/codegen';

export const GET_CAMPAIGNS_QUERY = gql(/* GraphQL */ `
  query GetCampaigns($status: CampaignStatus, $limit: Int, $nextToken: String) {
    campaigns(status: $status, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hashId
        name
        status
        createdAt
        updatedAt
        responseCount
        questions {
          items {
            id
            title
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
            }
          }
        }
      }
      count
      nextToken
    }
  }
`);
