import { gql } from '~/utils/codegen';

export const GET_ENTITIES_QUERY = gql(/* GraphQL */ `
  query GetEntities {
    entities {
      items {
        id
        hashId
        spaceId
        parentId
        logoSrc
        name
        siteUrl
      }
    }
  }
`);
