// TODO: check with BE if we can retrieve more invoice information from Stripe, e.g.
// the charged amount and what CC it was charged against, etc + also check if we can get
// the `provider` for the payment methods
// https://vouch.atlassian.net/browse/VCH-2493

import { gql } from '~/utils/codegen';

export const GET_SUBSCRIPTION_INVOICES_QUERY = gql(/* GraphQL */ `
  query GetSubscriptionInvoices {
    invoices {
      date
      product
      pdf
    }
  }
`);
