function formatDuration(seconds: number, options: { includeMs?: boolean; includeCs?: boolean } = {}) {
  // Just in case we are sending values the formatter can't handle
  if (seconds <= 0) {
    return `0:00${options.includeMs ? '.000' : options.includeCs ? '.00' : ''}`;
  }

  const duration = seconds;
  const dHours = Math.floor(duration / 3600);
  const dMinutes = Math.floor((duration % 3600) / 60);
  const dSeconds = Math.floor((duration % 3600) % 60);
  const dMilliseconds = Math.floor((duration % 1) * 1000);

  let durationStr = dHours
    ? `${dHours}:${`${dMinutes}`.padStart(2, '0')}:${`${dSeconds}`.padStart(2, '0')}`
    : `${dMinutes}:${`${dSeconds}`.padStart(2, '0')}`;

  if (options.includeMs) {
    durationStr += `.${`${dMilliseconds}`.padStart(3, '0')}`;
  } else if (options.includeCs) {
    durationStr += `.${`${Math.floor(dMilliseconds / 10)}`.padStart(2, '0')}`;
  }

  return durationStr;
}

export { formatDuration };
