/**
 * We're wrapping the apollo client here, to provide additional sugar on top of it
 * where needed and to be able switch out the underlying client in the future
 */

import * as apollo from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

import { createClient, createSSRClient } from '~/utils/apollo';

import type { QueryOptions, ApolloClient, MutationOptions } from '@apollo/client';
import type { GetServerSidePropsContext } from 'next';

function useQueryAll<Data = any, Variables extends apollo.OperationVariables = any>(
  query: Parameters<typeof apollo.useQuery<Data, Variables>>[0],
  options: Parameters<typeof apollo.useQuery<Data, Variables>>[1] = {}
) {
  const [loading, setLoading] = useState(true);
  // TODO: remove the `<any>` and instead derive the data object structure from the passed in query to get
  // type safety and IDE autocompletes
  const q = apollo.useQuery<Data, Variables>(query, options);

  const lastToken = useRef<string | undefined>();
  useEffect(() => {
    const queryKey = Object.keys(q.data || {})?.[0];
    if (q.loading) {
      return;
    }

    const data = (q?.data as any)?.[queryKey];
    if (!data?.nextToken) {
      setLoading(false);
      return;
    }

    if (lastToken.current === data?.nextToken) {
      return;
    }

    lastToken.current = data?.nextToken;
    setLoading(true);

    q.fetchMore<Data, any>({
      variables: { nextToken: data?.nextToken },
      updateQuery: (previous, { fetchMoreResult }) => {
        const previousData = (previous as any)?.[queryKey];
        const moreData = (fetchMoreResult as any)?.[queryKey];

        const previousItem = previousData?.items || [];
        const moreItems = moreData?.items || [];

        const mergedData = {
          ...previousData,
          ...moreData,
          items: [...previousItem, ...moreItems]
        };

        return { ...previousData, [queryKey]: mergedData } as any;
      }
    });
  }, [q]);

  return { ...q, loading };
}

function useLazyQueryAll(
  query: Parameters<typeof apollo.useLazyQuery>[0],
  options: Parameters<typeof apollo.useLazyQuery>[1] = {}
) {
  const [runQuery] = apollo.useLazyQuery(query, options);

  const queryAll = async (args: any) => {
    const firstPage: any = await runQuery(args);
    let allData: any = firstPage?.data; // TODO: type data based on `query`

    const queryKey = Object.keys(firstPage.data || {})?.[0];
    let nextToken = firstPage?.data?.[queryKey]?.nextToken;

    while (nextToken) {
      const nextPage: any = await runQuery({
        ...args,
        variables: { ...args.variables, nextToken }
      });

      const previousData: any = (allData as any)?.[queryKey];
      const moreData: any = nextPage?.data?.[queryKey];

      const previousItem = previousData?.items || [];
      const moreItems = moreData?.items || [];

      const mergedData = { ...previousData, ...moreData, items: [...previousItem, ...moreItems] };
      allData = { ...allData, [queryKey]: mergedData };

      if (nextPage.data?.[queryKey]?.nextToken === nextToken) {
        break;
      }

      nextToken = nextPage.data?.[queryKey]?.nextToken;
    }

    return { ...firstPage, data: allData };
  };

  return [queryAll];
}

type QueryServerArg = {
  context: GetServerSidePropsContext;
  query: any; // TODO: type properly
  variables?: any;
};

async function queryServer({ context, query, variables }: QueryServerArg) {
  const client = await createSSRClient({ context });
  const { data } = await client.query<any, any>({ query, variables }); // TODO: type based on `query`
  return { data, client };
}

type Include = apollo.DocumentNode | [query: apollo.DocumentNode, args: Record<any, any>];

type RefetchOrEvictArgs = {
  client: ApolloClient<any>;
  include: Include[];
};

/**
 * NOTE: this is a workaround for buggy/inconsistent behaviour in the legacy implementation of the in-build refetchQueries
 * functionality in Apollo: by default, Apollo will only refetch queries if they are active (= have a rendered component),
 * if they are just cached but the component that fetched it is unmounted, Apollo will not refetch the query (it will still
 * do it in dev mode, which is even more confusing)
 *
 * `client.refetchQuery` does have an `include: "all"` option, which based on the documentation should be doing what we
 * want, but again it doesn't work as expected in production where despite documentation it doesn't actually expose inactive
 * queries
 *
 * So the only working workaround I found was clearing lists from the cache manually via `client.cache.evict`, however this
 * doesn't wait for the refetch to finish if it is evicting an active cache item :|
 *
 * See:
 *   https://github.com/apollographql/apollo-client/issues/5419#issuecomment-598065442
 *   https://github.com/apollographql/apollo-client/issues/5419#issuecomment-962735383
 *   https://github.com/apollographql/apollo-client/issues/5419#issuecomment-1242511457
 *   https://github.com/apollographql/apollo-client/issues/6795#issuecomment-713198836
 **/
async function refetchOrEvict({ client = createClient(), include }: RefetchOrEvictArgs) {
  const queries = include.map((i) => {
    const doc: any = Array.isArray(i) ? i[0] : i;
    return {
      queryName: doc.definitions?.[0]?.name?.value,
      cacheName: doc.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value,
      cacheArgs: Array.isArray(i) ? i[1] : undefined
    };
  });

  let refetched: typeof queries = [];
  await client
    .refetchQueries({
      include: 'all',
      onQueryUpdated: (query) => {
        // This callback will be called for every *active* query in the cache, returning `true` will tell that query
        // to refetch -- we compare query name and arguments here to determine weather the given query is one of the
        // queries passed into via `includes`
        const update = queries.filter((q) => {
          if (q.queryName === query.queryName) {
            if (!Object.keys(q.cacheArgs || {}).find((key) => q.cacheArgs?.[key] !== query.options?.variables?.[key])) {
              return true;
            }
          }
          return false;
        });

        refetched = refetched.concat(update);
        return !!update?.length;
      }
    })
    .catch(() => {
      // noop
    });

  queries.forEach((query) => {
    // For all remaining queries in `includes` that were not refetched above, we assume they either have never
    // been fetched or they are inactive, so we clear them from the cache which will make sure they are re-fetched
    // whenever the responsible component mounts again
    if (!refetched.includes(query)) {
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: query.cacheName, args: query.cacheArgs });
    }
  });
  client.cache.gc();
}

/**
 * NOTE: the below are used for e2e tests (to run api queries through `page.evaluate`), so they don't need
 * to be type safe, but they do need to be able to accept strings as the mutation/query
 */

type QueryClientArg = Omit<QueryOptions, 'query'> & {
  query: string;
  client: ApolloClient<any>;
};

async function queryClient({ client = createClient(), query, ...options }: QueryClientArg) {
  return client.query<any, any>({ query: apollo.gql(query), ...options });
}

type MutateClientArg = Omit<MutationOptions, 'mutation'> & {
  mutation: string;
  client: ApolloClient<any>;
};

async function mutateClient({ client = createClient(), mutation, ...options }: MutateClientArg) {
  return client.mutate<any, any>({ mutation: apollo.gql(mutation), ...options });
}

const useQuery = apollo.useQuery;
const useLazyQuery = apollo.useLazyQuery;
const useMutation = apollo.useMutation;

export {
  useQuery,
  useQueryAll,
  useLazyQuery,
  useLazyQueryAll,
  useMutation,
  queryServer,
  queryClient,
  mutateClient,
  refetchOrEvict
};
