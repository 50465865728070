import { forwardRef } from 'react';

import { headingVariants, styled } from '~/utils/styling';

import type { ComponentProps, ForwardedRef } from 'react';

const HeadingCore = styled('h1', {
  variants: {
    size: headingVariants,
    truncate: {
      true: {
        minWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    }
  }
});

type HeadingLevels = 1 | 2 | 3 | 4;

type HeadingProps = ComponentProps<typeof HeadingCore> & {
  level: HeadingLevels;
};

type HeadingElements = 'h1' | 'h2' | 'h3' | 'h4';

type HeadingSizeDefaultsMap = {
  [key in HeadingLevels]: HeadingProps['size'];
};

const headingSizeDefaultsMap: HeadingSizeDefaultsMap = {
  1: 'large',
  2: 'medium',
  3: 'small',
  4: 'tiny'
};

const Heading = forwardRef(function Heading(
  { level, size = headingSizeDefaultsMap[level], ...props }: HeadingProps,
  ref: ForwardedRef<HTMLHeadingElement>
) {
  const as: HeadingElements = `h${level}`;
  return <HeadingCore ref={ref} as={as} {...props} size={size} />;
});

export { Heading };
