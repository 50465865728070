type GetAppAuthLink = {
  pathname: string;
  query?: { [key: string]: any };
  storage: any;
};

function getAppAuthLink({ pathname, query = {}, storage }: GetAppAuthLink) {
  const url = new window.URL(pathname);

  const search = new window.URLSearchParams(query);

  const code = window.btoa(encodeURIComponent(JSON.stringify(storage)));
  const hash = new window.URLSearchParams({ code });

  return `${url}?${search}#${hash}`;
}

export { getAppAuthLink };
