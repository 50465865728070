import { lazy } from 'react';

import { useModal } from '~/components/modal';

const MoveCampaignModal = lazy(() => import('~/features/campaigns/modals/MoveCampaignModal'));

function useMoveCampaign() {
  const { open } = useModal(MoveCampaignModal);
  return open;
}

export { useMoveCampaign };
