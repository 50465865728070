import { lazy } from 'react';

import { useModal } from '~/components/modal';

const ShareEmbedCodeModal = lazy(() => import('~/features/share/modals/ShareEmbedCodeModal'));

function useShareEmbedCode() {
  const { open } = useModal(ShareEmbedCodeModal);
  return open;
}

export { useShareEmbedCode };
