import { styled } from '~/utils/styling';

import LogoSvg from '~/assets/logo.svg';

const Logo = styled(LogoSvg, {
  height: '1.3rem',
  width: 'auto',
  color: '$s-brand-800',
  marginTop: '-$wee',
  display: 'flex'
});

export { Logo };
