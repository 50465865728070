import { lazy } from 'react';

import { useModal } from '~/components/modal';

const AnswerPlayerModal = lazy(() => import('~/features/answers/modals/AnswerPlayerModal'));

function useAnswerPlayerModal() {
  const { open } = useModal(AnswerPlayerModal);
  return open;
}

export { useAnswerPlayerModal };
