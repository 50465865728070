import now from 'performance-now';

type Timers = {
  [key: string]: { start: number; end?: null | number; desc?: string };
};

const _timers: Timers = {};

function timerStart(id: string, options?: { start?: number; desc?: string }) {
  _timers[id] = { start: options?.start || now(), end: null, desc: options?.desc };
}

function timerEnd(id: string) {
  if (!_timers[id]?.start) {
    return;
  }
  _timers[id] = { ..._timers[id], end: now() };
}

function getServerTimingHeader() {
  return Object.keys(_timers)
    .reduce<string[]>((all, key) => {
      const end = _timers[key].end || now();
      const dur = end - _timers[key].start;
      all.push(`${key};desc="${_timers[key].desc || key}";dur=${dur}`);
      return all;
    }, [])
    .join(',');
}

export { timerStart, timerEnd, getServerTimingHeader };
