import { forwardRef } from 'react';

import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';
import type { FieldPassthroughProps } from '~/components/form/Field';

const Input = styled('input', {
  height: '$medium',
  width: '100%',
  padding: '0 $small',
  borderRadius: '$tiny',
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: '$dark-200',
  background: '$light-1000',

  '&:hover, &:focus': {
    borderColor: '$dark-400'
  },

  '&[disabled], fieldset[disabled] &': {
    background: '$dark-80',
    color: '$dark-400',

    '&, &:hover, &:focus': {
      borderColor: '$dark-200'
    }
  },

  '-moz-appearance': 'textfield',
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },

  variants: {
    size: {
      small: {
        height: '$small'
      },
      medium: {
        height: '$medium'
      }
    },
    error: {
      true: {
        borderColor: '$s-danger-500',
        background: '$s-danger-50',
        outlineColor: '$s-danger-500'
      }
    }
  },

  defaultVariants: {
    size: 'medium'
  }
});

type TextInputProps = Omit<ComponentProps<typeof Input>, 'onChange' | 'onBlur' | 'error'> & FieldPassthroughProps;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function TextInput(props, ref) {
  const { getValue, setValue, error, setError, clearError, ...rest } = props;
  return (
    <Input
      ref={ref as any}
      type="text"
      value={getValue?.()}
      onChange={(e) => setValue?.(e.target.value)}
      error={!!error}
      {...rest}
    />
  );
});

export { TextInput };
