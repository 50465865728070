type PermissionErrorArg = {
  message?: string;
};

class PermissionError extends Error {
  constructor({ message = 'Permission denied' }: PermissionErrorArg = {}) {
    super(message);
    this.name = 'CustomPermissionError';
  }

  static code = 403 as const;
  code = PermissionError.code;
}

type NotFoundErrorArg = {
  message?: string;
  context?: any;
};

class NotFoundError extends Error {
  constructor({ message = 'Not found', context }: NotFoundErrorArg = {}) {
    super(message);
    this.name = 'CustomNotFoundError';
    this.context = context;
  }

  static code = 404 as const;
  code = NotFoundError.code;
  context: any | undefined = undefined;
}

export { PermissionError, NotFoundError };
