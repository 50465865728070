import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ActionMenu } from '~/components/action-menu';
import { Avatar } from '~/components/avatar';
import { IconButton } from '~/components/button';
import { useErrorNotification } from '~/components/notification';
import { Text } from '~/components/text';
// NOTE: We can't import global actions because of failing tests
import { useSwitchAccount } from '~/features/global/actions/useSwitchAccount';
import { useTeamActions } from '~/features/team/actions';
import { useWorkspaceActions } from '~/features/workspaces/actions';
import { useAuth } from '~/utils/auth';
import { usePermissions } from '~/utils/permissions';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { ComponentProps, MouseEvent } from 'react';
import type { Items } from '~/components/action-menu/ActionMenuContent';

const Container = styled('button', {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$tiny',
  cursor: 'pointer',
  background: 'transparent',
  border: '0 none',
  width: '100%',
  minWidth: '12rem',
  maxWidth: '18rem',
  position: 'relative',
  borderRadius: '$tiny',
  padding: '$tiny',
  overflow: 'hidden',

  '&[disabled]': {
    '&, &:focus, &:hover': {
      cursor: 'default',
      color: '$dark-600',
      pointerEvents: 'none'
    }
  },

  '&:focus, &:hover, &[data-selected="true"]': {
    background: '$dark-80'
  },

  variants: {
    indented: {
      true: {
        paddingLeft: '$large'
      }
    },

    selected: {
      true: {
        background: '$dark-80'
      }
    }
  }
});

const Title = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '$tiny',
  overflow: 'hidden'
});

const Actions = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$wee',
  marginLeft: '$small'
});

type WorkspaceSwitcherItemProps = ComponentProps<typeof Container> & {
  workspace: any;
  path?: string;
  showActions?: boolean;
};

function WorkspaceSwitcherItem({ workspace, path, showActions = true, ...props }: WorkspaceSwitcherItemProps) {
  const auth = useAuth();
  const router = useRouter();
  const client = useApolloClient();
  const permissions = usePermissions();
  const teamActions = useTeamActions();
  const workspaceActions = useWorkspaceActions();
  const errorNotification = useErrorNotification();

  const [loading, setLoading] = useState(false);

  const disabled = auth?.status === 'switchingEntity';
  const selected = workspace.hashId === auth?.entity?.hashId;

  const switchAccount = useSwitchAccount({ path });

  const handleAddWorkspace = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      workspaceActions.createWorkspace();
    },
    [workspaceActions]
  );

  const handleSwitchAccount = useCallback(async () => {
    if (workspace.id !== auth?.entity?.id && !disabled) {
      try {
        setLoading(true);
        auth?.setStatus('switchingEntity');
        await switchAccount({ entityHash: workspace.hashId });
        await client.clearStore();
      } catch (e) {
        errorNotification.show(e);
      } finally {
        setLoading(false);
        auth?.setStatus('idle');
      }
    }
  }, [auth, switchAccount, workspace, errorNotification, disabled, client]);

  const actionItems: Items = useMemo(
    () => [
      {
        label: 'Manage plan',
        visible: workspace.hashId === auth?.entity?.hashId && !workspace.parentId,
        // NOTE: this is a nested action menu, so `href` doesn't work at the moment because the
        // mousedown event will close the outer action menu before the link click can trigger
        onClick: () => router.push('/dashboard/billing')
      },
      {
        label: 'Invite team members',
        visible: workspace.hashId === auth?.entity?.hashId,
        onClick: () => teamActions.inviteMembers()
      },
      {
        label: 'Rename Space',
        visible: workspace.hashId === auth?.entity?.hashId,
        onClick: () => workspaceActions.renameWorkspace()
      },
      {
        label: 'Delete Space',
        intent: 'danger',
        visible: !!workspace.parentId,
        onClick: () => workspaceActions.deleteWorkspace({ id: workspace.id })
      }
    ],
    [auth?.entity?.hashId, workspace, workspaceActions, teamActions, router]
  );

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMounted(true);
    }
  }, []);

  return (
    <Container
      {...props}
      selected={selected}
      onClick={handleSwitchAccount}
      indented={!!workspace.parentId}
      disabled={disabled}
    >
      <Title>
        <Avatar size="medium" name={workspace.name} imageSrc={workspace.logoSrc} color="purple" loading={loading} />
        <Text truncate weight={!workspace.parentId ? 'bold' : undefined}>
          {workspace.name}
        </Text>
      </Title>

      {showActions && mounted && permissions.isAdmin && workspace.spaceId === auth?.entity?.spaceId && (
        <Actions>
          {!workspace.parentId && permissions.canUseFeature('entity:spaces') && (
            <IconButton
              color="grey"
              icon="add"
              tooltip="Create a child Space"
              aria-label="Add new Space"
              disabled={disabled}
              onClick={handleAddWorkspace}
            />
          )}
          {(!!workspace.parentId || workspace.hashId === auth?.entity?.hashId) && (
            <ActionMenu items={actionItems} placement="bottom-end">
              {(props) => (
                <IconButton color="grey" icon="more" aria-label="More actions" disabled={disabled} {...props} />
              )}
            </ActionMenu>
          )}
        </Actions>
      )}
    </Container>
  );
}

export { WorkspaceSwitcherItem };
