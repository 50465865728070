import { lazy } from 'react';

import { useModal } from '~/components/modal';

const CreateCampaignModal = lazy(() => import('~/features/campaigns/modals/CreateCampaignModal'));

function useCreateCampaign() {
  const { open } = useModal(CreateCampaignModal);
  return open;
}

export { useCreateCampaign };
