import { lazy } from 'react';

import { useModal } from '~/components/modal';

const PlaylistEmbedSettingsModal = lazy(() => import('~/features/playlists/modals/PlaylistEmbedSettingsModal'));

function usePlaylistEmbedSettings() {
  const { open } = useModal(PlaylistEmbedSettingsModal);
  return open;
}

export { usePlaylistEmbedSettings };
