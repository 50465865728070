import * as Sentry from '@sentry/nextjs';
import { createContext, useContext, useMemo, useState, useCallback } from 'react';

import { styled } from '~/utils/styling';

import type { Icon } from '../icon';
import type { ReactNode, PropsWithChildren, ComponentProps } from 'react';

import { Notification } from './Notification';

const Container = styled('div', {
  position: 'fixed',
  bottom: 0,
  right: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '$small',
  gap: '$tiny',
  pointerEvents: 'none',
  zIndex: 200
});

type NotificationData = {
  id: string;
  type?: 'default' | 'error';
  message: ReactNode;
  timeout?: number;
  hide: () => void;
  icon?: ComponentProps<typeof Icon>['name'];
  title?: string;
};

type NotificationContextValue = {
  addNotification?: (data: NotificationData) => void;
  removeNotification?: (id: string) => void;
};

const NotificationContext = createContext<NotificationContextValue>({});

function NotificationProvider({ children }: PropsWithChildren<Record<never, any>>) {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const value = useMemo<NotificationContextValue>(() => {
    return {
      addNotification: (data) => setNotifications((state) => [...state, data]),
      removeNotification: (id: string) => setNotifications((state) => state.filter((item) => item.id !== id))
    };
  }, []);

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Container>
        {notifications.map((notification) => (
          <Notification key={notification.id} {...notification} />
        ))}
      </Container>
    </NotificationContext.Provider>
  );
}

let uuid = 0;
function useNotification() {
  const { addNotification, removeNotification } = useContext(NotificationContext);

  const show = useCallback(
    (data: Omit<NotificationData, 'id' | 'hide'>): NotificationData => {
      const id = `${++uuid}`;
      const item = { ...data, id, hide: () => removeNotification?.(id) };
      addNotification?.(item);
      return item;
    },
    [addNotification, removeNotification]
  );

  return useMemo(() => ({ show }), [show]);
}

type UseErrorNotificationOptions = {
  message?: string;
};

function useErrorNotification() {
  const notification = useNotification();

  const show = useCallback(
    (e: any, options: UseErrorNotificationOptions = {}) => {
      // We normalise the error here, mainly because amplify sometimes throws strings instead of error
      // objects, so `e` will be a string here :/
      const error = !e ? new Error('Unknown error occurred') : typeof e === 'string' ? new Error(e) : e;

      notification.show({ type: 'error', message: options?.message || error.message });
      if (__DEV__) {
        console.error(error);
      }

      e.message = `Error Notification: ${error.message}`;
      Sentry?.captureException(error);
    },
    [notification]
  );

  return useMemo(() => ({ show }), [show]);
}

export { useNotification, useErrorNotification, NotificationProvider };
