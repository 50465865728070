import { forwardRef } from 'react';

import type { SVGProps } from 'react';

const icons = {
  action: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m160 16-16 80 64 24L96 240l16-80-64-24L160 16z" />
        <path className="stroke-duotone-primary" fill="none" d="m160 16-16 80 64 24L96 240l16-80-64-24L160 16z" />
      </svg>
    );
  }),
  add: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={40} y={40} width={176} height={176} rx={16} />
        <path className="stroke-duotone-primary" fill="none" d="M40 128h176m-88-88v176" />
      </svg>
    );
  }),
  ai: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="m84.27 171.73-55.09-20.3a7.92 7.92 0 0 1 0-14.86l55.09-20.3 20.3-55.09a7.92 7.92 0 0 1 14.86 0l20.3 55.09 55.09 20.3a7.92 7.92 0 0 1 0 14.86l-55.09 20.3-20.3 55.09a7.92 7.92 0 0 1-14.86 0Z"
        />
        <path
          className="stroke-duotone-primary"
          d="m84.27 171.73-55.09-20.3a7.92 7.92 0 0 1 0-14.86l55.09-20.3 20.3-55.09a7.92 7.92 0 0 1 14.86 0l20.3 55.09 55.09 20.3a7.92 7.92 0 0 1 0 14.86l-55.09 20.3-20.3 55.09a7.92 7.92 0 0 1-14.86 0ZM176 16v48m48 8v32m-72-64h48m8 48h32"
          fill="none"
        />
      </svg>
    );
  }),
  analytics: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M152 40h56v168h-56z" />
        <path className="stroke-duotone-primary" fill="none" d="M48 208v-72h48m128 72H32m64 0V88h56m0 120V40h56v168" />
      </svg>
    );
  }),
  'arrow-clockwise': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={88} />
        <path className="stroke-duotone-primary" d="M184 104h48V56" fill="none" />
        <path className="stroke-duotone-primary" d="M188.4 192a88 88 0 1 1 1.83-126.23L232 104" fill="none" />
      </svg>
    );
  }),
  'arrow-counter-clockwise': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={88} />
        <path className="stroke-duotone-primary" fill="none" d="M24 56v48h48" />
        <path className="stroke-duotone-primary" d="M67.59 192a88 88 0 1 0-1.82-126.23L24 104" fill="none" />
      </svg>
    );
  }),
  'arrow-left': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m112 56-72 72 72 72V56z" />
        <path className="stroke-duotone-primary" fill="none" d="M216 128H112m0-72-72 72 72 72V56z" />
      </svg>
    );
  }),
  'arrow-right': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m144 56 72 72-72 72V56z" />
        <path className="stroke-duotone-primary" fill="none" d="M40 128h104m0-72 72 72-72 72V56z" />
      </svg>
    );
  }),
  'brand-apple-mail': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 341 216"
        width="1em"
        height="1em"
        ref={ref}
        {...props}
      >
        <path
          fill="#fff"
          d="M5.64 8.543A23.904 23.904 0 0 0 0 24v168a23.895 23.895 0 0 0 5.069 14.753L104 107.823 10.284 14.107a40.239 40.239 0 0 1-4.645-5.564zm105.431 106.351 32.467 32.467c15.621 15.621 40.948 15.621 56.569 0l32.467-32.467 96.942 96.941A23.89 23.89 0 0 1 316 216H24c-4.063 0-7.89-1.01-11.244-2.792l98.315-98.314zm128.574-7.071 93.716-93.716c1-1.001 1.937-2.042 2.81-3.117A23.886 23.886 0 0 1 340 24v168a23.88 23.88 0 0 1-3.573 12.605l-96.782-96.782z"
        />
        <path
          fill="#fff"
          d="M329.241 2.608 193.75 138.1c-12.106 12.106-31.734 12.106-43.84 0L13.293 1.482A23.95 23.95 0 0 1 21.616 0h296.733a23.9 23.9 0 0 1 10.892 2.608z"
        />
      </svg>
    );
  }),
  'brand-facebook': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0 0 5.373 0 12Z" fill="#1877F2" />
        <path
          d="M18 12c0-3.3-2.7-6-6-6s-6 2.7-6 6c0 3 2.175 5.475 5.025 5.925v-4.2h-1.5V12h1.5v-1.35c0-1.5.9-2.325 2.25-2.325.675 0 1.35.15 1.35.15v1.5h-.75c-.75 0-.975.45-.975.9V12h1.65l-.3 1.725h-1.425V18c3-.45 5.175-3 5.175-6Z"
          fill="#fff"
        />
      </svg>
    );
  }),
  'brand-gmail': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 92.12" width="1em" height="1em" ref={ref} {...props}>
        <path fill="#4285f4" d="M8.36 92.12H27.9V44.67L0 23.76v60.08c0 4.58 3.78 8.28 8.36 8.28z" />
        <path fill="#34a853" d="M94.97 92.12h19.54c4.67 0 8.36-3.78 8.36-8.36v-60l-27.9 20.91v47.45z" />
        <path fill="#fbbc04" d="M94.97 8.4v36.27l27.91-20.91V12.58c0-10.37-11.82-16.25-20.11-10.05l-7.8 5.87z" />
        <path fill="#ea4335" d="M27.9 44.67V8.4l33.54 25.17L94.97 8.4v36.27L61.44 69.76 27.9 44.67z" />
        <path fill="#c5221f" d="M0 12.58v11.18l27.91 20.91V8.4l-7.8-5.87C11.82-3.66 0 2.29 0 12.58z" />
      </svg>
    );
  }),
  'brand-google': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="1em" height="1em" ref={ref} {...props}>
        <path
          fill="#FFC107"
          d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
        />
        <path
          fill="#FF3D00"
          d="m6.306 14.691 6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"
        />
        <path
          fill="#4CAF50"
          d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"
        />
        <path
          fill="#1976D2"
          d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"
        />
      </svg>
    );
  }),
  'brand-linkedin': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0 0 5.373 0 12Z" fill="#2867B2" />
        <path
          d="M8.7 18H6.15V9.975H8.7V18ZM7.425 8.85C6.6 8.85 6 8.25 6 7.425 6 6.6 6.675 6 7.425 6c.825 0 1.425.6 1.425 1.425 0 .825-.6 1.425-1.425 1.425ZM18 18h-2.55v-4.35c0-1.275-.525-1.65-1.275-1.65-.75 0-1.5.6-1.5 1.725V18h-2.55V9.975h2.4V11.1c.225-.525 1.125-1.35 2.4-1.35 1.425 0 2.925.825 2.925 3.3V18H18Z"
          fill="#fff"
        />
      </svg>
    );
  }),
  'brand-microsoft-outlook': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Livello_1"
        x={0}
        y={0}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        width="1em"
        height="1em"
        ref={ref}
        {...props}
      >
        <style>
          {
            '.st1{fill:#0364b8}.st2{fill:#0078d4}.st3{fill:#28a8ea}.st10,.st8{enable-background:new}.st8{opacity:.1}.st10{opacity:.2}'
          }
        </style>
        <path
          fill="#0a2767"
          d="M512 267.91c.03-4-2.04-7.73-5.45-9.82h-.06l-.21-.12-177.42-105.02c-.77-.52-1.56-.99-2.38-1.42a23.844 23.844 0 0 0-21.84 0c-.82.43-1.62.9-2.38 1.42L124.84 257.96l-.21.12c-5.42 3.37-7.08 10.5-3.71 15.92.99 1.6 2.36 2.93 3.99 3.88L302.32 382.9c.77.51 1.56.99 2.38 1.42a23.844 23.844 0 0 0 21.84 0c.82-.43 1.61-.9 2.38-1.42l177.41-105.02c3.55-2.06 5.71-5.87 5.67-9.97z"
        />
        <path
          d="M145.53 197.79h116.43v106.72H145.53V197.79zM488.19 89.3V40.48c.28-12.21-9.38-22.33-21.59-22.62H164.47c-12.21.29-21.87 10.42-21.59 22.62V89.3l178.6 47.63L488.19 89.3z"
          className="st1"
        />
        <path d="M142.88 89.3h119.07v107.16H142.88V89.3z" className="st2" />
        <path d="M381.02 89.3H261.95v107.16l119.07 107.16h107.16V196.47L381.02 89.3z" className="st3" />
        <path d="M261.95 196.47h119.07v107.16H261.95V196.47z" className="st2" />
        <path d="M261.95 303.63h119.07v107.16H261.95V303.63z" className="st1" />
        <path fill="#14447d" d="M145.53 304.51h116.43v97.02H145.53v-97.02z" />
        <path d="M381.02 303.63h107.16v107.16H381.02V303.63z" className="st2" />
        <linearGradient
          id="SVGID_1_"
          x1={315.534}
          x2={315.534}
          y1={-877.426}
          y2={-651.193}
          gradientTransform="translate(0 1145.333)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#35b8f1" />
          <stop offset={1} stopColor="#28a8ea" />
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="m506.55 277.23-.23.12-177.41 99.78c-.77.48-1.56.93-2.38 1.33a24.703 24.703 0 0 1-9.62 2.38l-9.69-5.67c-.82-.41-1.61-.87-2.38-1.37l-179.8-102.61h-.08l-5.88-3.29v202c.09 13.48 11.09 24.33 24.56 24.24h344.18c.2 0 .38-.1.6-.1 2.85-.18 5.65-.77 8.33-1.74 1.16-.49 2.28-1.07 3.35-1.74.8-.45 2.17-1.44 2.17-1.44 6.1-4.51 9.71-11.64 9.74-19.23V267.91c-.01 3.86-2.1 7.42-5.46 9.32z"
        />
        <path
          fill="#0a2767"
          d="M502.47 267.11v12.38L316.96 407.22 124.9 271.28c0-.07-.05-.12-.12-.12l-17.62-10.6v-8.93l7.26-.12 15.36 8.81.36.12 1.31.83s180.51 103 180.99 103.23l6.91 4.05c.6-.24 1.19-.48 1.91-.71.36-.24 179.2-100.85 179.2-100.85l2.01.12z"
          opacity={0.5}
        />
        <path
          fill="#1490df"
          d="m506.55 277.23-.23.13-177.41 99.78a24.854 24.854 0 0 1-26.6 0L124.9 277.36l-.21-.13a10.724 10.724 0 0 1-5.61-9.32V469.9c.09 13.47 11.08 24.33 24.55 24.24h343.83c13.47.09 24.47-10.77 24.55-24.24V267.91c-.01 3.86-2.1 7.42-5.46 9.32z"
        />
        <path
          d="m331.49 375.67-2.66 1.49c-.77.49-1.56.94-2.38 1.35a24.645 24.645 0 0 1-9.36 2.48l67.5 79.82 117.75 28.37a24.33 24.33 0 0 0 7.47-9.32L331.49 375.67z"
          className="st8"
        />
        <path
          d="m343.52 368.9-14.68 8.25c-.77.49-1.56.94-2.38 1.35a24.645 24.645 0 0 1-9.36 2.48l31.62 87.19 153.66 20.97A24.064 24.064 0 0 0 512 469.9v-2.61L343.52 368.9z"
          opacity={0.05}
        />
        <path
          d="M143.96 494.14h343.46c5.29.03 10.44-1.64 14.7-4.76L307.2 375.2c-.82-.41-1.61-.87-2.38-1.37l-179.8-102.61h-.08l-5.87-3.31v201.3c-.01 13.75 11.13 24.92 24.89 24.93z"
          className="st3"
        />
        <path
          d="M285.77 134.94v253.98a21.89 21.89 0 0 1-13.69 20.24c-2.56 1.1-5.31 1.67-8.1 1.67H119.07v-285.8h23.81v-11.91h121.09c12.04.04 21.77 9.79 21.8 21.82z"
          className="st8"
        />
        <path
          d="M273.86 146.85v253.98c.03 2.88-.58 5.72-1.79 8.33a21.701 21.701 0 0 1-20 13.54h-133V125.02h133c3.45-.03 6.86.83 9.88 2.5a21.657 21.657 0 0 1 11.91 19.33z"
          className="st10"
        />
        <path
          d="M273.86 146.85v230.16c-.06 12.02-9.77 21.77-21.79 21.87h-133V125.02h133c3.45-.03 6.86.83 9.88 2.5a21.657 21.657 0 0 1 11.91 19.33z"
          className="st10"
        />
        <path
          d="M261.95 146.85v230.16c-.01 12.04-9.75 21.81-21.79 21.87H119.07V125.02h121.09c12.04.01 21.8 9.77 21.79 21.81v.02z"
          className="st10"
        />
        <linearGradient
          id="SVGID_2_"
          x1={45.507}
          x2={216.447}
          y1={-1037.364}
          y2={-741.303}
          gradientTransform="translate(0 1145.333)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#1784d9" />
          <stop offset={0.5} stopColor="#107ad5" />
          <stop offset={1} stopColor="#0a63c9" />
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M21.83 125.02h218.3c12.05 0 21.83 9.77 21.83 21.83v218.3c0 12.05-9.77 21.83-21.83 21.83H21.83C9.77 386.98 0 377.2 0 365.15v-218.3c0-12.05 9.77-21.83 21.83-21.83z"
        />
        <path
          fill="#fff"
          d="M68.22 216.56a63.503 63.503 0 0 1 24.93-27.54 75.387 75.387 0 0 1 39.61-9.93 70.147 70.147 0 0 1 36.66 9.42 62.893 62.893 0 0 1 24.25 26.31 84.833 84.833 0 0 1 8.5 38.7 89.623 89.623 0 0 1-8.75 40.48 64.39 64.39 0 0 1-25 27.23 72.935 72.935 0 0 1-38.03 9.67 71.614 71.614 0 0 1-37.47-9.53 64 64 0 0 1-24.58-26.36 82.914 82.914 0 0 1-8.6-38.23 92.12 92.12 0 0 1 8.48-40.22zm26.57 64.66a41.145 41.145 0 0 0 14.03 18.12 36.693 36.693 0 0 0 21.91 6.6 38.38 38.38 0 0 0 23.39-6.81 39.443 39.443 0 0 0 13.62-18.12 70.33 70.33 0 0 0 4.35-25.28c.09-8.7-1.29-17.36-4.1-25.6-2.48-7.44-7.06-14-13.19-18.88a36.21 36.21 0 0 0-23.18-7.14 37.729 37.729 0 0 0-22.42 6.66 41.528 41.528 0 0 0-14.29 18.28 72.645 72.645 0 0 0-.1 52.21l-.02-.04z"
        />
        <path fill="#50d9ff" d="M381.02 89.3h107.16v107.16H381.02V89.3z" />
      </svg>
    );
  }),
  'brand-twitter': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0 0 5.373 0 12Z" fill="#1DA1F2" />
        <path
          d="M18 8.25c-.45.225-.9.3-1.425.375.525-.3.9-.75 1.05-1.35-.45.3-.975.45-1.575.6a2.619 2.619 0 0 0-1.8-.75c-1.575 0-2.775 1.5-2.4 3-2.025-.075-3.825-1.05-5.1-2.55-.675 1.125-.3 2.55.75 3.3-.375 0-.75-.15-1.125-.3 0 1.125.825 2.175 1.95 2.475-.375.075-.75.15-1.125.075a2.43 2.43 0 0 0 2.325 1.725c-.9.675-2.25 1.05-3.525.9 1.125.675 2.4 1.125 3.75 1.125 4.575 0 7.125-3.825 6.975-7.35.525-.3.975-.75 1.275-1.275Z"
          fill="#fff"
        />
      </svg>
    );
  }),
  brand: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M128 192a24 24 0 0 1 24-24h46.21a24 24 0 0 0 23.4-18.65 96.48 96.48 0 0 0 2.39-22.18c-.45-52.82-44.16-95.7-97-95.17a96 96 0 0 0-95 96c0 41.81 26.73 73.44 64 86.61A24 24 0 0 0 128 192Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M128 192a24 24 0 0 1 24-24h46.21a24 24 0 0 0 23.4-18.65 96.48 96.48 0 0 0 2.39-22.18c-.45-52.82-44.16-95.7-97-95.17a96 96 0 0 0-95 96c0 41.81 26.73 73.44 64 86.61A24 24 0 0 0 128 192Z"
          fill="none"
        />
        <circle className="fill-duotone-primary" cx={128} cy={76} r={12} />
        <circle className="fill-duotone-primary" cx={84} cy={100} r={12} />
        <circle className="fill-duotone-primary" cx={84} cy={156} r={12} />
        <circle className="fill-duotone-primary" cx={172} cy={100} r={12} />
      </svg>
    );
  }),
  calendar: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M40 88h176V48a8 8 0 0 0-8-8H48a8 8 0 0 0-8 8Z" />
        <rect className="stroke-duotone-primary" x={40} y={40} width={176} height={176} rx={8} fill="none" />
        <path className="stroke-duotone-primary" fill="none" d="M176 24v32M80 24v32M40 88h176" />
      </svg>
    );
  }),
  camera: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={24} y={64} width={176} height={128} rx={8} />
        <rect className="stroke-duotone-primary" x={24} y={64} width={176} height={128} rx={8} fill="none" />
        <path className="stroke-duotone-primary" fill="none" d="m200 112 48-32v96l-48-32" />
      </svg>
    );
  }),
  campaigns: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={40} y={96} width={176} height={112} rx={8} />
        <rect className="stroke-duotone-primary" x={40} y={96} width={176} height={112} rx={8} fill="none" />
        <path className="stroke-duotone-primary" fill="none" d="M56 64h144M72 32h112" />
      </svg>
    );
  }),
  'caret-down': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m208 96-80 80-80-80h160z" />
        <path className="stroke-duotone-primary" fill="none" d="m208 96-80 80-80-80h160z" />
      </svg>
    );
  }),
  'caret-left': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m160 208-80-80 80-80v160z" />
        <path className="stroke-duotone-primary" fill="none" d="m160 208-80-80 80-80v160z" />
      </svg>
    );
  }),
  'caret-right': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m96 48 80 80-80 80V48z" />
        <path className="stroke-duotone-primary" fill="none" d="m96 48 80 80-80 80V48z" />
      </svg>
    );
  }),
  'caret-up': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <polygon className="fill-duotone-secondary" points="48 160 128 80 208 160 48 160" />
        <polygon className="stroke-duotone-primary" points="48 160 128 80 208 160 48 160" fill="none" />
      </svg>
    );
  }),
  'chevron-down': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m178 103-50 50-50-50h100Z" />
        <path className="stroke-duotone-primary" d="m178 103-50 50-50-50" fill="none" />
      </svg>
    );
  }),
  'chevron-left': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m153 178-50-50 50-50v100Z" />
        <path className="stroke-duotone-primary" d="m153 178-50-50 50-50" fill="none" />
      </svg>
    );
  }),
  'chevron-right': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m103 78 50 50-50 50V78Z" />
        <path className="stroke-duotone-primary" d="m103 78 50 50-50 50" fill="none" />
      </svg>
    );
  }),
  'chevron-up': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg
        viewBox="0 0 256 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        ref={ref}
        {...props}
      >
        <path d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m78 153 50-50 50 50H78Z" />
        <path className="stroke-duotone-primary" d="m78 153 50-50 50 50" />
      </svg>
    );
  }),
  'cloud-arrow-up': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <path
          d="M160,48A80,80,0,0,0,85.9,97.84l0-.1A56,56,0,1,0,72,208h88a80,80,0,0,0,0-160Z"
          opacity={0.2}
          className="fill-duotone-secondary"
        />
        <path d="M112,208H72A56,56,0,1,1,85.92,97.74" fill="none" className="stroke-duotone-primary" />
        <polyline points="120 160 152 128 184 160" fill="none" className="head stroke-duotone-primary" />
        <line x1={152} y1={208} x2={152} y2={128} fill="none" className="shaft stroke-duotone-primary" />
        <path d="M80,128a80,80,0,1,1,112,73.34" fill="none" className="stroke-duotone-primary" />
      </svg>
    );
  }),
  'cloud-check': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <path
          d="M160,48A80,80,0,0,0,85.9,97.84l0-.1A56,56,0,1,0,72,208h88a80,80,0,0,0,0-160Z"
          opacity={0.2}
          className="fill-duotone-secondary"
        />
        <path d="M80,128a80,80,0,1,1,80,80H72A56,56,0,1,1,85.92,97.74" fill="none" className="stroke-duotone-primary" />
        <polyline points="120 136 144 160 192 112" fill="none" className="stroke-duotone-primary" />
      </svg>
    );
  }),
  'cloud-warning': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <path
          d="M80,127.62a80,80,0,1,1,80,80H72A56,56,0,1,1,85.93,97.36"
          opacity={0.2}
          className="fill-duotone-secondary"
        />
        <path d="M80,128a80,80,0,1,1,80,80H72A56,56,0,1,1,85.92,97.74" fill="none" className="stroke-duotone-primary" />
        <line x1={160} y1={128} x2={160} y2={88} fill="none" className="stroke-duotone-primary" />
        <circle cx={160} cy={164} r={12} className="fill-duotone-primary" />
      </svg>
    );
  }),
  command: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M180 48a28 28 0 0 1 28 28 28 28 0 0 1-28 28h-28V76a28 28 0 0 1 28-28ZM104 104H76a28 28 0 0 1-28-28 28 28 0 0 1 28-28 28 28 0 0 1 28 28v28ZM152 152h28a28 28 0 0 1 28 28 28 28 0 0 1-28 28 28 28 0 0 1-28-28v-28ZM76 208a28 28 0 0 1-28-28 28 28 0 0 1 28-28h28v28a28 28 0 0 1-28 28Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M76 208a28 28 0 0 1-28-28 28 28 0 0 1 28-28h28v28a28 28 0 0 1-28 28ZM180 48a28 28 0 0 1 28 28 28 28 0 0 1-28 28h-28V76a28 28 0 0 1 28-28ZM104 104H76a28 28 0 0 1-28-28 28 28 0 0 1 28-28 28 28 0 0 1 28 28v28ZM152 152h28a28 28 0 0 1 28 28 28 28 0 0 1-28 28 28 28 0 0 1-28-28v-28ZM104 104h48v48h-48z"
          fill="none"
        />
      </svg>
    );
  }),
  control: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <path className="fill-duotone-secondary" d="M48 160L128 80L208 160H48Z" />
        <path className="stroke-duotone-primary" d="M48 160L128 80L208 160" fill="none" />
      </svg>
    );
  }),
  copy: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M160 40a39.83 39.83 0 0 1 8 24v8H88v-8a39.83 39.83 0 0 1 8-24H56a8 8 0 0 0-8 8v168a8 8 0 0 0 8 8h144a8 8 0 0 0 8-8V48a8 8 0 0 0-8-8Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M160 40h40a8 8 0 0 1 8 8v168a8 8 0 0 1-8 8H56a8 8 0 0 1-8-8V48a8 8 0 0 1 8-8h40"
          fill="none"
        />
        <path className="stroke-duotone-primary" d="M88 72v-8a40 40 0 0 1 80 0v8Z" fill="none" />
      </svg>
    );
  }),
  crop: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M64 64h128v128H64z" />
        <path className="stroke-duotone-primary" fill="none" d="M64 24v168h168M24 64h40" />
        <path className="stroke-duotone-primary" fill="none" d="M96 64h96v96m0 32v40" />
      </svg>
    );
  }),
  cross: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={40} y={40} width={176} height={176} rx={16} />
        <path className="stroke-duotone-primary" d="M200 56 56 200m144 0L56 56" />
      </svg>
    );
  }),
  'dark-mode': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M108.11 28.11a96.09 96.09 0 0 0 119.78 119.78A96 96 0 1 1 108.11 28.11Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M108.11 28.11a96.09 96.09 0 0 0 119.78 119.78A96 96 0 1 1 108.11 28.11Z"
          fill="none"
        />
      </svg>
    );
  }),
  delete: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M200 56v152a8 8 0 0 1-8 8H64a8 8 0 0 1-8-8V56Z" />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="M216 56H40m64 48v64m48-64v64m48-112v152a8 8 0 0 1-8 8H64a8 8 0 0 1-8-8V56m112 0V40a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v16"
        />
      </svg>
    );
  }),
  done: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={96} />
        <path className="stroke-duotone-primary" fill="none" d="m88 136 24 24 56-56" />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={96} fill="none" />
      </svg>
    );
  }),
  download: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M56 32h144a16 16 0 0 1 16 16v160H40V48a16 16 0 0 1 16-16Z" />
        <path className="stroke-duotone-primary" fill="none" d="M128 144V32m88 112v64H40v-64" />
        <path className="stroke-duotone-primary" fill="none" d="m168 104-40 40-40-40" />
      </svg>
    );
  }),
  draft: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M216 160h-56v56l56-56z" />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="M96 96h64m-64 32h64m-64 32h32m28.69 56H48a8 8 0 0 1-8-8V48a8 8 0 0 1 8-8h160a8 8 0 0 1 8 8v108.69a8 8 0 0 1-2.34 5.65l-51.32 51.32a8 8 0 0 1-5.65 2.34Z"
        />
        <path className="stroke-duotone-primary" fill="none" d="M215.28 159.99H160v55.29" />
      </svg>
    );
  }),
  drag: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={48} y={16} width={160} height={224} rx={16} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={92} cy={60} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={164} cy={60} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={92} cy={128} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={164} cy={128} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={92} cy={196} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={164} cy={196} r={8} />
      </svg>
    );
  }),
  edit: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="m192 120-56-56 29.66-29.66a8 8 0 0 1 11.31 0L221.66 79a8 8 0 0 1 0 11.31Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M92.69 216H48a8 8 0 0 1-8-8v-44.69a8 8 0 0 1 2.34-5.65L165.66 34.34a8 8 0 0 1 11.31 0L221.66 79a8 8 0 0 1 0 11.31L98.34 213.66a8 8 0 0 1-5.65 2.34ZM136 64l56 56"
          fill="none"
        />
      </svg>
    );
  }),
  email: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m224 96-78.54 56h-34.91L32 96l96-64 96 64z" />
        <path
          className="stroke-duotone-primary"
          d="M32 96v104a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8V96l-96-64Zm78.55 56-76.08 53.74m187.06 0L145.45 152"
          fill="none"
        />
        <path className="stroke-duotone-primary" fill="none" d="m224 96-78.54 56h-34.91L32 96" />
      </svg>
    );
  }),
  embed: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m192 168 48-40-48-40H64l-48 40 48 40h128z" />
        <path className="stroke-duotone-primary" fill="none" d="m64 88-48 40 48 40m128-80 48 40-48 40M160 40 96 216" />
      </svg>
    );
  }),
  equals: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <rect className="fill-duotone-secondary" x={40} y={40} width={176} height={176} rx={16} />
        <line className="stroke-duotone-primary" x1={40} y1={160} x2={216} y2={160} />
        <line className="stroke-duotone-primary" x1={40} y1={96} x2={216} y2={96} />
      </svg>
    );
  }),
  error: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={96} />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={96} fill="none" />
        <path className="stroke-duotone-primary" fill="none" d="M128 136V80" />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={128} cy={172} r={6} />
      </svg>
    );
  }),
  extension: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M64 216a8 8 0 0 1-8-8v-42.69a28 28 0 1 1 0-50.62V72a8 8 0 0 1 8-8h46.69a28 28 0 1 1 50.61 0H208a8 8 0 0 1 8 8v42.69a28 28 0 1 0 0 50.62V208a8 8 0 0 1-8 8Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M64 216a8 8 0 0 1-8-8v-42.69a28 28 0 1 1 0-50.62V72a8 8 0 0 1 8-8h46.69a28 28 0 1 1 50.61 0H208a8 8 0 0 1 8 8v42.69a28 28 0 1 0 0 50.62V208a8 8 0 0 1-8 8Z"
          fill="none"
        />
      </svg>
    );
  }),
  'eye-dropper': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="m182.43 113.17 4.88 4.89a16 16 0 0 1 0 22.63l-9 9a8 8 0 0 1-11.31 0L106.34 89a8 8 0 0 1 0-11.31l9-9a16 16 0 0 1 22.63 0l4.89 4.88 25-25.1c10.79-10.79 28.37-11.45 39.44-1a28 28 0 0 1 .57 40.15Z"
        />
        <path
          className="stroke-duotone-primary"
          d="m182.43 113.17 4.88 4.89a16 16 0 0 1 0 22.63l-9 9a8 8 0 0 1-11.31 0L106.34 89a8 8 0 0 1 0-11.31l9-9a16 16 0 0 1 22.63 0l4.89 4.88 25-25.1c10.79-10.79 28.37-11.45 39.44-1a28 28 0 0 1 .57 40.15Z"
          fill="none"
        />
        <path
          className="stroke-duotone-primary"
          d="m159.31 141.94-56.68 56.69a32 32 0 0 1-32.06 8l-20 8.74a8 8 0 0 1-8.86-1.67 5.74 5.74 0 0 1-1.2-6.36l9.19-21.06a32 32 0 0 1 7.7-32.87l56.69-56.68"
          fill="none"
        />
      </svg>
    );
  }),
  facebook: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={96} />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={96} fill="none" />
        <path className="stroke-duotone-primary" d="M168,88H152a24,24,0,0,0-24,24V224" fill="none" />
        <line className="stroke-duotone-primary" x1={96} y1={144} x2={160} y2={144} fill="none" />
      </svg>
    );
  }),
  filter: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M34.1 61.38A8 8 0 0 1 40 48h176a8 8 0 0 1 5.92 13.38L152 136v58.65a8 8 0 0 1-3.56 6.66l-32 21.33A8 8 0 0 1 104 216v-80Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M34.1 61.38A8 8 0 0 1 40 48h176a8 8 0 0 1 5.92 13.38L152 136v58.65a8 8 0 0 1-3.56 6.66l-32 21.33A8 8 0 0 1 104 216v-80Z"
          fill="none"
        />
      </svg>
    );
  }),
  'format-bold': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M160 120h-12a36 36 0 0 0 0-72H80v152h80a40 40 0 0 0 0-80Z" />
        <path
          className="stroke-duotone-primary"
          d="M80 120h80a40 40 0 0 1 0 80H80V48h68a36 36 0 0 1 0 72"
          fill="none"
        />
      </svg>
    );
  }),
  'format-italic': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M112 56h80l-48 144H64l48-144z" />
        <path className="stroke-duotone-primary" fill="none" d="m152 56-48 144m-40 0h80M112 56h80" />
      </svg>
    );
  }),
  'format-ol': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M104 64h112v128H104z" />
        <path className="stroke-duotone-primary" d="M104 128h112M104 64h112M104 192h112" />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="M56 104V40l-16 8m32 160H40l28.68-38.37a15.69 15.69 0 0 0-3.24-22.41 16.78 16.78 0 0 0-23.06 3.15 15.85 15.85 0 0 0-2.38 4.3"
        />
      </svg>
    );
  }),
  'format-ul': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M88 64h128v128H88z" />
        <path className="stroke-duotone-primary" fill="none" d="M88 64h128M88.01 128H216M88.01 192H216" />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={44} cy={64} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={44} cy={128} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={44} cy={192} r={8} />
      </svg>
    );
  }),
  'format-underlined': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M184 56v80a56 56 0 0 1-112 0V56Z" />
        <path className="stroke-duotone-primary" fill="none" d="M64 224h128m-8-168v80a56 56 0 0 1-112 0V56" />
      </svg>
    );
  }),
  grid: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={48} y={48} width={64} height={64} rx={8} />
        <rect className="fill-duotone-secondary" x={144} y={48} width={64} height={64} rx={8} />
        <rect className="fill-duotone-secondary" x={48} y={144} width={64} height={64} rx={8} />
        <rect className="fill-duotone-secondary" x={144} y={144} width={64} height={64} rx={8} />
        <rect className="stroke-duotone-primary" x={144} y={144} width={64} height={64} rx={8} fill="none" />
        <rect className="stroke-duotone-primary" x={48} y={48} width={64} height={64} rx={8} fill="none" />
        <rect className="stroke-duotone-primary" x={144} y={48} width={64} height={64} rx={8} fill="none" />
        <rect className="stroke-duotone-primary" x={48} y={144} width={64} height={64} rx={8} fill="none" />
      </svg>
    );
  }),
  handle: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" ref={ref} {...props}>
        <path
          className="fill-duotone-primary"
          d="M208,32V224a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V32A16,16,0,0,1,64,16H192A16,16,0,0,1,208,32Z"
          opacity={0.2}
        />
        <path
          className="stroke-duotone-primary"
          d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z"
        />
      </svg>
    );
  }),
  help: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={96} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={128} cy={180} r={6} />
        <path
          className="stroke-duotone-primary"
          d="M128 144v-8c17.67 0 32-12.54 32-28s-14.33-28-32-28-32 12.54-32 28v4"
          fill="none"
        />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={96} fill="none" />
      </svg>
    );
  }),
  history: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={88} />
        <path className="stroke-duotone-primary" fill="none" d="M128 80v48l40 24m-96-48H32V64" />
        <path
          className="stroke-duotone-primary"
          d="M67.6 192a88 88 0 1 0-1.83-126.23C54 77.69 44.28 88.93 32 104"
          fill="none"
        />
      </svg>
    );
  }),
  info: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={96} />
        <path className="stroke-duotone-primary" d="M120 120a8 8 0 0 1 8 8v40a8 8 0 0 0 8 8" fill="none" />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={96} fill="none" />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={124} cy={84} r={6} />
      </svg>
    );
  }),
  introduction: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M213.27 104 196 74a20 20 0 0 0-34.64 20l-30-52a20 20 0 0 0-34.71 20l-6.92-12a20 20 0 0 0-34.65 20l14.24 24.67a20 20 0 0 0-34.64 20L74.7 184a80 80 0 0 0 138.57-80Z"
        />
        <path className="stroke-duotone-primary" d="M96.65 62a20 20 0 0 1 34.64-20l30 52" fill="none" />
        <path className="stroke-duotone-primary" d="M69.32 94.67 55.08 70a20 20 0 0 1 34.65-20l31.17 54" fill="none" />
        <path
          className="stroke-duotone-primary"
          d="M158.87 160a40 40 0 0 1 9.13-54.42L161.32 94A20 20 0 0 1 196 74l17.31 30A80 80 0 0 1 74.7 184l-40-69.32a20 20 0 0 1 34.64-20L88.57 128M192 33.78A51.84 51.84 0 0 1 223.67 58l.33.57M74.62 232A111.88 111.88 0 0 1 47 200"
          fill="none"
        />
      </svg>
    );
  }),
  invite: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={108} cy={100} r={60} />
        <path className="stroke-duotone-primary" fill="none" d="M200 136h48m-24-24v48" />
        <circle className="stroke-duotone-primary" cx={108} cy={100} r={60} fill="none" />
        <path className="stroke-duotone-primary" d="M24 200c20.55-24.45 49.56-40 84-40s63.45 15.55 84 40" fill="none" />
      </svg>
    );
  }),
  launch: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={40} y={72} width={144} height={144} rx={8} />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="m136 120 80-80m0 64-.01-63.99L152 40m32 96v72a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8V80a8 8 0 0 1 8-8h72"
        />
      </svg>
    );
  }),
  'light-mode': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={128} cy={128} r={56} />
        <path className="stroke-duotone-primary" fill="none" d="M128 40V16" />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={56} fill="none" />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="M64 64 48 48m16 144-16 16M192 64l16-16m-16 144 16 16M40 128H16m112 88v24m88-112h24"
        />
      </svg>
    );
  }),
  link: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M113.94 209.94a48 48 0 0 1-67.88-67.88l96-96a48 48 0 0 1 67.88 67.88Z"
        />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="m96 160 64-64m-48-19.89 30.06-30a48 48 0 0 1 67.88 67.88L179.88 144M76.11 112l-30 30.06a48 48 0 0 0 67.88 67.88L144 179.88"
        />
      </svg>
    );
  }),
  linkedin: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <rect className="fill-duotone-secondary" x={32} y={32} width={192} height={192} rx={8} />
        <rect className="stroke-duotone-primary" x={32} y={32} width={192} height={192} rx={8} fill="none" />
        <line className="stroke-duotone-primary" x1={120} y1={112} x2={120} y2={176} fill="none" />
        <line className="stroke-duotone-primary" x1={88} y1={112} x2={88} y2={176} fill="none" />
        <path className="stroke-duotone-primary" d="M120,140a28,28,0,0,1,56,0v36" fill="none" />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={88} cy={84} r={6} />
      </svg>
    );
  }),
  list: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={40} y={144} width={176} height={56} rx={8} />
        <rect className="fill-duotone-secondary" x={40} y={56} width={176} height={56} rx={8} />
        <rect className="stroke-duotone-primary" x={40} y={144} width={176} height={56} rx={8} fill="none" />
        <rect className="stroke-duotone-primary" x={40} y={56} width={176} height={56} rx={8} fill="none" />
      </svg>
    );
  }),
  live: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M20.75 13.25a1.2 1.2 0 0 1-.875-.375A1.2 1.2 0 0 1 19.5 12a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375 1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375Zm-17.5 0a1.2 1.2 0 0 1-.875-.375A1.2 1.2 0 0 1 2 12a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375 1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375ZM12 22a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875 1.2 1.2 0 0 1 .375-.875A1.2 1.2 0 0 1 12 19.5a1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875a1.2 1.2 0 0 1-.375.875A1.2 1.2 0 0 1 12 22Zm3.675-12.625L14.6 8.3 18.9 4l1.075 1.075-4.3 4.3Zm-7.35 0-4.3-4.3L5.1 4l4.3 4.3-1.075 1.075ZM18.9 19.95l-4.3-4.3 1.075-1.075 4.3 4.3L18.9 19.95Zm-13.825.025L4 18.9l4.325-4.325L9.4 15.65l-4.325 4.325ZM12 4.5a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875 1.2 1.2 0 0 1 .375-.875A1.2 1.2 0 0 1 12 2a1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875a1.2 1.2 0 0 1-.375.875A1.2 1.2 0 0 1 12 4.5Z"
          fill="currentColor"
        />
      </svg>
    );
  }),
  lock: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={40} y={88} width={176} height={128} rx={8} />
        <rect className="stroke-duotone-primary" x={40} y={88} width={176} height={128} rx={8} fill="none" />
        <path className="stroke-duotone-primary" d="M88 88V56a40 40 0 0 1 80 0v32" fill="none" />
      </svg>
    );
  }),
  'mark-unwatched': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M128 56c-80 0-112 72-112 72s32 72 112 72 112-72 112-72-32-72-112-72Zm0 112a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z"
        />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="m48 40 160 176m-53.09-58.4a40 40 0 0 1-53.82-59.2m34.44-9.69a40 40 0 0 1 32.3 35.53"
        />
        <path
          className="stroke-duotone-primary"
          d="M208.61 169.1C230.41 149.58 240 128 240 128s-32-72-112-72a126 126 0 0 0-20.68 1.68M74 68.6C33.23 89.24 16 128 16 128s32 72 112 72a118.05 118.05 0 0 0 54-12.6"
          fill="none"
        />
      </svg>
    );
  }),
  megaphone: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M144 69.09 50.24 40.33A8 8 0 0 0 40 48v144a8 8 0 0 0 10.24 7.67L144 170.91Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M226.24 145.68A8 8 0 0 0 232 138v-36a8 8 0 0 0-5.76-7.68l-176-54A8 8 0 0 0 40 48v144a8 8 0 0 0 10.24 7.68Z"
          fill="none"
        />
        <path
          className="stroke-duotone-primary"
          d="M192 156.18V192a8 8 0 0 1-8 8h-32a8 8 0 0 1-8-8V69.09"
          fill="none"
        />
      </svg>
    );
  }),
  menu: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M40 64h176v128H40z" />
        <path className="stroke-duotone-primary" fill="none" d="M40 128h176M40 64h176M40 192h176" />
      </svg>
    );
  }),
  microphone: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={88} y={24} width={80} height={144} rx={40} />
        <rect className="stroke-duotone-primary" x={88} y={24} width={80} height={144} rx={40} fill="none" />
        <path className="stroke-duotone-primary" fill="none" d="M128 200v40m72-112a72 72 0 0 1-144 0" />
      </svg>
    );
  }),
  minus: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={40} y={40} width={176} height={176} rx={16} />
        <path className="stroke-duotone-primary" fill="none" d="M40 128h176" />
      </svg>
    );
  }),
  more: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={80} y={16} width={96} height={224} rx={16} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={128} cy={128} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={128} cy={60} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={128} cy={196} r={8} />
      </svg>
    );
  }),
  note: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M216 160h-56v56l56-56z" />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="M96 96h64m-64 32h64m-64 32h32m28.69 56H48a8 8 0 0 1-8-8V48a8 8 0 0 1 8-8h160a8 8 0 0 1 8 8v108.69a8 8 0 0 1-2.34 5.65l-51.32 51.32a8 8 0 0 1-5.65 2.34Z"
        />
        <path className="stroke-duotone-primary" fill="none" d="M215.28 159.99H160v55.29" />
      </svg>
    );
  }),
  pause: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={152} y={40} width={56} height={176} rx={8} />
        <rect className="fill-duotone-secondary" x={48} y={40} width={56} height={176} rx={8} />
        <rect className="stroke-duotone-primary" x={152} y={40} width={56} height={176} rx={8} fill="none" />
        <rect className="stroke-duotone-primary" x={48} y={40} width={56} height={176} rx={8} fill="none" />
      </svg>
    );
  }),
  pin: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M229.66 98.34a8 8 0 0 0 0-11.31L169 26.34a8 8 0 0 0-11.31 0l-57.3 57.46S72.64 69.93 43 93.85a8 8 0 0 0-.65 11.91l107.9 107.89a8 8 0 0 0 12-.83c8.39-11.16 21.57-34.09 10.11-57Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M229.66 98.34a8 8 0 0 0 0-11.31L169 26.34a8 8 0 0 0-11.31 0l-57.3 57.46S72.64 69.93 43 93.85a8 8 0 0 0-.65 11.91l107.9 107.89a8 8 0 0 0 12-.83c8.39-11.16 21.57-34.09 10.11-57ZM96.29 159.71 48 208"
          fill="none"
        />
      </svg>
    );
  }),
  play: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M72 39.88v176.24a8 8 0 0 0 12.15 6.69l144.08-88.12a7.82 7.82 0 0 0 0-13.38L84.15 33.19A8 8 0 0 0 72 39.88Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M72 39.88v176.24a8 8 0 0 0 12.15 6.69l144.08-88.12a7.82 7.82 0 0 0 0-13.38L84.15 33.19A8 8 0 0 0 72 39.88Z"
          fill="none"
        />
      </svg>
    );
  }),
  playlists: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M208 48H48a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16Zm-96 104V88l48 32Z"
        />
        <rect
          className="stroke-duotone-primary"
          x={32}
          y={48}
          width={192}
          height={144}
          rx={16}
          transform="rotate(180 128 120)"
          fill="none"
        />
        <path className="stroke-duotone-primary" fill="none" d="M160 224H96m64-104-48-32v64l48-32z" />
      </svg>
    );
  }),
  processing: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M192 184H64v32a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8Z" />
        <path
          className="stroke-duotone-primary"
          d="M128 128 67.2 82.4A8 8 0 0 1 64 76V40a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8v35.64a8 8 0 0 1-3.18 6.36L128 128m0 0-60.8 45.6A8 8 0 0 0 64 180v36a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8v-35.64a8 8 0 0 0-3.18-6.38L128 128m-64 56h128"
          fill="none"
        />
      </svg>
    );
  }),
  public: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M160.71 140.26a8.11 8.11 0 0 0-3.12-1.11l-22.82-3.08a8 8 0 0 0-8.38 4.67l-13.7 30.74a8 8 0 0 0 1.44 8.69l19.74 20.33a8 8 0 0 1 2 6.95l-3.17 16.44a96 96 0 0 0 80.41-51.41Z"
        />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={96} fill="none" />
        <path
          className="stroke-duotone-primary"
          d="M75.88 123.26 89.2 100a8 8 0 0 1 6.94-4h16.71a7.9 7.9 0 0 0 3.86-1L129 88.24a7.12 7.12 0 0 0 1.49-1.07l26.92-24.33A8 8 0 0 0 159 53l-10.5-18.81"
          fill="none"
        />
        <path
          className="stroke-duotone-primary"
          d="M67.78 53.23 56 81.08a8 8 0 0 0-.12 5.92l11.5 30.67a8 8 0 0 0 5.81 5l21.43 4.61a8 8 0 0 1 5.52 4.33l3.8 7.87a8 8 0 0 0 7.2 4.51h13.8"
          fill="none"
        />
        <path
          className="fill-duotone-secondary"
          d="M75.88 123.26 89.2 100a8 8 0 0 1 6.94-4h16.71a7.9 7.9 0 0 0 3.86-1L129 88.24a7.12 7.12 0 0 0 1.49-1.07l26.92-24.33A8 8 0 0 0 159 53l-10.5-18.81A96.62 96.62 0 0 0 128 32a95.61 95.61 0 0 0-60.22 21.23L56 81.08a8 8 0 0 0-.12 5.92l11.5 30.67a8 8 0 0 0 5.81 5Z"
        />
        <path
          className="stroke-duotone-primary"
          d="m213.09 172.48-52.38-32.22a8.11 8.11 0 0 0-3.12-1.11l-22.82-3.08a8 8 0 0 0-8.38 4.67l-13.7 30.74a8 8 0 0 0 1.44 8.69l19.74 20.33a8 8 0 0 1 2 6.95l-3.17 16.44"
          fill="none"
        />
      </svg>
    );
  }),
  'qr-code': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={48} y={48} width={64} height={64} rx={8} />
        <rect className="fill-duotone-secondary" x={48} y={144} width={64} height={64} rx={8} />
        <rect className="fill-duotone-secondary" x={144} y={48} width={64} height={64} rx={8} />
        <rect className="stroke-duotone-primary" x={48} y={48} width={64} height={64} rx={8} fill="none" />
        <rect className="stroke-duotone-primary" x={48} y={144} width={64} height={64} rx={8} fill="none" />
        <rect className="stroke-duotone-primary" x={144} y={48} width={64} height={64} rx={8} fill="none" />
        <path className="stroke-duotone-primary" fill="none" d="M144 144v32m0 32h32v-64m0 16h32m0 32v16" />
      </svg>
    );
  }),
  remind: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M56 104a72 72 0 0 1 144 0c0 35.82 8.3 64.6 14.9 76a8 8 0 0 1-6.9 12H48a8 8 0 0 1-6.88-12C47.71 168.6 56 139.81 56 104Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M96 192a32 32 0 0 0 64 0M56 104a72 72 0 0 1 144 0c0 35.82 8.3 64.6 14.9 76a8 8 0 0 1-6.9 12H48a8 8 0 0 1-6.88-12C47.71 168.6 56 139.81 56 104Z"
          fill="none"
        />
      </svg>
    );
  }),
  scene: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="m115.5 92.19-47.79-27.6-29.86 7.88A7.76 7.76 0 0 0 32.27 82l8.16 30ZM126.61 49.05l47.79 27.59 33.6-8.87-8.16-30a7.9 7.9 0 0 0-9.66-5.49Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M40 112h176v88a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8v-88ZM40.43 112 208 67.77l-8.16-30a7.9 7.9 0 0 0-9.66-5.49L37.85 72.47A7.76 7.76 0 0 0 32.27 82ZM67.71 64.59l47.79 27.6M126.61 49.05l47.79 27.59"
          fill="none"
        />
      </svg>
    );
  }),
  screen: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect
          className="fill-duotone-secondary"
          x={32}
          y={48}
          width={192}
          height={144}
          rx={16}
          transform="rotate(180 128 120)"
        />
        <rect
          className="stroke-duotone-primary"
          x={32}
          y={48}
          width={192}
          height={144}
          rx={16}
          transform="rotate(180 128 120)"
          fill="none"
        />
        <path className="stroke-duotone-primary" fill="none" d="M160 224H96" />
      </svg>
    );
  }),
  search: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={112} cy={112} r={80} />
        <circle className="stroke-duotone-primary" cx={112} cy={112} r={80} fill="none" />
        <path className="stroke-duotone-primary" fill="none" d="M168.57 168.57 224 224" />
      </svg>
    );
  }),
  sent: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M48.49 221.28A8 8 0 0 0 59.93 231l168-96.09a8 8 0 0 0 0-14l-168-95.85a8 8 0 0 0-11.44 9.67L80 128Z"
        />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="M144 128H80m-31.51 93.28A8 8 0 0 0 59.93 231l168-96.09a8 8 0 0 0 0-14l-168-95.85a8 8 0 0 0-11.44 9.67L80 128Z"
        />
      </svg>
    );
  }),
  settings: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="m207.86 123.18 16.78-21a99.14 99.14 0 0 0-10.07-24.29l-26.7-3a81 81 0 0 0-6.81-6.81l-3-26.71a99.43 99.43 0 0 0-24.3-10l-21 16.77a81.59 81.59 0 0 0-9.64 0l-21-16.78a99.14 99.14 0 0 0-24.21 10.07l-3 26.7a81 81 0 0 0-6.81 6.81l-26.71 3a99.43 99.43 0 0 0-10 24.3l16.77 21a81.59 81.59 0 0 0 0 9.64l-16.78 21a99.14 99.14 0 0 0 10.07 24.29l26.7 3a81 81 0 0 0 6.81 6.81l3 26.71a99.43 99.43 0 0 0 24.3 10l21-16.77a81.59 81.59 0 0 0 9.64 0l21 16.78a99.14 99.14 0 0 0 24.29-10.07l3-26.7a81 81 0 0 0 6.81-6.81l26.71-3a99.43 99.43 0 0 0 10-24.3l-16.77-21a81.59 81.59 0 0 0-.08-9.64ZM128 168a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z"
        />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={40} fill="none" />
        <path
          className="stroke-duotone-primary"
          d="M41.43 178.09a99.14 99.14 0 0 1-10.07-24.29l16.78-21a81.59 81.59 0 0 1 0-9.64l-16.77-21a99.43 99.43 0 0 1 10.05-24.3l26.71-3a81 81 0 0 1 6.81-6.81l3-26.7a99.14 99.14 0 0 1 24.26-9.99l21 16.78a81.59 81.59 0 0 1 9.64 0l21-16.77a99.43 99.43 0 0 1 24.3 10.05l3 26.71a81 81 0 0 1 6.81 6.81l26.7 3a99.14 99.14 0 0 1 10.07 24.29l-16.78 21a81.59 81.59 0 0 1 0 9.64l16.77 21a99.43 99.43 0 0 1-10 24.3l-26.71 3a81 81 0 0 1-6.81 6.81l-3 26.7a99.14 99.14 0 0 1-24.29 10.07l-21-16.78a81.59 81.59 0 0 1-9.64 0l-21 16.77a99.43 99.43 0 0 1-24.3-10l-3-26.71a81 81 0 0 1-6.81-6.81Z"
          fill="none"
        />
      </svg>
    );
  }),
  share: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M48 104h160v112H48z" />
        <path
          className="stroke-duotone-primary"
          d="M176 104h24a8 8 0 0 1 8 8v96a8 8 0 0 1-8 8H56a8 8 0 0 1-8-8v-96a8 8 0 0 1 8-8h24m8-40 40-40 40 40m-40-40v112"
          fill="none"
        />
      </svg>
    );
  }),
  shift: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="m32 120 96-96 96 96h-48v88a8 8 0 0 1-8 8H88a8 8 0 0 1-8-8v-88Z" />
        <path
          className="stroke-duotone-primary"
          d="m32 120 96-96 96 96h-48v88a8 8 0 0 1-8 8H88a8 8 0 0 1-8-8v-88Z"
          fill="none"
        />
      </svg>
    );
  }),
  'speech-bubble-dots': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <path
          className="fill-duotone-secondary"
          d="M79.93,211.11a96,96,0,1,0-35-35h0L32.42,213.46a8,8,0,0,0,10.12,10.12l37.39-12.47Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M79.93,211.11a96,96,0,1,0-35-35h0L32.42,213.46a8,8,0,0,0,10.12,10.12l37.39-12.47Z"
          fill="none"
        />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={128} cy={128} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={84} cy={128} r={8} />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={172} cy={128} r={8} />
      </svg>
    );
  }),
  split: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="M128 40v176m-32-88H16m32 32-32-32 32-32m112 32h80m-32-32 32 32-32 32"
        />
        <rect className="fill-duotone-secondary" x={16} y={40} width={224} height={176} rx={16} />
      </svg>
    );
  }),
  stop: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={48} y={48} width={160} height={160} rx={8} />
        <rect className="stroke-duotone-primary" x={48} y={48} width={160} height={160} rx={8} fill="none" />
      </svg>
    );
  }),
  tag: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <path
          className="fill-duotone-secondary"
          d="M42.34,138.34A8,8,0,0,1,40,132.69V40h92.69a8,8,0,0,1,5.65,2.34l99.32,99.32a8,8,0,0,1,0,11.31L153,237.66a8,8,0,0,1-11.31,0Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M42.34,138.34A8,8,0,0,1,40,132.69V40h92.69a8,8,0,0,1,5.65,2.34l99.32,99.32a8,8,0,0,1,0,11.31L153,237.66a8,8,0,0,1-11.31,0Z"
          fill="none"
        />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={84} cy={84} r={8} />
      </svg>
    );
  }),
  'thumb-down': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M32 48h48v104H32a8 8 0 0 1-8-8V56a8 8 0 0 1 8-8Z" />
        <path
          className="stroke-duotone-primary"
          d="M32 48h48v104H32a8 8 0 0 1-8-8V56a8 8 0 0 1 8-8Zm48 104 40 80a32 32 0 0 0 32-32v-24h64a16 16 0 0 0 15.87-18l-12-96A16 16 0 0 0 204 48H80"
          fill="none"
        />
      </svg>
    );
  }),
  'thumb-up': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M32 104h48v104H32a8 8 0 0 1-8-8v-88a8 8 0 0 1 8-8Z" />
        <path
          className="stroke-duotone-primary"
          d="M32 104h48v104H32a8 8 0 0 1-8-8v-88a8 8 0 0 1 8-8Zm48 0 40-80a32 32 0 0 1 32 32v24h64a16 16 0 0 1 15.87 18l-12 96A16 16 0 0 1 204 208H80"
          fill="none"
        />
      </svg>
    );
  }),
  thumbnail: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M216 48H40a8 8 0 0 0-8 8v144a8 8 0 0 0 8 8h20.1a72 72 0 0 1 67.9-48 40 40 0 1 1 40-40 40 40 0 0 1-40 40 72 72 0 0 1 67.9 48H216a8 8 0 0 0 8-8V56a8 8 0 0 0-8-8Z"
        />
        <circle className="stroke-duotone-primary" cx={128} cy={120} r={40} fill="none" />
        <rect className="stroke-duotone-primary" x={32} y={48} width={192} height={160} rx={8} fill="none" />
        <path className="stroke-duotone-primary" d="M60.1 208a72 72 0 0 1 135.8 0" fill="none" />
      </svg>
    );
  }),
  tick: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Z"
          opacity={0.2}
        />
        <path className="stroke-duotone-primary" fill="none" d="m64 136 40 40 96-96" />
      </svg>
    );
  }),
  transcription: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <rect className="fill-duotone-secondary" x={24} y={56} width={208} height={144} rx={8} />
        <rect
          className="stroke-duotone-primary"
          x={24}
          y={56}
          width={208}
          height={144}
          rx={8}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={16}
        />
        <path
          className="stroke-duotone-primary"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={16}
          d="M56 136h16M104 136h96M56 168h96M200 168h-16"
        />
      </svg>
    );
  }),
  translation: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M144 184h80l-40-80-40 80zM96 127.56A95.78 95.78 0 0 1 64 56h64a95.78 95.78 0 0 1-32 71.56Z"
        />
        <path
          className="stroke-duotone-primary"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={16}
          d="m239.97 216-56-112-56 112M143.97 184h80M95.97 32v24M31.97 56h128M128 56a96 96 0 0 1-96 96"
        />
        <path
          className="stroke-duotone-primary"
          d="M69.44 88A96 96 0 0 0 160 152"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={16}
        />
      </svg>
    );
  }),
  trim: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <circle className="fill-duotone-secondary" cx={60} cy={76} r={28} />
        <circle className="fill-duotone-secondary" cx={60} cy={180} r={28} />
        <circle className="stroke-duotone-primary" cx={60} cy={76} r={28} fill="none" />
        <circle className="stroke-duotone-primary" cx={60} cy={180} r={28} fill="none" />
        <path
          className="stroke-duotone-primary"
          fill="none"
          d="m136 128-52.89 36.19M232 62.32l-67.67 46.29M232 193.68 83.11 91.81"
        />
      </svg>
    );
  }),
  twitter: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <path
          className="fill-duotone-secondary"
          d="M88,176S32.85,144,40.78,56c0,0,39.66,40,87.22,48V88c0-22,18-40.27,40-40a40.74,40.74,0,0,1,36.67,24H240l-32,32c-4.26,66.84-60.08,120-128,120-32,0-40-12-40-12S72,200,88,176Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M88,176S32.85,144,40.78,56c0,0,39.66,40,87.22,48V88c0-22,18-40.27,40-40a40.74,40.74,0,0,1,36.67,24H240l-32,32c-4.26,66.84-60.08,120-128,120-32,0-40-12-40-12S72,200,88,176Z"
          fill="none"
        />
      </svg>
    );
  }),
  upload: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M56 32h144a16 16 0 0 1 16 16v160H40V48a16 16 0 0 1 16-16Z" />
        <path className="stroke-duotone-primary" fill="none" d="M128 144V32m88 112v64H40v-64m48-72 40-40 40 40" />
      </svg>
    );
  }),
  'user-guides': forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path className="fill-duotone-secondary" d="M128 232a32 32 0 0 1 32-32h72V56h-72a32 32 0 0 0-32 32Z" />
        <path
          className="stroke-duotone-primary"
          d="M128 88a32 32 0 0 1 32-32h72v144h-72a32 32 0 0 0-32 32M24 200h72a32 32 0 0 1 32 32V88a32 32 0 0 0-32-32H24ZM160 96h40m-40 32h40m-40 32h40"
          fill="none"
        />
      </svg>
    );
  }),
  viewed: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <path fill="none" d="M0 0h256v256H0z" />
        <path
          className="fill-duotone-secondary"
          d="M128 56c-80 0-112 72-112 72s32 72 112 72 112-72 112-72-32-72-112-72Zm0 112a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M128 56c-80 0-112 72-112 72s32 72 112 72 112-72 112-72-32-72-112-72Z"
          fill="none"
        />
        <circle className="stroke-duotone-primary" cx={128} cy={128} r={40} fill="none" />
      </svg>
    );
  }),
  warning: forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(function Icon(props, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="1em" height="1em" ref={ref} {...props}>
        <rect width={256} height={256} fill="none" />
        <path
          className="fill-duotone-secondary"
          d="M142.41,40.22l87.46,151.87C236,202.79,228.08,216,215.46,216H40.54C27.92,216,20,202.79,26.13,192.09L113.59,40.22C119.89,29.26,136.11,29.26,142.41,40.22Z"
        />
        <path
          className="stroke-duotone-primary"
          d="M142.41,40.22l87.46,151.87C236,202.79,228.08,216,215.46,216H40.54C27.92,216,20,202.79,26.13,192.09L113.59,40.22C119.89,29.26,136.11,29.26,142.41,40.22Z"
          fill="none"
        />
        <line className="stroke-duotone-primary" x1={128} y1={144} x2={128} y2={104} fill="none" />
        <circle className="fill-duotone-primary stroke-duotone-primary" cx={128} cy={180} r={6} />
      </svg>
    );
  })
} as const;

export { icons };
