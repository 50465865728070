import { lazy } from 'react';

import { useModal } from '~/components/modal';

const CreateWorkspaceModal = lazy(() => import('~/features/workspaces/modals/CreateWorkspaceModal'));

function useCreateWorkspace() {
  const { open } = useModal(CreateWorkspaceModal);
  return open;
}

export { useCreateWorkspace };
