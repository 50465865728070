import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import { useAuth } from '~/utils/auth';

function useSentry() {
  const auth = useAuth();

  useEffect(() => {
    if (auth?.account?.id) {
      if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        Sentry?.setUser?.({
          id: auth?.account?.id,
          name: auth?.account?.name,
          email: auth?.account?.email,
          entityId: auth?.entity?.id,
          entityName: auth?.entity?.name
        });
      }
    }
  }, [auth]);
}

export { useSentry };
