import { forwardRef } from 'react';

import type { ComponentProps } from 'react';

import { TextInput } from './TextInput';

type UrlInputProps = Omit<ComponentProps<typeof TextInput>, 'type'>;

const UrlInput = forwardRef<HTMLInputElement, UrlInputProps>(function UrlInput(props, ref) {
  return <TextInput {...props} ref={ref} type="url" />;
});

export { UrlInput };
