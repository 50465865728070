import { Fragment } from 'react';

import { Text } from '~/components/text';
import { TruncateWithTooltip } from '~/features/global/components/TruncateWithTooltip';
import { styled, truncate } from '~/utils/styling';

import type { ReactNode, ComponentProps } from 'react';

const WrapSticker = styled('div', {
  marginRight: '$tiny',
  display: 'inline-flex',

  '& > *:not(:first-child)': {
    margin: '0 0 0 $tiny'
  },

  '&& > *': {
    display: 'inline-flex'
  }
});

const WrapItems = styled('div', {
  fontSize: '$small',

  '& a': {
    color: 'inherit',
    fontWeight: 'bold',
    textDecoration: 'none',

    '&:hover, &:focus': {
      color: 'inherit',
      textDecoration: 'underline'
    }
  }
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$tiny',
  width: '100%',

  variants: {
    truncate: {
      true: {
        [`& ${WrapItems}`]: {
          ...truncate({ lines: 3 })
        }
      }
    }
  }
});

const Separator = styled(Text, {
  display: 'inline-flex',
  padding: '0 $wee'
});

type Item = {
  key?: string | number;
  label: ReactNode;
};

type MetaProps = ComponentProps<typeof Container> & {
  items?: Item[];
  stickers?: ReactNode;
  size?: ComponentProps<typeof Text>['size'];
};

function Meta({ stickers, items, size = 'medium', ...props }: MetaProps) {
  return (
    <Container {...props}>
      <TruncateWithTooltip
        tooltip={items?.map?.((item, i) => (
          <>
            {i > 0 && <> · </>}
            {item.label}
          </>
        ))}
      >
        {(tooltipProps) => (
          <WrapItems {...tooltipProps}>
            {stickers && <WrapSticker>{stickers}</WrapSticker>}
            {items?.map?.((item, index) => (
              <Fragment key={item.key || index}>
                {index > 0 && (
                  <Separator color="grey" size={size}>
                    ·
                  </Separator>
                )}
                <Text color="grey" size={size}>
                  {item.label}
                </Text>
              </Fragment>
            ))}
          </WrapItems>
        )}
      </TruncateWithTooltip>
    </Container>
  );
}

export { Meta };
