import { Card } from '~/components/card';
import { styled } from '~/utils/styling';

import type { ComponentProps, ReactNode } from 'react';

const Inner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$$cardPadding',
  width: '100%'
});

const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$small',
  position: 'relative',
  width: '100%'
});

const Content = styled('div', {
  // We want the scroll container to extend to the edge of the list item container so
  // we remove the card padding with negative margin and add our own. This is made more
  // maintainable by using a padding variable that is controlled by the card component
  padding: '$$cardPadding',
  margin: '-$$cardPadding',
  width: 'calc(100% + $$cardPadding * 2)',
  overflow: 'auto',

  '& > *': {
    width: 'min-content'
  }
});

type ListItemCardProps = ComponentProps<typeof Card> & {
  header: ReactNode;
};

function ListItemCard({ href, onClick, header, children, ...rest }: ListItemCardProps) {
  return (
    <Card href={href} onClick={onClick} role="listitem" {...rest}>
      <Inner>
        <Header>{header}</Header>
        {children && <Content>{children}</Content>}
      </Inner>
    </Card>
  );
}

export { ListItemCard };
