import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import { useAuth } from '~/utils/auth';

const HUBSPOT_APP_ID = process.env.NEXT_PUBLIC_HUBSPOT_APP_ID as string;

function useHubspotChat() {
  const auth = useAuth();
  const router = useRouter();

  useEffect(
    () => {
      // https://developers.hubspot.com/docs/api/events/tracking-code
      window._hsq?.push([
        'identify',
        auth?.account?.id
          ? {
              id: auth?.account?.id,
              name: auth?.account?.name,
              email: auth?.account?.email,
              entityId: auth?.entity?.id,
              entityName: auth?.entity?.name
            }
          : null
      ]);
      window.HubSpotConversations?.widget?.clear?.({ refreshWidget: true });
    },
    // eslint-disable-next-line
    [auth?.account?.id]
  );

  useEffect(
    () => {
      window._hsq?.push(['setPath', router.pathname]);
      window._hsq?.push(['trackPageView']);
      window.HubSpotConversations?.widget?.refresh?.();
    },
    // Refresh hubspot widget on route change
    // https://legacydocs.hubspot.com/docs/methods/conversations_api/widget_refresh
    // https://developers.hubspot.com/docs/api/events/tracking-code
    // eslint-disable-next-line
    [router.pathname]
  );

  return useMemo(
    () => ({
      isEnabled: !!HUBSPOT_APP_ID,
      open: async () => {
        window.HubSpotConversations?.widget?.load?.();

        // HACK: Hubspot does not return a promise from the `load` method, so we create our own fake
        // promise here to show a loading state while the widget is loading (cause it can take ~100-300ms)
        let i = 0;
        while (i++ < 30 && !window.HubSpotConversations?.widget?.status().loaded) {
          await new Promise((resolve) => setTimeout(resolve, 100));
        }

        window.HubSpotConversations?.widget?.open?.();
      }
    }),
    []
  );
}

export { useHubspotChat };
