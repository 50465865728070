function truncate({ lines }: { lines: number }) {
  return {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': lines,
    '-webkit-box-orient': 'vertical'
  };
}

export { truncate };
