import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import awsExports from '~/config/aws-exports';

const publicApi = awsExports?.aws_cloud_logic_custom?.find?.((apis) => apis?.name === 'vouchpublicapi');
const baseUrl = publicApi?.endpoint ? `${publicApi?.endpoint}/api` : undefined;

let visitorIdCache: string | undefined;

async function createVisitor(visitorId: string) {
  const { Auth } = await import('aws-amplify');
  const auth = await Auth.currentAuthenticatedUser().catch(() => {
    // User is not authenticated
    return;
  });

  navigator.sendBeacon(`${baseUrl}/visitor`, JSON.stringify({ visitorId, userId: auth?.username }));
}

function setVisitor(id: string) {
  const cookies = new Cookies();
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);

  visitorIdCache = id;
  cookies.set('vouch-uid-visitor', id, { path: '/', expires, domain: '.vouchfor.com' });
  window.localStorage?.setItem('vouch-uid-visitor', id);
}

async function updateVisitor(data: { [key: string]: any }) {
  const visitorId = getVisitor();
  navigator.sendBeacon(`${baseUrl}/updatevisitor`, JSON.stringify({ ...data, visitorId }));
}

async function authenticateVisitor() {
  const { Auth } = await import('aws-amplify');
  const auth = await Auth.currentAuthenticatedUser().catch(() => {
    // User is not authenticated
    return;
  });

  if (!auth?.username) {
    // Bail if the user is not authenticated
    return;
  }

  await updateVisitor({ userId: auth?.usernam });
}

function getVisitor() {
  if (typeof window === 'undefined') {
    return '';
  }

  if (visitorIdCache) {
    return visitorIdCache;
  }

  const cookies = new Cookies();

  /* 
  Falls back to localStorage as it was originally using localStorage and some visitors already exist as well as
  handling non vouchfor.com domains and expired cookies 
  */
  let visitorId = cookies.get('vouch-uid-visitor') || window.localStorage?.getItem('vouch-uid-visitor');
  if (!visitorId) {
    visitorId = uuidv4();
    createVisitor(visitorId);
  }

  setVisitor(visitorId);
  return visitorIdCache;
}

export { authenticateVisitor, getVisitor };
