import { useEffect, useState } from 'react';

import { Button } from '~/components/button';
import { Logo, Masthead } from '~/components/masthead';
import { Responsive } from '~/components/responsive';
import { useGlobalActions } from '~/features/global/actions';
import { Link } from '~/utils/routing/Link';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

const Container = styled(Masthead, {
  padding: '0 $medium',
  marginBottom: '$large',
  background: 'transparent',
  borderBottom: '$borderWidths$thin solid transparent',
  transition: 'box-shadow .2s',

  '@bp-medium': {
    padding: '0 $large'
  },

  variants: {
    scrolled: {
      true: {
        borderBottomColor: '$dark-80',
        background: '$grey-100'
      }
    }
  }
});

const ButtonContainer = styled('div', {
  display: 'flex',
  columnGap: '$wee',

  '@bp-medium': {
    columnGap: '$tiny'
  }
});

const StyledLink = styled(Link, {
  display: 'flex'
});

function AuthMasthead({ action }: { action?: 'sign-in' | 'sign-out' }) {
  const router = useRouter();
  const globalActions = useGlobalActions();

  const isOauth = router.pathname.startsWith('/oauth/');

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    handleScroll();
    window.document.addEventListener('scroll', handleScroll);
    return () => window.document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Container scrolled={scrolled}>
      <StyledLink href="/sign-in" aria-label="Go to sign in">
        <Logo />
      </StyledLink>

      <ButtonContainer>
        <Button color="transparent" onClick={() => globalActions.contactUs({ type: 'auth-masthead' })}>
          <Responsive as="span" desktop="Contact us" mobile="Contact" />
        </Button>

        {action === 'sign-in' && (
          <Button color="brand" href={isOauth ? '/oauth/sign-in' : '/sign-in'}>
            Sign in
          </Button>
        )}

        {action === 'sign-out' && (
          <Button color="brand" href="/sign-out">
            Logout
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
}

export { AuthMasthead };
