import { useCallback } from 'react';

import { useMutation } from '~/utils/graphql';

import { CREATE_CAMPAIGN_MUTATION } from '~/queries/create-campaign-mutation';
import { CREATE_QUESTION_MUTATION } from '~/queries/create-question-mutation';

import type { QuestionTemplate } from '~/features/campaigns/data/question-templates';

function useCreateFromTemplate() {
  const [createCampaign] = useMutation(CREATE_CAMPAIGN_MUTATION);
  const [addQuestion] = useMutation(CREATE_QUESTION_MUTATION);

  return useCallback(
    async (template?: QuestionTemplate) => {
      let campaign;

      if (!template) {
        const { data } = await createCampaign({
          variables: {
            input: {
              name: '',
              private: true
            }
          }
        });
        campaign = data?.createCampaign;
      } else {
        const { type, title, questions, id } = template;
        const { data } = await createCampaign({
          variables: {
            input: {
              name: title,
              private: true,
              template: {
                type,
                title
              },
              templateId: id
            }
          }
        });
        await Promise.all(
          questions?.map((question, index) => {
            return addQuestion({
              variables: {
                input: {
                  ...question,
                  referenceId: data?.createCampaign?.id as string,
                  ordinality: index + 1,
                  required: true,
                  settings: {
                    ...question?.settings,
                    type: 'VIDEO',
                    optional: false,
                    orientation: 'DEFAULT'
                  }
                }
              }
            });
          }) ?? []
        );

        campaign = data?.createCampaign;
      }

      return campaign;
    },
    [addQuestion, createCampaign]
  );
}

export { useCreateFromTemplate };
