import * as Sentry from '@sentry/nextjs';

import type { ErrorStateProps } from './ErrorState';
import type { ComponentProps } from 'react';

import { ErrorState } from './ErrorState';

const errorFallback = (props: ErrorStateProps) => <ErrorState {...props} />;

type ErrorBoundaryProps = ComponentProps<typeof Sentry.ErrorBoundary>;

function ErrorBoundary({ fallback = errorFallback, beforeCapture, ...props }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={fallback}
      beforeCapture={(scope, error, componentStack) => {
        if (error) {
          try {
            scope.setExtra('capture', { source: 'ErrorBoundary' });
            error.message = `ErrorBoundary: ${error.message}`;
          } catch {
            // sometimes the error we get is an object with only getters, so this will throw an
            // error when we try to write to it :|
          }
        }
        beforeCapture?.(scope, error, componentStack);
      }}
      {...props}
    />
  );
}

export { ErrorBoundary };
