import { gql } from '~/utils/codegen';

export const GET_ENTITY_BILLING_QUERY = gql(/* GraphQL */ `
  query GetEntityBilling($id: ID!) {
    entity(id: $id) {
      id
      billing {
        limit {
          users
          spaces
          campaigns
        }
        usage {
          users
          spaces
          campaigns
        }
      }
    }
  }
`);
