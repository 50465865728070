import { gql } from '~/utils/codegen';

export const GET_CAMPAIGN_QUERY = gql(/* GraphQL */ `
  query GetCampaign($id: ID!) {
    campaign(id: $id) {
      id
      hashId
      name
      status
      message
      private
      createdAt
      updatedAt
      viewCount
      startCount
      responseCount
      answerCount
      questions {
        items {
          id
          ordinality
          required
          title
          referenceId
          system
          settings {
            type
            orientation
            optional
            maxDuration
            maxTakes
            note
          }
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
          }
        }
      }
      events {
        items {
          createdAt
          type
          message
          account {
            id
            name
          }
        }
      }
      account {
        id
        name
      }
      settings {
        urls {
          request
        }
      }
    }
  }
`);
