import { Sticker } from '~/components/sticker';
import { usePermissions } from '~/utils/permissions';

import type { ComponentProps } from 'react';

const map = {
  starter: { label: 'Starter', $$stickerBackground: '$colors$s-brand-100', $$stickerColor: '$colors$s-brand-500' },
  teams: { label: 'Teams', $$stickerBackground: '$colors$dark-1000', $$stickerColor: '$colors$light-1000' },
  beta: { label: 'Beta', $$stickerBackground: '$colors$teal-100', $$stickerColor: '$colors$teal-600' }
};

type PlanStickerProps = Omit<ComponentProps<typeof Sticker>, 'children'> & {
  type: keyof typeof map;
};

function PlanSticker({ css = {}, type, ...props }: PlanStickerProps) {
  const permissions = usePermissions();
  if (type !== 'beta' && permissions.isTeams) {
    // For teams customers we want to hide the plan stickers
    return null;
  }

  const { label, ...rest } = map[type];
  return (
    <Sticker css={{ ...rest, ...css, textTransform: 'none' }} {...props}>
      {label}
    </Sticker>
  );
}

export { PlanSticker };
