import { useCallback } from 'react';
import { useEffect, useRef } from 'react';

import { styled } from '~/utils/styling';

import type { MenuItemObj } from './MenuItem';
import type { Ref, Dispatch, SetStateAction, ComponentType, ReactNode, ComponentProps, MouseEvent } from 'react';

import { MenuItem } from './MenuItem';

const Ul = styled('ul', {
  $$menuPadding: '$space$wee',

  margin: '0',
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '$lineHeights$medium',
  width: '100%',
  wordWrap: 'break-word',
  gap: '.0625rem',
  overflow: 'auto',
  padding: '$$menuPadding',

  variants: {
    height: {
      auto: {},
      normal: {
        maxHeight: '45vh'
      },
      full: {
        maxHeight: '80vh'
      }
    }
  },

  defaultVariants: {
    height: 'normal'
  }
});

const Li = styled('li', {
  margin: 0,
  padding: 0,
  listStyle: 'none'
});

type CustomItemObj = {
  key?: string;
  isCustom: boolean;
  visible?: boolean;
  Content: ComponentType<{ visible?: boolean; setVisible?: Dispatch<SetStateAction<boolean>>; item?: Item }>;
};

type Item = MenuItemObj | CustomItemObj;

type PopoutMenuProps = Omit<ComponentProps<typeof Ul>, 'title'> & {
  items: Item[];
  setVisible?: Dispatch<SetStateAction<boolean>>;
  element?: HTMLElement;
  analyticsId?: string;
  disableKeyboardInteraction?: boolean;
};

function Menu({ items, setVisible, element, analyticsId, disableKeyboardInteraction, ...props }: PopoutMenuProps) {
  const ulRef = useRef<HTMLUListElement>();
  const prevFocus = useRef<HTMLElement | null | undefined>();

  useEffect(() => {
    if (disableKeyboardInteraction) {
      return;
    }

    const el = ulRef.current;
    if (!el) {
      return;
    }

    // Allow user to navigate through the menu items using the arrow keys
    function handleKeyDown(e: any) {
      const focusable = el?.querySelectorAll('[data-focusable="true"]');
      if (!focusable?.length) {
        return;
      }

      const focusIndex =
        focusable && window.document.activeElement
          ? Array.from(focusable).indexOf?.(window.document.activeElement)
          : -1;

      // When the user enters focus of the menu items e.g. via arrow keys, we want to remember what the original
      // focused element outside of the menu was, so we can restore that focus when the user uses `Tab` to get
      // out of the menu (otherwise by default the focus will go to the first focusable element on the page)
      if (focusIndex === -1) {
        prevFocus.current = window.document.activeElement as HTMLElement;
      }

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (focusIndex === -1 || focusIndex >= focusable?.length - 1) {
            (focusable[0] as HTMLElement).focus();
          } else {
            (focusable[focusIndex + 1] as HTMLElement).focus();
          }
          break;

        case 'ArrowUp':
          e.preventDefault();
          if (focusIndex <= 0) {
            (focusable[focusable.length - 1] as HTMLElement).focus();
          } else {
            (focusable[focusIndex - 1] as HTMLElement).focus();
          }
          break;

        case 'Tab':
          if (focusIndex !== -1 && prevFocus.current) {
            e.preventDefault();
            prevFocus.current.focus();
          }
          break;

        case 'Escape':
          if (focusIndex !== -1) {
            e.preventDefault();
            if (prevFocus.current) {
              prevFocus.current.focus();
            }
            setVisible?.(false);
          }
          break;
      }
    }

    window.document.addEventListener('keydown', handleKeyDown);
    return () => window.document.removeEventListener('keydown', handleKeyDown);
  }, [disableKeyboardInteraction, setVisible]);

  const renderItems = useCallback(
    (items: Item[]): ReactNode => {
      return items.map((item, index) => {
        if (item.visible === false) {
          return null;
        }

        if ('isCustom' in item) {
          return (
            <Li key={item.key || index}>
              <item.Content item={item} setVisible={setVisible} />
            </Li>
          );
        }

        return (
          <Li key={item.key || index}>
            <MenuItem
              item={item}
              setVisible={setVisible}
              element={element}
              analyticsId={analyticsId}
              disableKeyboardInteraction={disableKeyboardInteraction}
            />
          </Li>
        );
      });
    },
    [element, setVisible, analyticsId, disableKeyboardInteraction]
  );

  return (
    <Ul
      ref={ulRef as Ref<HTMLUListElement>}
      tabIndex={disableKeyboardInteraction ? undefined : -1}
      {...props}
      onClick={(e: MouseEvent) => {
        // We stop propagation in the menu itself, to avoid triggering any outside clicks e.g. in the
        // `ActionMenu`, which is not ideal but works for now, it just means you cannot listen to the
        // click event bubbling up
        // Doing this on the menu means that we can pass through clicks from disabled menu items and
        // still have the propagation stopped (otherwise we end up clicking on the item underneath)
        e.stopPropagation();
      }}
    >
      {renderItems(items)}
    </Ul>
  );
}

export { Menu };
export type { Item };
