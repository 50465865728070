import { heading, styled } from '~/utils/styling';

import type { ComponentProps, ReactNode } from 'react';

const Container = styled('fieldset', {
  display: 'grid',
  gap: '$small',
  gridTemplateColumns: '1fr',
  padding: 0,
  margin: 0,
  border: '0 none',

  '@bp-medium': {
    gap: '$medium'
  },

  variants: {
    columns: {
      1: {
        gridTemplateColumns: '1fr'
      },
      2: {
        gridTemplateColumns: '1fr',

        '@bp-medium': {
          gridTemplateColumns: '1fr 1fr'
        }
      }
    }
  },

  defaultVariants: {
    columns: 1
  }
});

const Legend = styled('legend', {
  ...heading({ size: 'small' }),
  margin: '$medium 0 $small',
  padding: 0
});

type FieldsetProps = ComponentProps<typeof Container> & {
  title?: ReactNode;
};

function Fieldset({ title, children, ...props }: FieldsetProps) {
  return (
    <Container {...props}>
      {title && <Legend>{title}</Legend>}
      {children}
    </Container>
  );
}

export { Fieldset };
