import { lazy } from 'react';

import { useModal } from '~/components/modal';

const DuplicateCampaignModal = lazy(() => import('~/features/campaigns/modals/DuplicateCampaignModal'));

function useDuplicateCampaign() {
  const { open } = useModal(DuplicateCampaignModal);
  return open;
}

export { useDuplicateCampaign };
