import { lazy } from 'react';

import { useModal } from '~/components/modal';
import { usePlanUsage } from '~/features/billing/utils/usePlanUsage';

const CampaignSetLiveModal = lazy(() => import('~/features/campaigns/modals/CampaignSetLiveModal'));
const CampaignLimitModal = lazy(() => import('~/features/campaigns/modals/CampaignLimitModal'));

function useSetLive() {
  const usage = usePlanUsage();
  const isAtLimit = !!usage?.limit?.campaigns && usage.usage.campaigns >= usage.limit.campaigns;

  const { open } = useModal(isAtLimit ? CampaignLimitModal : CampaignSetLiveModal);
  return open;
}

export { useSetLive };
