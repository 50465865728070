import { configure } from '~/utils/amplify';
import { clearAuthCookies } from '~/utils/auth/clearAuthCookies';

import type { ApolloClient } from '@apollo/client';

type SignOutArg = {
  client: ApolloClient<any>;
};

async function signOut({ client }: SignOutArg) {
  // Configure Amplify, to make sure we have the correct auth config set
  await configure();

  // Log out of Amplify Cognito
  const { Auth } = await import('aws-amplify');
  await Auth.signOut();

  clearAuthCookies();

  // Clear apollo store
  await client?.clearStore();
}

export { signOut };
