import { forwardRef } from 'react';

import { Icon } from '~/components/icon';
import { styled } from '~/utils/styling';

import type { ComponentProps, ForwardedRef } from 'react';

import { QuestionCard } from './QuestionCard';

const Container = styled(QuestionCard, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$tiny',

  // Variant styles have higher specificity so increasing
  // specificity here allows us to override them
  '&&': {
    color: '$dark-400',
    textAlign: 'center'
  }
});

const StyledIcon = styled(Icon, {
  height: '1.5rem',
  width: '1.5rem'
});

const Label = styled('span', {
  fontWeight: '$bold',

  variants: {
    size: {
      small: {
        fontSize: '$small'
      },
      large: {
        fontSize: '$medium'
      }
    }
  },

  defaultVariants: {
    size: 'small'
  }
});

type AddCardProps = ComponentProps<typeof Container> & {
  title?: string;
  icon?: ComponentProps<typeof Icon>['name'] | false;
};

const AddCard = forwardRef(function AddCard(
  { title, icon = 'add', size, ...props }: AddCardProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <Container color="grey" size={size} {...props} ref={ref}>
      {icon !== false && <StyledIcon name={icon} />}
      {title && <Label size={size}>{title}</Label>}
    </Container>
  );
});

export { AddCard };
