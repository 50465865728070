import { useEffect, useRef } from 'react';

import { Spacer } from '~/components/box';
import { useErrorMessage } from '~/components/error-boundary/useErrorMessage';
import { Heading, P } from '~/components/text';
import { styled } from '~/utils/styling';

import type { FallbackRender } from '@sentry/react/types/errorboundary';
import type { ComponentProps } from 'react';

const Container = styled('div', {
  width: '100%',
  maxWidth: '34rem',
  padding: '6rem $medium',
  margin: '0 auto',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

type ErrorStateProps = ComponentProps<typeof Container> &
  Partial<Omit<Parameters<FallbackRender>[0], 'error'>> & {
    error: Error & { code?: number };
    pathname?: string;
  };

function ErrorState({ error, componentStack, resetError, pathname, ...props }: ErrorStateProps) {
  if (__DEV__ && typeof window !== 'undefined') {
    console.error('Caught error', { error, componentStack, pathname });
  }

  // Self recover if the user tries to navigate
  const originalPathname = useRef(pathname);
  useEffect(() => {
    if (originalPathname.current !== pathname) {
      resetError?.();
    }
  }, [pathname, resetError]);

  const { title, description } = useErrorMessage(error);

  return (
    <Container {...props}>
      <Heading level={1}>{title}</Heading>
      <Spacer h="tiny" />

      <P color="grey">{description}</P>
    </Container>
  );
}

export { ErrorState };
export type { ErrorStateProps };
