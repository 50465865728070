import Cookies from 'universal-cookie';

function clearAuthCookies() {
  // NOTE: Amplify is not very helpful when you change the auth config, potentially leaving
  // cookies behind after `signOut` but then still using them to authenticate successfully,
  // so we delete all cookies that are Cognito related manually here just to be sure
  const prefix = 'CognitoIdentityServiceProvider.';

  const cookies = new Cookies();
  const all = cookies.getAll();
  Object.keys(all).forEach((key) => {
    if (key.startsWith(prefix)) {
      cookies.remove(key, { sameSite: 'strict' });
      cookies.remove(key, { sameSite: 'lax' });
    }
  });

  // Same for local storage
  if (typeof window !== 'undefined') {
    for (let i = 0; i < window.localStorage?.length || 0; i++) {
      const key = window.localStorage?.key?.(i);
      if (key?.startsWith?.(prefix)) {
        window.localStorage?.removeItem?.(key);
      }
    }
  }
}

export { clearAuthCookies };
