import * as ProgressPrimitive from '@radix-ui/react-progress';

import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';

const StyledProgress = styled(ProgressPrimitive.Root, {
  position: 'relative',
  overflow: 'hidden',
  height: '.4rem',
  borderRadius: '.2rem',
  width: '100%',
  background: '$$progressBackground',

  // Fix overflow clipping in Safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: 'translateZ(0)',

  variants: {
    color: {
      red: {
        $$progressBackground: '$colors$s-brand-100',
        $$progressIndicator: '$colors$s-brand-800'
      },
      purple: {
        $$progressBackground: '$colors$purple-100',
        $$progressIndicator: '$colors$purple-700'
      },
      teal: {
        $$progressBackground: '$colors$teal-100',
        $$progressIndicator: '$colors$teal-600'
      },
      grey: {
        $$progressBackground: '$colors$dark-80',
        $$progressIndicator: '$colors$dark-80'
      },
      dark: {
        $$progressBackground: '$colors$dark-80',
        $$progressIndicator: '$colors$dark-800'
      }
    }
  }
});

const StyledIndicator = styled(ProgressPrimitive.Indicator, {
  width: '100%',
  height: '100%',
  transition: 'transform 330ms cubic-bezier(0.65, 0, 0.35, 1)',
  background: '$$progressIndicator'
});

type ProgressProps = Partial<ComponentProps<typeof StyledProgress>>;

function Progress({ value, max = 100, color = 'red', ...rest }: ProgressProps) {
  return (
    <StyledProgress value={value} max={max} color={color} {...rest}>
      <StyledIndicator style={{ transform: `translateX(-${100 - ((value ?? 0) / max) * 100}%)` }} />
    </StyledProgress>
  );
}

export { Progress };
