import { forwardRef } from 'react';

import { Avatar } from '~/components/avatar';
import { Text } from '~/components/text';
import { styled } from '~/utils/styling';

import type { ComponentProps, ForwardedRef } from 'react';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
  border: '0 none',
  background: 'transparent',
  gap: '$tiny',
  overflow: 'hidden',
  textAlign: 'left',

  variants: {
    interactive: {
      true: {
        cursor: 'pointer',

        '&:hover, &:focus': {
          outlineOffset: '.2rem',

          [`${Avatar}`]: {
            filter: 'brightness(.95)'
          }
        }
      }
    }
  }
});

const Title = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '$tiny',
  overflow: 'hidden'
});

type ProfileProps = ComponentProps<typeof Container> & {
  entity: any;
  loading?: boolean;
  compact?: boolean;
};

const WorkspaceProfile = forwardRef(function Profile(
  { entity, loading, compact, ...props }: ProfileProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <Container ref={ref as any} as={props.onClick ? 'button' : 'div'} interactive={!!props.onClick} {...props}>
      <Title>
        <Avatar
          size="medium"
          name={entity?.name}
          imageSrc={entity?.logoSrc}
          color="purple"
          loading={loading}
          css={{ flexShrink: 0 }}
        />
        <Text truncate weight={!entity?.parentId ? 'bold' : undefined}>
          {entity?.name}
        </Text>
      </Title>
    </Container>
  );
});

export { WorkspaceProfile };
