import { useApolloClient } from '@apollo/client';
import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useErrorNotification, useNotification } from '~/components/notification';
import { refetchOrEvict, useMutation } from '~/utils/graphql';
import { useRouter } from '~/utils/routing/useRouter';

import { APPEND_PLAYLIST_MUTATION } from '~/queries/append-playlist-mutation';
import { SEARCH_ENTITY_QUERY } from '~/queries/search-entity-query';

const UploadVideoModal = lazy(() => import('~/features/global/modals/UploadVideoModal'));

function useUploadVideo() {
  const router = useRouter();
  const client = useApolloClient();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const uploadVideoModal = useModal(UploadVideoModal);

  const [appendPlaylist] = useMutation(APPEND_PLAYLIST_MUTATION);

  return useCallback(
    ({ id, redirect = true }: { id: string; redirect?: boolean }) => {
      uploadVideoModal.open({
        redirect: false,
        onSubmit: async ({ vouch }) => {
          try {
            await appendPlaylist({ variables: { id, items: [{ id: vouch.questions?.items?.[0]?.answer?.id }] } });
            await refetchOrEvict({ client, include: [SEARCH_ENTITY_QUERY] });
            if (redirect) {
              await router.push(`/dashboard/playlists/${id}`);
            }
            notification.show({ message: 'Video successfully added to playlist.' });
          } catch (e) {
            errorNotification.show(e);
          }
        }
      });
    },
    [uploadVideoModal, appendPlaylist, client, notification, router, errorNotification]
  );
}

export { useUploadVideo };
