import { gql } from '~/utils/codegen';

export const CREATE_VOUCH_MUTATION = gql(/* GraphQL */ `
  mutation CreateVouch($input: CreateVouchInput!) {
    createVouch(input: $input) {
      id
      type
      name
      hashId
      status
      message
      updatedAt
      createdAt
      contact {
        id
        name
        email
        client {
          id
          name
        }
      }
      questions {
        items {
          id
          ordinality
          answer {
            id
          }
        }
      }
    }
  }
`);
