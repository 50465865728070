import { styled } from '~/utils/styling';

function createSpacingVariant<T extends string>(styleName: T) {
  return {
    wee: { [styleName]: '$space$wee' },
    tiny: { [styleName]: '$space$tiny' },
    small: { [styleName]: '$space$small' },
    medium: { [styleName]: '$space$medium' },
    large: { [styleName]: '$space$large' },
    huge: { [styleName]: '$space$huge' }
  };
}

function createSizesVariant<T extends string>(styleName: T) {
  return {
    wee: { [styleName]: '$wee' },
    tiny: { [styleName]: '$tiny' },
    small: { [styleName]: '$small' },
    medium: { [styleName]: '$medium' },
    large: { [styleName]: '$large' },
    full: { [styleName]: '100%' }
  };
}

const Box = styled('div', {
  width: '100%',

  variants: {
    m: createSpacingVariant('margin'),
    mt: createSpacingVariant('marginTop'),
    mb: createSpacingVariant('marginBottom'),
    ml: createSpacingVariant('marginLeft'),
    mr: createSpacingVariant('marginRight'),
    p: createSpacingVariant('padding'),
    pt: createSpacingVariant('paddingTop'),
    pb: createSpacingVariant('paddingBottom'),
    pl: createSpacingVariant('paddingLeft'),
    pr: createSpacingVariant('paddingRight'),
    w: createSizesVariant('width'),
    h: createSizesVariant('height')
  }
});

export { Box, createSpacingVariant };
