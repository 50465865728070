import type { ComponentProps } from 'react';

import { Header } from './Header';

type ListItemHeaderProps = Omit<ComponentProps<typeof Header>, 'headingLevel'>;

function ListItemHeader({ size = 'medium', ...props }: ListItemHeaderProps) {
  return <Header {...props} headingLevel={2} size={size} />;
}

export { ListItemHeader };
