const headingVariants = {
  micro: {
    fontWeight: '$fontWeights$bold',
    fontSize: '$fontSizes$small',
    lineHeight: '$lineHeights$small',
    letterSpacing: '$letterSpacings$medium'
  },
  wee: {
    fontWeight: '$fontWeights$bold',
    fontSize: '$fontSizes$medium',
    lineHeight: '$lineHeights$medium',
    letterSpacing: '$letterSpacings$small'
  },
  tiny: {
    fontWeight: '$fontWeights$bold',
    fontSize: '$fontSizes$large',
    lineHeight: '$lineHeights$large',
    letterSpacing: '$letterSpacings$xs'
  },
  small: {
    fontWeight: '$fontWeights$bold',
    fontSize: '$fontSizes$xl',
    lineHeight: '$lineHeights$xl',
    letterSpacing: '$letterSpacings$xxs'
  },
  medium: {
    fontWeight: '$fontWeights$bold',
    fontSize: '$fontSizes$xxl',
    lineHeight: '$lineHeights$xxl',
    letterSpacing: '$letterSpacings$xxs'
  },
  large: {
    fontWeight: '$fontWeights$bold',
    fontSize: '$fontSizes$xxxl',
    lineHeight: '$lineHeights$xxxl',
    letterSpacing: '$letterSpacings$xxxs'
  },
  huge: {
    fontWeight: '$fontWeights$bold',
    fontSize: '$fontSizes$4xl',
    lineHeight: '$lineHeights$4xl',
    letterSpacing: '$letterSpacings$xxxs'
  },
  ultra: {
    fontWeight: '$fontWeights$bold',
    fontSize: '$fontSizes$5xl',
    lineHeight: '$lineHeights$5xl',
    letterSpacing: '$letterSpacings$xxxs'
  }
};

type HeadingArg = {
  size: keyof typeof headingVariants;
};

function heading({ size }: HeadingArg) {
  return {
    ...headingVariants[size]
  };
}

export { heading, headingVariants };
