import { Sticker } from '~/components/sticker';

import type { ComponentProps } from 'react';

const map = {
  DRAFT: { label: 'Draft', background: '$dark-80', color: '$dark-800' },
  PAUSED: { label: 'Paused', background: '$dark-80', color: '$dark-800' },
  LIVE: { label: 'Live', background: '$teal-600', color: '$teal-100' },
  COMPLETE: { label: 'Completed', background: '$dark-80', color: '$dark-800' }
};

type CampaignStatusStickerProps = Omit<ComponentProps<typeof Sticker>, 'children'> & {
  status: keyof typeof map;
};

function CampaignStatusSticker({ css = {}, status, ...props }: CampaignStatusStickerProps) {
  const { label, background, color } = map[status];
  return (
    <Sticker css={{ '&, &:hover': { background, color }, ...css }} {...props}>
      {label}
    </Sticker>
  );
}

export { CampaignStatusSticker };
