import { lazy } from 'react';

import { useModal } from '~/components/modal';

const ShareCampaignModal = lazy(() => import('~/features/campaigns/modals/ShareCampaignModal'));

function useShare() {
  const { open } = useModal(ShareCampaignModal);
  return open;
}

export { useShare };
