import * as Sentry from '@sentry/nextjs';
import { useRouter as nextUseRouter } from 'next/router';
import { useMemo } from 'react';

function useRouter() {
  const router = nextUseRouter();
  // We want common routing actions to default to `shallow=true`, so we override the `push` and `replace`
  // methods of the nextjs hook
  return useMemo(
    () => ({
      ...router,
      push: async (...args: Parameters<typeof router.push>) => {
        try {
          // The router seems to throw (but not break anything) when triggered while already processing a previous
          // trigger, this is pretty annoying in dev and in Sentry, so we catch it here and just send it through as
          // a warning to still be able to monitor
          const result = await router.push(args[0], args[1], { shallow: true, ...args[2] });
          return result;
        } catch (e) {
          Sentry.captureException(e, { level: 'warning' });
        }
      },
      replace: async (...args: Parameters<typeof router.replace>) => {
        try {
          const result = await router.replace(args[0], args[1], { shallow: true, ...args[2] });
          return result;
        } catch (e) {
          Sentry.captureException(e, { level: 'warning' });
        }
      }
    }),
    [router]
  );
}

export { useRouter };
