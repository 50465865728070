import { GtmScript } from './gtm/GtmScript';
import { HubspotScript } from './hubspot/HubspotScript';

function AnalyticsScripts() {
  return (
    <>
      <GtmScript />
      <HubspotScript />
    </>
  );
}

export { AnalyticsScripts };
