import { useApolloClient } from '@apollo/client';
import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useAnalyticsEvent } from '~/utils/analytics';
import { refetchOrEvict, useMutation } from '~/utils/graphql';

import { GET_CAMPAIGN_COVER_SETTINGS_QUERY } from '~/queries/get-campaign-cover-settings-query';
import { GET_CAMPAIGN_SETTINGS_QUERY } from '~/queries/get-campaign-settings-query';
import { UPDATE_CAMPAIGN_MUTATION } from '~/queries/update-campaign-mutation';

const UpdateMessageModal = lazy(() => import('~/features/request/components/UpdateMessageModal'));

function useUpdateMessage() {
  const client = useApolloClient();
  const event = useAnalyticsEvent();
  const updateMessageModal = useModal(UpdateMessageModal);

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN_MUTATION);

  return useCallback(
    ({ id, message }: { id: string; message?: string | null }) => {
      updateMessageModal.open({
        message,
        onSubmit: async ({ message }) => {
          await updateCampaign({ variables: { input: { id, message } } });
          await refetchOrEvict({ client, include: [GET_CAMPAIGN_SETTINGS_QUERY, GET_CAMPAIGN_COVER_SETTINGS_QUERY] });
          event('message_added', { campaign_id: id, text: message });
        },
        messageVariables: [
          { key: 'firstName', label: 'First name' },
          { key: 'lastName', label: 'Last name' },
          { key: 'clientName', label: 'Client name' }
        ]
      });
    },
    [updateMessageModal, updateCampaign, client, event]
  );
}

export { useUpdateMessage };
