import { styled } from '~/utils/styling';

import { Card } from './Card';

const QuestionCard = styled(Card, {
  variants: {
    size: {
      small: {
        $$cardPadding: '$space$small',
        width: '11rem',
        height: '13.75rem'
      },
      large: {
        $$cardPadding: '$space$large',
        width: '15rem',
        height: '20rem'
      }
    },

    orientation: {
      portrait: {},
      landscape: {}
    }
  },

  compoundVariants: [
    {
      size: 'small',
      orientation: 'landscape',
      css: {
        width: '11rem',
        height: '7rem'
      }
    },
    {
      size: 'large',
      orientation: 'landscape',
      css: {
        width: '15rem',
        height: '11rem'
      }
    }
  ],

  defaultVariants: {
    size: 'small',
    orientation: 'portrait'
  }
});

export { QuestionCard };
