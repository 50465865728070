import { lazy } from 'react';

import { useModal } from '~/components/modal';

const CampaignEmbedSettingsModal = lazy(() => import('~/features/campaigns/modals/CampaignEmbedSettingsModal'));

function useCampaignEmbedSettings() {
  const { open } = useModal(CampaignEmbedSettingsModal);
  return open;
}

export { useCampaignEmbedSettings };
