import { useCallback } from 'react';

import { useErrorNotification, useNotification } from '~/components/notification';
import { useLazyQuery } from '~/utils/graphql';

import { EXPORT_CAMPAIGN_QUERY } from '~/queries/export-campaign-query';

function useExportCampaign() {
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const [exportVouchesQuery] = useLazyQuery(EXPORT_CAMPAIGN_QUERY);

  // TODO: this should have a progress modal flow similar to downloads, given the export
  // will take longer for large campaigns
  return useCallback(
    async ({ id }: { id: string }) => {
      try {
        const { data }: any = await exportVouchesQuery({
          variables: {
            campaignId: id
          }
        });

        const csvContent = `data:text/csv;charset=utf-8,${data.exportVouches.csv}`;
        const encodedURI = encodeURI(csvContent);
        window.location.href = encodedURI;
        notification.show({ message: 'CSV downloaded' });
      } catch (e) {
        errorNotification.show(e);
      }
    },
    [errorNotification, exportVouchesQuery, notification]
  );
}

export { useExportCampaign };
