import Script from 'next/script';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH as string;
const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW as string;

let GTM_QS = `id=${GTM_ID}`;
if (GTM_AUTH) GTM_QS += `&gtm_auth=${GTM_AUTH}`;
if (GTM_PREVIEW) GTM_QS += `&gtm_preview=${GTM_PREVIEW}`;

function GtmScript() {
  return (
    <>
      {/* Google Tag Manager */}
      {!!GTM_ID && (
        <>
          <Script id="gtm" src={`https://www.googletagmanager.com/gtm.js?${GTM_QS}`} async strategy="lazyOnload" />
          <Script
            id="gtm-init"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ 
                  event: "gtm.js",
                  "gtm.start": new Date().getTime(),
                  hide_intercom_default_launcher: true,
                  app_info: { 
                    framework: "next", 
                    version: "${process.env.NEXT_PUBLIC_RELEASE}", 
                    environment: "${process.env.NEXT_PUBLIC_ENV}",
                    context: "admin" 
                  }
                });
              `
            }}
          />
          <noscript>
            <iframe
              id="gtm-iframe"
              src={`https://www.googletagmanager.com/ns.html?id=${GTM_QS}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
        </>
      )}
    </>
  );
}

export { GtmScript };
