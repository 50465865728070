import { forwardRef } from 'react';

import type { ComponentProps } from 'react';

import { TextInput } from './TextInput';

type NumberInputProps = Omit<ComponentProps<typeof TextInput>, 'type'>;

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(function NumberInput(props, ref) {
  return <TextInput {...props} ref={ref} type="number" />;
});

export { NumberInput };
