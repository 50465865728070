import { lazy } from 'react';

import { useModal } from '~/components/modal';

const BulkMoveCampaignsModal = lazy(() => import('~/features/campaigns/modals/BulkMoveCampaignsModal'));

function useBulkMoveCampaigns() {
  const { open } = useModal(BulkMoveCampaignsModal);
  return open;
}

export { useBulkMoveCampaigns };
