import { lazy } from 'react';

import { useModal } from '~/components/modal';

const CreateMeetingModal = lazy(() => import('~/features/meetings/modals/CreateMeetingModal'));

function useCreateMeeting() {
  const { open } = useModal(CreateMeetingModal);
  return open;
}

export { useCreateMeeting };
