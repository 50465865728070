import awsExports from '~/config/aws-exports';

type ForgotPasswordArg = {
  email: string;
  invited?: boolean;
  entityHash?: string;
};

/*
 * Sends either the verication email or reset password email to the user based on user state.
 */

async function sendUserNotification({ email, invited, entityHash }: ForgotPasswordArg) {
  const publicApi =
    process.env.NEXT_PUBLIC_PUBLIC_API_URL ||
    awsExports?.aws_cloud_logic_custom?.find?.((apis) => apis?.name === 'vouchpublicapi')?.endpoint;
  const baseUrl = publicApi ? `${publicApi}/api` : undefined;

  if (!baseUrl) {
    throw new Error('Public API not found');
  }

  const response = await fetch(`${baseUrl}/sendnotification`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      email,
      invited,
      entityHash
    })
  });

  if (response.status >= 400) {
    throw new Error('Failed to reset password.');
  }
}

export { sendUserNotification };
