import { useApolloClient } from '@apollo/client';

import { useAddCoverVideoFromLibrary as useAddCoverVideo } from '~/features/request/actions/useAddCoverVideoFromLibrary';
import { useAnalyticsEvent } from '~/utils/analytics';
import { refetchOrEvict, useMutation } from '~/utils/graphql';

import { GET_CAMPAIGN_SETTINGS_QUERY } from '~/queries/get-campaign-settings-query';
import { SET_CAMPAIGN_COVER_MUTATION } from '~/queries/set-campaign-cover-mutation';

function useAddCoverVideoFromLibrary() {
  const client = useApolloClient();
  const event = useAnalyticsEvent();

  const [setCampaignCover] = useMutation(SET_CAMPAIGN_COVER_MUTATION);

  return useAddCoverVideo({
    handleSetCoverVideo: async ({ id, createdPlaylistId }) => {
      await setCampaignCover({ variables: { id: id || '', coverVouchId: createdPlaylistId } });
      await refetchOrEvict({ client, include: [GET_CAMPAIGN_SETTINGS_QUERY] });
      event('campaign_cover_created', { campaign_id: id, medium: 'library' });
    }
  });
}

export { useAddCoverVideoFromLibrary };
