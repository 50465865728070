import Script from 'next/script';

const HUBSPOT_APP_ID = process.env.NEXT_PUBLIC_HUBSPOT_APP_ID as string;

function HubspotScript() {
  return (
    <>
      {!!HUBSPOT_APP_ID && (
        <>
          <Script
            id="hubspot-script-init"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
                window.hsConversationsSettings = {
                  loadImmediately: false,
                };
              `
            }}
          />
          <Script
            id="hubspot-script"
            type="text/javascript"
            async
            strategy="lazyOnload"
            src={`//js.hs-scripts.com/${HUBSPOT_APP_ID}.js`}
          />
        </>
      )}
    </>
  );
}

export { HubspotScript };
