import { useCallback } from 'react';

import { useAuth } from '~/utils/auth';

import { event } from './event';

function useAnalyticsEvent() {
  const auth = useAuth();
  return useCallback(
    (
      name: Parameters<typeof event>[0],
      data: Parameters<typeof event>[1] = {},
      authData: Parameters<typeof event>[2] = {}
    ) => {
      const mergedAuth = { ...auth, ...authData };
      return event(name, data, mergedAuth);
    },
    [auth]
  );
}

export { useAnalyticsEvent };
