type ResetPasswordArg = {
  email: string;
  code: string;
  password: string;
};

async function resetPassword({ email, code, password }: ResetPasswordArg) {
  try {
    const { Auth } = await import('aws-amplify');

    await Auth.forgotPasswordSubmit(email, code, password);
    await Auth.signIn({ username: email, password });

    // For security reasons, we invalidate ALL tokens issues to the user when they change their password
    // We then log them in again to create a new fresh token that stays valid
    await Auth.signOut({ global: true });

    // HACK: for whatever reason this flow sometimes seem to run into race conditions where it fails
    // because the access token has been revoked, the timeout seems to fix it :|
    await new Promise((resolve) => setTimeout(resolve, 1000));

    await Auth.signIn({ username: email, password });
  } catch (e: any) {
    if (e.code !== 'UserNotConfirmedException') {
      throw e;
    }
  }
}

export { resetPassword };
