import { ErrorState } from '~/components/error-boundary';
import { DashboardMasthead } from '~/features/global/components/masthead/DashboardMasthead';
import { AuthLayout } from '~/layouts/AuthLayout';
import { useAuth } from '~/utils/auth';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';

const Container = styled('main', {
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  background: '$grey-100'
});

const Content = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center'
});

type ErrorLayoutProps = ComponentProps<typeof ErrorState>;

// NOTE: Sentry does something weird with the `fallback` in their ErrorBoundary, where it
// doesn't treat it like a functional component, which means React errors out because hooks
// can only be used in functional components :/
function Component(props: ErrorLayoutProps) {
  const auth = useAuth();
  const router = useRouter();

  if (auth?.username && router.asPath.startsWith('/dashboard/')) {
    return (
      <Container>
        <DashboardMasthead />
        <Content>
          <ErrorState {...props} pathname={router.asPath} />
        </Content>
      </Container>
    );
  }

  return <AuthLayout content={<ErrorState {...props} pathname={router.asPath} />} />;
}

function ErrorLayout(props: ErrorLayoutProps) {
  return <Component {...props} />;
}

export { ErrorLayout };
