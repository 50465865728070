import { gql } from '~/utils/codegen';

export const GET_CAMPAIGN_SETTINGS_QUERY = gql(/* GraphQL */ `
  query GetCampaignSettings($id: ID!) {
    campaign(id: $id) {
      id
      hashId
      status
      name
      createdAt
      account {
        id
        name
        avatarSrc
        entity {
          id
          name
        }
      }
      settings {
        # Brand settings
        branding {
          base {
            radius
            appearance
            primary {
              color
              text
            }
            secondary {
              color
              text
            }
          }
          button {
            primary {
              color
              text
            }
            secondary {
              color
              text
            }
          }
        }

        # Cover settings
        cover {
          message
          vouch {
            ...TemplateVouch
            status
          }
        }

        # Language settings
        language
        transcription {
          language
        }
        translation {
          enabled
          language
        }

        # Recorder settings
        urls {
          privacyPolicy
          terms
          request
        }
        options {
          sendResponseEmail
          showContactForm
          showClientFields
          showAccountDetails
          showCoverScreen
          showEstimatedTime
          showPrivacyPolicy
          showQuestionList
          showTerms
          allowAINotes
          kioskMode
          showVouchBranding
          requireAuth
          useNativeRecorder
        }
        labels {
          requestTips
          title
          submission
          welcome
          recordingTips
          termsText
          termsLabel
          privacyPolicyText
          privacyPolicyLabel
        }
        contactForm {
          fields {
            name
            label
            optional
            show
          }
        }
      }

      ## Question settings
      questions {
        items {
          id
          ordinality
          required
          title
          referenceId
          system
          settings {
            type
            orientation
            optional
            maxDuration
            maxTakes
            note
          }
        }
      }
    }
  }
`);
