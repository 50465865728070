import { useEffect, useRef } from 'react';

import { useAuth } from '~/utils/auth';

const channel = typeof window !== 'undefined' ? new BroadcastChannel('vouch-auth-updates') : undefined;

function useAuthBroadcast() {
  const auth = useAuth();

  // Send user information whenever it changes, so we can keep login status in sync in the extension
  // through the global state
  const lastAuth = useRef<any>();
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const data = auth?.account?.id
      ? {
          id: auth?.account?.id,
          name: auth?.account?.name,
          email: auth?.account?.email,
          entityId: auth?.entity?.id,
          entityName: auth?.entity?.name
        }
      : undefined;

    if (data?.id !== lastAuth.current?.id) {
      lastAuth.current = data;
      channel?.postMessage({ type: 'authUpdated', data });
      window.parent?.postMessage?.({ type: 'EXTERNAL__AUTH_UPDATED', data }, '*');
    }

    const handleMessage = (event: MessageEvent) => {
      switch (event.data?.type) {
        case 'authUpdated': {
          // Only reload if we're currently logged in and the message comes from a logged out session, or vice versa
          const wasLoggedIn = !!auth?.account?.id;
          const isLoggedIn = !!event.data?.data?.id;
          if (wasLoggedIn !== isLoggedIn) {
            window.location.reload();
          }
        }
      }
    };
    channel?.addEventListener('message', handleMessage);
    return () => channel?.removeEventListener('message', handleMessage);
  }, [auth]);
}

export { useAuthBroadcast };
