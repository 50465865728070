import { lazy } from 'react';

import { useModal } from '~/components/modal';

const PlaylistDownloadSettingsModal = lazy(() => import('~/features/playlists/modals/PlaylistDownloadSettingsModal'));

function usePlaylistDownloadSettings() {
  const { open } = useModal(PlaylistDownloadSettingsModal);
  return open;
}

export { usePlaylistDownloadSettings };
