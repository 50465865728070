import { gql } from '@apollo/client';

export const VIDEO_ANSWER_FRAGMENT = gql`
  fragment VideoAnswer on Answer {
    id
    label
    media {
      video
      input
      playlist
      thumbnail
      captions
      reducedQuality
      thumbnails {
        original
        lg
        md
        sm
        xs
      }
      videos {
        xs
      }
    }
    contact {
      id
      name
      email
      department
      roleTitle
      location
      client {
        id
        name
        logoSrc
      }
    }
    settings {
      startOffset
      endOffset
      crop {
        x
        y
        width
        height
      }
      thumbnail {
        timestamp
        system
        isCustom
        updatedAt
      }
    }
    metadata {
      duration
      resolution
      aspectRatio
    }
    transcription {
      language
      translation {
        language
      }
    }
  }
`;
