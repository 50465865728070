import { lazy } from 'react';

import { useModal } from '~/components/modal';

const BulkDeletePlaylistsModal = lazy(() => import('~/features/playlists/modals/BulkDeletePlaylistsModal'));

function useBulkDeletePlaylists() {
  const { open } = useModal(BulkDeletePlaylistsModal);
  return open;
}

export { useBulkDeletePlaylists };
