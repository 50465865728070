import { gql } from '~/utils/codegen';

export const CREATE_QUESTION_MUTATION = gql(/* GraphQL */ `
  mutation CreateQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      id
      title
    }
  }
`);
