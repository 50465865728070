const ignoreErrors = [
  // Cognito/Amplify auth flows
  'Error Notification: Incorrect username or password.',
  'Error Notification: User does not exist.',
  'Error Notification: A user with that email address already exists.',
  'Error Notification: User password cannot be reset in the current state.',
  'Error Notification: Username/client id combination not found.',
  'Error Notification: Invalid session for the user, session is expired.',
  'Error Notification: Invalid session for the user, session can only be used once.',
  'Error Notification: Password attempts exceeded',
  'Error Notification: Attempt limit exceeded, please try after some time.',
  'Error Notification: This account has been disabled. Please see your account administrator.',
  'Apollo client: No current user',
  'Apollo SSR client: No current user',
  'Signout timeout fail',

  // Cognito/Amplify auth flows - grouped separately so we can more easily see how often they occur
  'Error Notification: Temporary password has expired and must be reset by an administrator.',
  'Error Notification: This account has been disabled. Please see your account administrator.',
  'Error Notification: Password reset required for the user',
  'Error Notification: Invalid code provided, please request a code again.',

  // Apollo client
  'Apollo client: Local storage is missing an ID Token, Please authenticate',
  'Apollo client: Network error',

  // GraphQL error responses
  'GraphQL API Error: Token has expired.',
  'GraphQL API Error: UnauthorizedException',

  // Stripe
  "Error Notification: Your card's security code is incomplete.",
  "Error Notification: Your card's expiration date is incomplete.",
  'Error Notification: Your card number is incomplete.',

  // Sentry
  'Error in compression worker',
  "Failed to execute 'contains' on 'Node': parameter 1 is not of type 'Node'.",

  // UI components
  'Error Notification: File type is not supported.',
  'Error Notification: Contact has 1 vouches, please delete all vouches',
  'Error Notification: Unable to verify domain, try again in a few hours.',

  "The fetching process for the media resource was aborted by the user agent at the user's request.",

  // Resize observer
  // https://stackoverflow.com/a/50387233
  'ResizeObserver loop completed with undelivered notifications',

  // Media player
  'AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22',
  'AbortError: The play() request was interrupted because the media was removed from the document. https://goo.gl/LdLk22',

  // Next JS
  'Cancel rendering route'
];

export { ignoreErrors };
