import Balancer from 'react-wrap-balancer';

import { Card } from '~/components/card';
import { Heading } from '~/components/text';
import { heading, styled } from '~/utils/styling';

import type { ReactNode } from 'react';

const Container = styled('div', {
  width: '100%',
  maxHeight: '100%',
  margin: '0 auto',
  padding: '2rem $medium',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  overflow: 'hidden'
});

const AuthHeadingText = styled(Heading, {
  '&&': {
    maxWidth: '30rem',
    color: '$s-brand-800',
    marginBottom: '$space$huge',
    ...heading({ size: 'large' }),

    '@bp-medium': {
      ...heading({ size: 'huge' })
    }
  }
});

const AuthStackContent = styled(Card, {
  '&&': {
    background: '$light-1000',
    borderRadius: '$large',
    boxShadow: '$medium',
    maxWidth: '22rem',
    height: 'auto'
  }
});

const AuthStackFooter = styled('div', {
  maxWidth: '22rem',
  marginTop: '$space$large'
});

function AuthStack({ heading, content, footer }: { heading?: string; content?: ReactNode; footer?: ReactNode }) {
  return (
    <Container>
      {heading ? (
        <AuthHeadingText level={1}>
          <Balancer>{heading}</Balancer>
        </AuthHeadingText>
      ) : null}
      {content ? <AuthStackContent>{content}</AuthStackContent> : null}
      {footer ? <AuthStackFooter>{footer}</AuthStackFooter> : null}
    </Container>
  );
}

export { AuthStack };
