import { useApolloClient } from '@apollo/client';

import { useNotification } from '~/components/notification';
import { useErrorNotification } from '~/components/notification';
import { refetchOrEvict, useMutation } from '~/utils/graphql';

import { GET_ACCOUNTS_QUERY } from '~/queries/get-accounts-query';
import { UPDATE_ROLE_MUTATION } from '~/queries/update-role-mutation';

import type { Role } from '~/utils/codegen/graphql';

function useUpdateRole() {
  const client = useApolloClient();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const [updateRole] = useMutation(UPDATE_ROLE_MUTATION);

  return async ({ id, role }: { id: string; role: Role }) => {
    try {
      await updateRole({ variables: { id, role } });
      await refetchOrEvict({ client, include: [GET_ACCOUNTS_QUERY] });
      notification.show({ message: 'Role has been successfully updated' });
    } catch (e) {
      errorNotification.show(e);
    }
  };
}

export { useUpdateRole };
