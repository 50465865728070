/**
 * THIS IS A VERY NON-SCIENTIFIC WAY TO CALCULATE A CUSTOM PERFORMANCE SCORE, VERY ROUGHLY
 * BASED ON THE THRESHOLDS DESCRIBED ON https://googlechrome.github.io/lighthouse/scorecalc
 */

import isMobile from 'is-mobile';

import performanceMaps from '~/config/performanceScoreMaps.json';

type GetScoreForValueArg = {
  metricId: string;
  metricValue: number;
};

function getScoreForValue({ metricId, metricValue }: GetScoreForValueArg) {
  const map: any = isMobile()
    ? performanceMaps[metricId as keyof typeof performanceMaps]?.mobile
    : performanceMaps[metricId as keyof typeof performanceMaps]?.desktop;

  if (!map) {
    return;
  }

  return map.find((item: any) => item.value <= metricValue)?.score;
}

export { getScoreForValue };
