import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { refetchOrEvict, useMutation } from '~/utils/graphql';

import { GET_ACCOUNTS_QUERY } from '~/queries/get-accounts-query';
import { INVITE_USERS_MUTATION } from '~/queries/invite-users-mutation';

import type { Role } from '~/utils/codegen/graphql';

function useInviteUsers() {
  const client = useApolloClient();

  const [inviteUsers] = useMutation(INVITE_USERS_MUTATION);

  const inviteTeamMember = useCallback(
    async ({ emails, role }: { emails: string[]; role: Role }) => {
      await inviteUsers({ variables: { emails, role } });
      await refetchOrEvict({ client, include: [GET_ACCOUNTS_QUERY] });
    },
    [client, inviteUsers]
  );

  return inviteTeamMember;
}

export { useInviteUsers };
