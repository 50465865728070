import * as Sentry from '@sentry/nextjs';

import { useContactUs } from '~/features/global/actions/useContactUs';
import { Link } from '~/utils/routing/Link';

import type { MouseEvent, ReactNode } from 'react';

import { NotFoundError, PermissionError } from './errors';

type Message = {
  title: string;
  description: ReactNode;
  showRefresh?: boolean;
};

function useErrorMessage(error: Error & { code?: number; context?: any }): Message {
  const contactUs = useContactUs();

  const contactSupport = (e: MouseEvent) => {
    e.preventDefault();
    contactUs({ type: 'error-message' });
  };

  const refreshPage = (e: MouseEvent) => {
    e.preventDefault();
    window?.location?.reload?.();
  };

  if (error.code === NotFoundError.code) {
    // NOTE: this is just to catch issues we had previously with `null` or `undefined` values in the url,
    // which come from actual bugs e.g. in the public recorder when redirecting back to admin
    // We trigger a separate sentry error here, so we can safely ignore the common `404` errors
    const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
    const hasInvalidParams = pathname.includes('/null/') || pathname.includes('/undefined/');
    if (typeof window !== 'undefined' && hasInvalidParams) {
      Sentry.captureException(new Error('Error Boundary: Not found - likely with invalid params'));
    }

    if (error.context?.label && ['Vouch', 'request', 'playlist'].includes(error.context.label)) {
      return {
        title: 'Uh-oh!',
        description: (
          <>
            We can’t find the {error.context.label} you were looking for. This might be because the{' '}
            {error.context.label} has been deleted, or because it belongs to a different account than the one you are
            logged into. To get back on track, return to the <Link href="/dashboard/home">home page</Link> or{' '}
            <a href="#" onClick={contactSupport}>
              contact us
            </a>{' '}
            if you need help.
          </>
        )
      };
    }

    return {
      title: 'Uh-oh!',
      description: (
        <>
          We can’t find the page you were looking for. To get back on track, return to the{' '}
          <Link href="/dashboard/home">home page</Link> or{' '}
          <a href="#" onClick={contactSupport}>
            contact us
          </a>{' '}
          if you need help.
        </>
      )
    };
  }

  if (error.code === PermissionError.code) {
    return {
      title: 'This page is restricted',
      description: (
        <>
          You do not have permission to view this page. If you think this could be a mistake, ask your account admin to
          give you access or{' '}
          <a href="#" onClick={contactSupport}>
            contact our support team
          </a>
          .
        </>
      )
    };
  }

  return {
    title: 'Oops!',
    description: (
      <>
        Something went wrong and we could not complete your request. Please{' '}
        <a href="#" onClick={refreshPage}>
          refresh the page
        </a>{' '}
        and try again or{' '}
        <a href="#" onClick={contactSupport}>
          contact our support team
        </a>
        .
      </>
    ),
    showRefresh: true
  };
}

export { useErrorMessage };
