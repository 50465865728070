import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$borderWidths$thin',

  '&& > *': {
    borderRadius: 0,

    '&:first-child': {
      borderTopLeftRadius: '$small',
      borderBottomLeftRadius: '$small'
    },

    '&:last-child': {
      borderTopRightRadius: '$small',
      borderBottomRightRadius: '$small'
    },

    '&[data-outlined="true"]': {
      '&:not(:first-child)': {
        marginLeft: 'calc(-2 * $borderWidths$thin)'
      },

      '&:hover': {
        zIndex: 2
      }
    }
  }
});

function ButtonGroup(props: ComponentProps<typeof Container>) {
  return <Container {...props} />;
}

export { ButtonGroup };
