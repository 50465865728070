import { useApolloClient } from '@apollo/client';
import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { usePlanUsage } from '~/features/billing/utils/usePlanUsage';
import { refetchOrEvict, useMutation } from '~/utils/graphql';

import { GET_CAMPAIGN_QUERY } from '~/queries/get-campaign-query';
import { SEARCH_ENTITY_QUERY } from '~/queries/search-entity-query';
import { UPDATE_CAMPAIGN_MUTATION } from '~/queries/update-campaign-mutation';

const CampaignLimitModal = lazy(() => import('~/features/campaigns/modals/CampaignLimitModal'));

function useResumeCampaign() {
  const usage = usePlanUsage();
  const client = useApolloClient();

  const isAtLimit = !!usage?.limit?.campaigns && usage.usage.campaigns >= usage.limit.campaigns;

  const campaignLimitModal = useModal(CampaignLimitModal);

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN_MUTATION);

  return useCallback(
    async ({ id }: { id: string }) => {
      if (isAtLimit) {
        return campaignLimitModal.open();
      }

      await updateCampaign({ variables: { input: { id, status: 'LIVE' } } });
      await Promise.all([
        usage.refresh(),
        refetchOrEvict({ client, include: [GET_CAMPAIGN_QUERY, SEARCH_ENTITY_QUERY] })
      ]);
    },
    [campaignLimitModal, client, isAtLimit, updateCampaign, usage]
  );
}

export { useResumeCampaign };
