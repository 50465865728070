import { gql } from '@apollo/client';

export const TEMPLATE_VOUCH_FRAGMENT = gql`
  fragment TemplateVouch on Vouch {
    id
    questions {
      items {
        id
        title
        answer {
          ...VideoAnswer
        }
      }
    }
    settings {
      template {
        instance {
          ...Instance
        }
      }
      branding {
        base {
          primary {
            text
            color
          }
          secondary {
            text
            color
          }
        }
      }
    }
  }
`;
