import { useEffect, useRef, useState } from 'react';

// NOTE: I tried `startPolling` and `stopPolling`, utilising apollo's internal polling
// functionality, but couldn't get it to work, so we do the polling manually here via
// `setInterval` :/

type UsePollingIntervalArg = {
  fn?: () => any;
  ms?: number;
};

function usePollingInterval({ fn, ms }: UsePollingIntervalArg) {
  // To not waste memory, we want to disable the polling when the tab is currently not
  // visible to the user, and restart the polling once it becomes visible again
  const initialVisible = typeof window !== 'undefined' && window.document.visibilityState === 'visible';
  const [visible, setVisible] = useState(initialVisible);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleChange = () => setVisible(window.document.visibilityState === 'visible');
      window.document.addEventListener('visibilitychange', handleChange);
      return () => window.document.removeEventListener('visibilitychange', handleChange);
    }
  });

  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (fn && ms && visible) {
      timer.current = setInterval(() => fn?.(), ms);
      return () => clearInterval(timer.current);
    }
  }, [fn, ms, visible]);
}

export { usePollingInterval };
