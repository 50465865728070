import { configure } from '~/utils/amplify';
import { loadSSRFeatureFlags } from '~/utils/feature-flags';
import { queryServer } from '~/utils/graphql';
import { timerEnd, timerStart } from '~/utils/performance';

import { GET_ACCOUNT_AND_ENTITY_QUERY } from '~/queries/get-account-and-entity-query';
import { GET_ENTITIES_QUERY } from '~/queries/get-entities-query';

import type { GetServerSidePropsContext } from 'next';

import { mapAuthData } from './mapAuthData';

type UseSSRAuthArgs = {
  context: GetServerSidePropsContext;
};

async function loadAuth({ context }: UseSSRAuthArgs) {
  await configure();

  const { withSSRContext } = await import('aws-amplify');
  const { Auth } = withSSRContext(context);

  let auth;
  try {
    auth = await Auth.currentAuthenticatedUser();
  } catch {
    // User is not authenticated
  }

  // Always fetch feature flags to make sure we get them even for unauthenticated users
  const token = auth?.signInUserSession?.idToken?.payload;
  const accountId = token?.accountId || auth?.attributes?.sub;
  const entityId = token?.['custom:entityid'] || auth?.attributes?.['custom:entityid'];

  timerStart('authLoadFeatureFlags', { desc: 'Auth: Load Feature Flags' });
  const featureFlags = await loadSSRFeatureFlags({ email: token?.email, accountId, entityId });
  timerEnd('authLoadFeatureFlags');

  if (!auth) {
    return mapAuthData({ auth, featureFlags });
  }

  const getAccount = async () => {
    timerStart('authGetAccount', { desc: 'GQL: getAccount' });
    const res = await queryServer({
      context,
      query: GET_ACCOUNT_AND_ENTITY_QUERY,
      variables: { id: accountId, entityId }
    });
    timerEnd('authGetAccount');
    return res;
  };

  const getEntities = async () => {
    timerStart('authGetEntities', { desc: 'GQL: getEntities' });
    const res = await queryServer({ context, query: GET_ENTITIES_QUERY });
    timerEnd('authGetEntities');
    return res;
  };

  const [accountQuery, entitiesQuery] = await Promise.allSettled([
    accountId && entityId ? getAccount() : undefined,
    getEntities()
  ]);

  return mapAuthData({
    auth,
    account: accountQuery?.status === 'fulfilled' ? accountQuery?.value?.data?.account : undefined,
    entity: accountQuery?.status === 'fulfilled' ? accountQuery?.value?.data?.entity : undefined,
    entities: entitiesQuery?.status === 'fulfilled' ? entitiesQuery?.value?.data?.entities : undefined,
    featureFlags: featureFlags
  });
}

export { loadAuth };
