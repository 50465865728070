import { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import type { PropsWithChildren } from 'react';

type title = string | undefined | null;
type HeadProps = PropsWithChildren<{
  title?: title[] | title;
  description?: string;
}>;

function Head({
  title = [],
  description = 'Vouch is a Q&A platform that lets you collect, manage and share short-form video asynchronously and remotely.',
  children
}: HeadProps) {
  const displayTitle = useMemo(
    () => (Array.isArray(title) ? title : [title]).concat(['Vouch']).filter(Boolean).join(' | '),
    [title]
  );

  return (
    <Helmet>
      <title>{displayTitle}</title>
      {description && <meta key="description" name="description" content={description} />}

      {children}
    </Helmet>
  );
}

export { Head };
