import { lazy } from 'react';

import { useModal } from '~/components/modal';

const ShareEmailEmbedModal = lazy(() => import('~/features/share/modals/ShareEmailEmbedModal'));

function useShareEmailEmbed() {
  const { open } = useModal(ShareEmailEmbedModal);
  return open;
}

export { useShareEmailEmbed };
