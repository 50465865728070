import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { styled } from '~/utils/styling';

import type { Placement } from '@popperjs/core';
import type { ReactNode, CSSProperties, MutableRefObject } from 'react';
import type mergeRefs from 'react-merge-refs';

const Container = styled('div', {
  padding: '$tiny',
  background: '$dark-1000',
  color: '$light-1000',
  fontSize: '$small',
  position: 'relative',
  filter: 'drop-shadow($shadows$medium)',
  borderRadius: '$tiny',
  zIndex: 200,

  '& a': {
    color: '$light-800',

    '&:hover, &:focus': {
      color: '$light-1000'
    }
  }
});

const Arrow = styled('div', {
  visibility: 'hidden',

  '&:before': {
    visibility: 'visible',
    content: '" "',
    transform: 'rotate(45deg)'
  },

  '&, &:before': {
    position: 'absolute',
    zIndex: 1,
    width: '.5rem',
    height: '.5rem',
    background: '$dark-1000'
  },

  '[data-popper-placement^="top"] > &': {
    bottom: '-.25rem'
  },

  '[data-popper-placement^="bottom"] > &': {
    top: '-.25rem'
  },

  '[data-popper-placement^="left"] > &': {
    right: '-.25rem'
  },

  '[data-popper-placement^="right"] > &': {
    left: '-.25rem'
  }
});

type UpdateFn = () => any;

type ChildrenProps =
  | Record<string, never>
  | {
      ref: MutableRefObject<any> | ReturnType<typeof mergeRefs>;
      onPointerEnter: (e: any) => void;
      onPointerLeave: (e: any) => void;
      onFocus: (e: any) => void;
      onBlur: (e: any) => void;
      tabIndex: 0;
      'aria-describedby'?: string;
      'aria-label'?: string;
      _: {
        update: UpdateFn | null;
      };
    };

type TooltipProps = {
  children: (props: ChildrenProps) => ReactNode;
  content: ReactNode;
  placement?: Placement;
  portalTarget?: HTMLElement;
  showTimeout?: number;
  hideTimeout?: number;
  maxWidth?: CSSProperties['maxWidth'];
};

// Storing the current callback independent from the component instance
// to be able to ensure that only one tooltip is ever visible at the
// same time
let currentCallback: any = null;

function TooltipInner({
  children,
  content,
  placement = 'top',
  portalTarget,
  showTimeout = 300,
  hideTimeout = 500,
  maxWidth = '16rem'
}: TooltipProps) {
  const id = useId();

  // Component instance specific timers to deal with show and hide delays
  const showTimer = useRef<any>();
  const hideTimer = useRef<any>();

  // Internal state keeping track of visibility of tooltip
  const [visible, setVisible] = useState(false);

  // Initialise popper
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [popper, setPopper] = useState(null);
  const [arrow, setArrow] = useState(null);
  const { styles, attributes, update } = usePopper(element, popper, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrow
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 18
        }
      }
    ]
  });

  const handleShow = useCallback(
    (e: any) => {
      clearTimeout(hideTimer.current);
      clearTimeout(showTimer.current);

      // A bit hacky, but seemed like the easiest way rn to check whether
      // or not another tooltip is currently visible
      const otherTooltipVisible = !visible && window.document.body.querySelector('[role=tooltip]');

      if (e.type === 'focus' || otherTooltipVisible || !showTimeout) {
        // On focus, or when another tooltip is currently visibly, we want
        // to show the new tooltip immediately
        currentCallback?.();
        setVisible(true);

        // HACK: for now, we're using custom events to ensure only one tooltip is ever visible at
        // a time and we're always hiding the visible ones when a new one is shown
        window.document.dispatchEvent(
          new CustomEvent('vouch:hide-tooltips', {
            detail: { id }
          })
        );
      } else {
        showTimer.current = setTimeout(() => {
          setVisible(true);
          currentCallback = () => setVisible(false);
        }, showTimeout);
      }

      currentCallback = () => setVisible(false);
    },
    [showTimeout, visible, id]
  );

  const handleHide = useCallback(
    (e?: any) => {
      clearTimeout(showTimer.current);
      clearTimeout(hideTimer.current);

      if (!e?.type || e.type === 'blur' || !hideTimeout) {
        // On blur we want to hide immediately
        setVisible(false);
      } else {
        hideTimer.current = setTimeout(() => {
          setVisible(false);
        }, hideTimeout);
      }
    },
    [hideTimeout]
  );

  // Tooltips should also close when the user presses the `esc` key
  // so we register an event listener whenever the visible state is true
  useEffect(() => {
    function handleKeydown(e: any) {
      if (e.key === 'Escape') {
        setVisible(false);
      }
    }

    function handleHideTooltips(e: any) {
      if (e.detail.id !== id) {
        handleHide();
      }
    }

    if (visible) {
      window.document.addEventListener('keydown', handleKeydown);
      window.document.addEventListener('vouch:hide-tooltips', handleHideTooltips);
      return () => {
        window.document.removeEventListener('keydown', handleKeydown);
        window.document.removeEventListener('vouch:hide-tooltips', handleHideTooltips);
      };
    }
  }, [visible, id, handleHide]);

  // TODO: add `element?.closest?.('[data-popper-target]')`, currently it has some weird side
  // effects to the tooltips initial positioning :/
  // https://vouch.atlassian.net/browse/VCH-2521
  const target = typeof window === 'undefined' ? undefined : portalTarget || window.document.body;

  return (
    <>
      {children({
        ref: setElement,
        // NOTE: `onMouseLeave` does not trigger for disabled buttons and potentially other elements for certain edge
        // cases, so we use pointer events instead, see https://github.com/facebook/react/issues/18753
        onPointerEnter: handleShow,
        onPointerLeave: handleHide,
        onFocus: handleShow,
        onBlur: handleHide,
        tabIndex: 0,
        ...(visible ? { 'aria-describedby': `tooltip-${id}` } : { 'aria-label': content?.toString() }),
        _: { update }
      })}

      {target &&
        visible &&
        createPortal(
          <Container
            ref={setPopper as any}
            id={`tooltip-${id}`}
            role="tooltip"
            style={styles.popper}
            onPointerEnter={handleShow}
            onPointerLeave={handleHide}
            css={{ maxWidth }}
            {...attributes.popper}
          >
            <div>{content}</div>
            <Arrow data-popper-arrow ref={setArrow as any} style={styles.arrow} />
          </Container>,
          target
        )}
    </>
  );
}

function Tooltip({ content, children, ...props }: TooltipProps) {
  if (!content) {
    return <>{children({})}</>;
  }

  return (
    <TooltipInner content={content} {...props}>
      {(props) => children(props)}
    </TooltipInner>
  );
}

export { Tooltip };
