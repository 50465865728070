function getResolutionLabel({ resolution }: { resolution?: string }) {
  const dimensions = resolution?.split(':')?.map((str) => parseInt(str)) || [];
  if (dimensions.length < 2) {
    return null;
  }

  const shorterSide = Math.min(...dimensions);
  if (shorterSide >= 4320) {
    return '8K';
  }

  if (shorterSide >= 2160) {
    return '4K';
  }

  if (shorterSide >= 1080) {
    return 'HD';
  }

  return null;
}

export { getResolutionLabel };
