import { lazy } from 'react';

import { useModal } from '~/components/modal';

const BulkDuplicateCampaignsModal = lazy(() => import('~/features/campaigns/modals/BulkDuplicateCampaignsModal'));

function useBulkDuplicateCampaigns() {
  const { open } = useModal(BulkDuplicateCampaignsModal);
  return open;
}

export { useBulkDuplicateCampaigns };
