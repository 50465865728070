import { useMemo } from 'react';

import { useCreateMeeting } from './useCreateMeeting';

function useMeetingActions() {
  const createMeeting = useCreateMeeting();

  return useMemo(
    () => ({
      createMeeting
    }),
    [createMeeting]
  );
}

export { useMeetingActions };
