import { Button, IconButton } from '~/components/button';
import { Field, Form, useForm } from '~/components/form';
import { Masthead } from '~/components/masthead';
import { Bold } from '~/components/text';
import { TextInput } from '~/components/text-input';
import { openPopout } from '~/features/extension/utils/openPopout';
import { useGlobalState } from '~/features/extension/utils/useGlobalState';
import { WorkspaceSwitcher } from '~/features/workspaces/components/WorkspaceSwitcher';
import { useAnalyticsEvent } from '~/utils/analytics';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { Item } from '~/components/action-menu';

const StickyHeader = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  zIndex: 10
});

const Banner = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$small $medium',
  background: '$teal-600',
  color: '$light-1000'
});

const Search = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  background: '$light-1000',
  borderBottom: '$borderWidths$thin solid $dark-80',
  padding: '$tiny $medium $tiny $tiny',
  zIndex: 9,
  gap: '$tiny',

  '& input': {
    padding: '0 $small',
    height: '$medium',
    border: '0 none',
    display: 'flex',
    flex: 1,

    '&::placeholder': {
      color: '$dark-200'
    },

    '&:-internal-autofill-selected': {
      background: '$light-1000 !important'
    },

    '&:focus': {
      outline: 'none'
    }
  }
});

const StyledWorkspaceSwitcher = styled(WorkspaceSwitcher, {
  maxWidth: '11rem'
});

const WrapActions = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$tiny',
  marginRight: '$tiny'
});

type ExtensionMastheadProps = {
  onRecord?: () => any;
};

function ExtensionMasthead({ onRecord }: ExtensionMastheadProps) {
  const globalState = useGlobalState();
  const event = useAnalyticsEvent();
  const router = useRouter();

  const menuItems: Item[] = [
    { isSeparator: true },
    {
      label: 'Open Vouch in new tab',
      href: '/dashboard/library/?source=extension',
      target: '_blank',
      onClick: () => {
        event('extension_menu_navigate', {
          type: 'library'
        });
      }
    },
    {
      label: 'Logout',
      onClick: async () => {
        // For 3rd party authenticated users the sign out will actually redirect them through the 3rd party
        // which will lead to CORS errors in the content script, so we open the sign out flow in a new window
        // TODO: could add a check if the user is current signed in through Google
        // and only then do the popout flow
        openPopout({ url: '/sign-out?source=extension' });
      }
    }
  ];

  const searchTerm = router.query?.s as string;
  const form = useForm({
    id: 'extension-search-form',
    defaultValues: { searchTerm },
    onSubmit: (values) => {
      router.push({ query: { ...router.query, s: values.searchTerm } });
    }
  });

  return (
    <StickyHeader>
      {globalState?.updateAvailable && (
        <Banner>
          <Bold>Update available</Bold>
          <Button
            color="brand-light"
            onClick={() =>
              new Promise(() => {
                return window.parent?.postMessage?.({ type: 'EXTERNAL__RELOAD' }, '*');
              })
            }
            size="small"
          >
            Reload extension
          </Button>
        </Banner>
      )}
      <Masthead css={{ paddingRight: '$tiny' }}>
        <StyledWorkspaceSwitcher
          showActions={false}
          path="/dashboard/extension"
          interactive
          forceLogo
          items={menuItems}
        />

        <WrapActions>
          {onRecord ? (
            <Button color="brand" icon="camera" onClick={onRecord}>
              Record
            </Button>
          ) : null}
        </WrapActions>
      </Masthead>
      <Form form={form}>
        <Search>
          <Field
            name="searchTerm"
            Input={TextInput}
            inputProps={{ autoFocus: true, type: 'search', placeholder: 'Search your library...' }}
          />
          <IconButton color="grey" icon="search" aria-label="Search" />
        </Search>
      </Form>
    </StickyHeader>
  );
}

export { ExtensionMasthead };
