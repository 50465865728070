import awsExports from '~/config/aws-exports';

// Get current amplify env based on bucket name
const amplifyEnv = awsExports.aws_user_files_s3_bucket.endsWith('-dev')
  ? 'dev'
  : awsExports.aws_user_files_s3_bucket.endsWith('-staging')
  ? 'staging'
  : 'production';

const uikitEnv = amplifyEnv === 'dev' ? 'dev' : amplifyEnv === 'staging' ? 'staging' : 'prod';

export { uikitEnv };
