import { useCallback, type ComponentProps, type ReactNode } from 'react';

import { Button } from '~/components/button';
import { useAnalyticsEvent } from '~/utils/analytics';

import type { ModalPassthroughProps } from './ModalContext';

import { Modal } from './Modal';
import { useModal } from './useModal';

type ConfirmationModalProps = ModalPassthroughProps &
  Omit<ComponentProps<typeof Modal>, 'children' | 'actions'> & {
    message: ReactNode;
    confirmLabel?: string;
    onConfirm?: () => void | Promise<void>;
  };

function ConfirmationModal({ id, title, message, confirmLabel = 'Confirm', onConfirm, close }: ConfirmationModalProps) {
  const event = useAnalyticsEvent();

  const handleConfirm = useCallback(async () => {
    event('modal_confirm_clicked', {
      type: id,
      // TODO: ideal future event attributes
      modal_id: id
    });
    await onConfirm?.();
    close?.();
  }, [event, id, onConfirm, close]);

  return (
    <Modal
      id={id}
      onClose={close}
      title={title}
      actions={
        <>
          <Button color="brand" onClick={handleConfirm}>
            {confirmLabel}
          </Button>
        </>
      }
    >
      {message}
    </Modal>
  );
}

function useConfirmationModal() {
  return useModal(ConfirmationModal);
}

export { useConfirmationModal };
