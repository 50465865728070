import { keyframes, styled } from '~/utils/styling';

import type { ComponentProps } from 'react';

const flashing = keyframes({
  '0%, 30%': { opacity: '$$spinner-opacity' },
  '65%': { opacity: 1 },
  '100%': { opacity: '$$spinner-opacity' }
});

const Container = styled('span', {
  '$$spinner-opacity': 0.3,
  '$$spinner-duration': '1.2s',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
});

const Dot = styled('span', {
  animation: `${flashing} $$spinner-duration infinite linear`,
  display: 'flex',
  width: '.4rem',
  height: '.4rem',
  borderRadius: '.2rem',
  background: 'currentColor',
  opacity: '$$spinner-opacity',
  marginLeft: '.15rem',

  '&:nth-child(1)': {
    marginLeft: 0
  },

  '&:nth-child(2)': {
    animationDelay: 'calc($$spinner-duration / 3)'
  },

  '&:nth-child(3)': {
    animationDelay: 'calc($$spinner-duration / 3 * 2)'
  },

  variants: {
    size: {
      xs: {
        width: '.2rem',
        height: '.2rem',
        borderRadius: '.1rem'
      },

      small: {
        width: '.3rem',
        height: '.3rem',
        borderRadius: '.15rem'
      },

      medium: {
        width: '.4rem',
        height: '.4rem',
        borderRadius: '.2rem'
      }
    }
  },

  defaultVariants: {
    size: 'medium'
  }
});

type LoadingSpinnerProps = ComponentProps<typeof Container> & {
  size?: ComponentProps<typeof Dot>['size'];
  color?: string;
};

function LoadingSpinner({ size = 'medium', color, ...props }: LoadingSpinnerProps) {
  return (
    <Container {...props} css={{ color }}>
      <Dot size={size} />
      <Dot size={size} />
      <Dot size={size} />
    </Container>
  );
}

export { LoadingSpinner };
