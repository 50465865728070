// TODO: should be a mutation on the BE!

import { gql } from '~/utils/codegen';

export const EXPORT_CAMPAIGN_QUERY = gql(/* GraphQL */ `
  query ExportVouches($campaignId: ID) {
    exportVouches(campaignId: $campaignId) {
      csv
    }
  }
`);
