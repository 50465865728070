import { Amplify } from 'aws-amplify';

import awsExports from '~/config/aws-exports';

type ConfigureArg = {
  pathname?: string;
};

async function configure(options: ConfigureArg = {}) {
  const isServer = typeof window === 'undefined';

  let pathname = options?.pathname;
  if (!pathname && !isServer) {
    pathname = window.location.pathname;
  }

  const preventUrlList = [
    '/reset-password/verify',
    '/reset-password/confirm',
    '/account-setup/verify',
    '/account-setup/complete',
    '/account-setup/confirm-account'
  ];

  const preventOauth = isServer || (!!pathname && !!preventUrlList.includes(pathname));

  // Set up Amplify for SSR
  const config: Parameters<typeof Amplify.configure>[0] = {
    ...awsExports,
    ssr: true,

    // NOTE: cognito is very agressive parsing the current URL when it gets instantiated and trying
    // to perform the oauth flow whenever it detects `code` and `email` in the query params. So here
    // we manually prevent that for specific urls where we want to manually deal with URL codes
    oauth: {
      ...awsExports.oauth,
      domain: process.env.NEXT_PUBLIC_OAUTH_DOMAIN || awsExports.oauth.domain,
      redirectSignIn: isServer || preventOauth ? undefined : `${window.location.origin}/authorise/`,
      redirectSignOut: isServer
        ? undefined
        : pathname?.startsWith('/oauth')
        ? `${window.location.origin}/oauth/sign-out/complete/`
        : `${window.location.origin}/sign-out/complete/`
    },

    // We need the `sameSite` policy to be `none`, to be able to receive the cookie even when
    // redirected from another site (i.e. from CW after creating a self recording)
    // https://web.dev/samesite-cookies-explained/#explicitly-state-cookie-usage-with-the-samesite-attribute
    ...(process.env.NEXT_PUBLIC_COOKIE_DOMAIN
      ? {
          Auth: {
            cookieStorage: {
              domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
              path: '/',
              expires: 7,
              // These two conditionals are for allowing Auth to work properly for Safari during local development
              // See https://stackoverflow.com/questions/70002648/amplify-gives-no-current-user-only-in-safari
              //
              // We also added the `NEXT_PUBLIC_NOT_QUITE_PRODUCTION` variable to give us a pseudo-production build for e2e
              // testing with the `secure` & `sameSite` policy still forced onto the local dev settings
              // See https://vouch.atlassian.net/browse/VCH-3484
              //
              // We ALSO will need to figure out if there's a nicer way to develop the chrome extension without using the
              // `NEXT_PUBLIC_EXTENSION_DEV` flag, { secure: true, sameSite: 'none' } is needed in the iframe
              // See https://vouch.atlassian.net/browse/VCH-3509
              secure: process.env.NODE_ENV !== 'development' || process.env.NEXT_PUBLIC_EXTENSION_DEV === 'true',
              sameSite:
                process.env.NODE_ENV !== 'development' || process.env.NEXT_PUBLIC_EXTENSION_DEV === 'true'
                  ? 'none'
                  : 'lax'
            }
          }
        }
      : {})
  };

  // NOTE: Amplify registers url listeners to deal with 3rd party oauth flows (e.g. Google login)
  // when you import the `Auth` module. Since we're now lazy loading it whereever possible it won't
  // always be loaded on the `/authorise` page, where we need Amplify to handle the oauth flow. So we
  // make sure it's loaded by importing it here
  if (pathname?.startsWith('/authorise')) {
    await import('@aws-amplify/auth');
  }

  Amplify.configure(config);
}

export { configure };
