type OpenPopoutArg = {
  url: string;
  width?: number;
  height?: number;
};

function openPopout({ url, width = 450, height = 500 }: OpenPopoutArg) {
  const left = window.screenLeft + (window.outerWidth / 2 - width / 2);
  const top = window.screenTop + (window.outerHeight / 2 - height / 2);
  const features = `popup,width=${width},height=${height},top=${top},left=${left}`;
  window.open(url, `_blank`, features);
}

export { openPopout };
