import { styled } from '~/utils/styling';

import { Box, createSpacingVariant } from './Box';

const Flex = styled(Box, {
  display: 'flex',

  variants: {
    gap: createSpacingVariant('gap'),
    direction: {
      row: { flexDirection: 'row' },
      column: { flexDirection: 'column' }
    },
    alignItems: {
      center: { alignItems: 'center' },
      start: { alignItems: 'flex-start' },
      end: { alignItems: 'flex-end' }
    },
    justifyContent: {
      center: { justifyContent: 'center' },
      start: { justifyContent: 'flex-start' },
      end: { justifyContent: 'flex-end' },
      'space-between': { justifyContent: 'space-between' }
    },
    wrap: {
      nowrap: { flexWrap: 'nowrap' },
      wrap: { flexWrap: 'wrap' },
      'wrap-reverse': { flexWrap: 'wrap-reverse' }
    },
    shrink: {
      false: { flexShrink: 0 },
      true: { flexShrink: 1 }
    }
  }
});

export { Flex };
