import { createFocusTrap } from 'focus-trap';
import { useEffect, useRef, useState } from 'react';

import { IconButton } from '~/components/button';
import { ErrorBoundary } from '~/components/error-boundary';
import { LoadingMessage } from '~/components/loading-spinner/LoadingMessage';
import { PlanSticker } from '~/features/billing/components/PlanSticker';
import { useAnalyticsOnMount } from '~/utils/analytics/useAnalyticsOnMount';
import { styled, truncate } from '~/utils/styling';

import type { ModalPassthroughProps } from './ModalContext';
import type { ReactNode, ComponentProps } from 'react';

import { Flex } from '../box';

const Outer = styled('div', {
  position: 'relative',
  display: 'flex',
  maxHeight: '100%',
  width: '100%'
});

const Container = styled('div', {
  $$modalPadding: '$space$large',
  $$modalBackground: '$colors$light-1000',

  width: '100%',
  margin: '0 auto',
  background: '$$modalBackground',
  position: 'relative',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$medium',
  overflow: 'hidden',

  variants: {
    width: {
      small: {
        maxWidth: '20rem'
      },
      medium: {
        maxWidth: '30rem'
      },
      large: {
        maxWidth: '40rem'
      },
      huge: {
        maxWidth: '60rem'
      }
    }
  },

  defaultVariants: {
    width: 'medium'
  }
});

const Header = styled('header', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$medium $$modalPadding',
  gap: '$small',
  borderBottomStyle: 'solid',
  borderBottomWidth: '$thin',
  borderBottomColor: '$dark-80',
  width: '100%'
});

const Title = styled('h1', {
  fontSize: '$large',
  fontWeight: '$bold',
  padding: '.1rem 0',
  ...truncate({ lines: 1 })
});

const Inner = styled('div', {
  overflow: 'auto',
  position: 'relative',
  width: '100%'
});

const Main = styled('main', {
  width: '100%',

  variants: {
    padded: {
      true: {
        padding: '$$modalPadding $$modalPadding $wee'
      },
      false: {
        padding: 0
      }
    },
    paddedBottom: {
      true: {
        paddingBottom: '$$modalPadding'
      }
    }
  },

  defaultVariants: {
    padded: true
  }
});

const Footer = styled('footer', {
  padding: '$$modalPadding',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$tiny',
  background: '$$modalBackground',
  position: 'sticky',
  bottom: 0,
  zIndex: 10,

  '& > *': {
    width: '100%'
  },

  '@bp-small': {
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '& > *': {
      width: 'auto'
    }
  },

  variants: {
    showShadow: {
      true: {
        boxShadow: '$medium'
      }
    }
  }
});

const WrapLoading = styled('div', {
  width: '100%',
  minHeight: '9rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

type ModalProps = ComponentProps<typeof Container> & {
  /**
   * Unique identifier for this modal, used e.g. for analytics events
   */
  id: string;
  title?: ReactNode;
  actions?: ReactNode;
  banner?: ReactNode;
  sticker?: ReactNode;
  onClose?: ModalPassthroughProps['close'];
  loading?: boolean;
  width?: ComponentProps<typeof Container>['width'];
  padded?: boolean;
  plan?: 'starter' | 'teams';
  autoFocus?: boolean;
};

function Modal({
  id,
  title,
  actions,
  banner,
  sticker,
  children,
  onClose,
  loading,
  width,
  padded = true,
  plan,
  autoFocus = true,
  ...props
}: ModalProps) {
  const innerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // NOTE: this is the equivalent to the standard `page_view` navigation event
  useAnalyticsOnMount('modal_viewed', {
    type: id,
    // TODO: ideal future event attributes
    modal_id: id
  });

  useEffect(() => {
    if (containerRef.current) {
      const trap = createFocusTrap(containerRef.current, {
        // Outside click should be allowed as clicks only happens on the Modal backdrop and avoid
        // blocking shadow DOM elements
        allowOutsideClick: true,
        fallbackFocus: containerRef.current,
        initialFocus: containerRef.current
      });
      trap.activate();
      return () => {
        trap.deactivate();
      };
    }
  }, []);

  // Auto focus the first input element or button if available
  useEffect(() => {
    if (autoFocus && !loading) {
      const selector = '[contentEditable], input, textarea, button, [tabindex="0"]';
      const firstFocusable = innerRef?.current?.querySelector<any>(selector);
      firstFocusable?.focus?.();
    }
  }, [loading, autoFocus]);

  // Close modal when user presses `escape`
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.();
      }
    };
    window.document.addEventListener('keydown', handleKeydown);
    return () => window.document.removeEventListener('keydown', handleKeydown);
  }, [onClose]);

  // Show/hide shadow on footer based on scroll position
  const [showShadow, setShowShadow] = useState(true);
  useEffect(() => {
    const scrollContainer = innerRef.current;
    if (scrollContainer && typeof window !== 'undefined' && 'ResizeObserver' in window) {
      const handleScroll = () => {
        setShowShadow(
          Math.ceil(scrollContainer.scrollTop) + Math.ceil(scrollContainer.clientHeight) <
            Math.floor(scrollContainer.scrollHeight)
        );
      };

      handleScroll();

      const observer = new ResizeObserver(handleScroll);

      scrollContainer?.addEventListener('scroll', handleScroll);
      observer?.observe(scrollContainer);
      return () => {
        scrollContainer?.removeEventListener('scroll', handleScroll);
        observer?.unobserve(scrollContainer);
      };
    }
  }, []);

  return (
    <Outer data-tether-target ref={containerRef}>
      <Container {...props} id={id} width={width}>
        {(title || onClose) && (
          <Header>
            <Flex alignItems="center" gap="small">
              <Title>{title}</Title>
              {plan && <PlanSticker type={plan} />}
              {sticker}
            </Flex>
            <IconButton color="grey" icon="cross" aria-label="Close modal" onClick={onClose} />
          </Header>
        )}
        <Inner ref={innerRef}>
          <ErrorBoundary>
            {loading ? (
              <WrapLoading>
                <LoadingMessage message="Loading data..." />
              </WrapLoading>
            ) : (
              <>
                {banner}

                <Main padded={padded} paddedBottom={padded && !actions}>
                  {children}
                </Main>

                {actions && (
                  <Footer ref={footerRef} showShadow={showShadow}>
                    {actions}
                  </Footer>
                )}
              </>
            )}
          </ErrorBoundary>
        </Inner>
      </Container>
    </Outer>
  );
}

export { Modal };
