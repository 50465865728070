import { event } from '~/utils/analytics';
import { getScoreForValue } from '~/utils/analytics/getPerformanceScore';

import type { NextWebVitalsMetric } from 'next/app';

// Send web vitals and custom Next metrices to Google Analytics
// See https://nextjs.org/docs/advanced-features/measuring-performance#sending-results-to-analytics
function reportWebVitals({ id, name, value }: NextWebVitalsMetric) {
  const score = getScoreForValue({ metricId: name, metricValue: value });
  event('web_vitals', {
    metric_id: id,
    metric_value: value,
    metric_name: name,
    metric_score: score
  });
}

export { reportWebVitals };
