import { useCallback } from 'react';

import { useErrorNotification } from '~/components/notification';
import { useAnalyticsEvent } from '~/utils/analytics';
import { useAuth } from '~/utils/auth';
import { useMutation } from '~/utils/graphql';
import { useRouter } from '~/utils/routing/useRouter';

import { SWITCH_ACCOUNT_MUTATION } from '~/queries/switch-account-mutation';

type Options = {
  path?: string;
};

function useSwitchAccount(options?: Options) {
  const auth = useAuth();
  const router = useRouter();
  const event = useAnalyticsEvent();
  const errorNotification = useErrorNotification();

  const [switchAccount] = useMutation(SWITCH_ACCOUNT_MUTATION);

  return useCallback(
    async ({ entityHash }: { entityHash: string }) => {
      try {
        const switchQuery = await switchAccount({ variables: { entityHash } });
        if (!switchQuery.data?.switchAccount?.success) {
          throw new Error('Failed to switch accounts');
        }

        await auth?.refresh({ refreshToken: true, clearCache: true });

        event('workspace_changed');

        // Only change the current url if we're on a details page (which won't be valid in the new
        // workspace) or if we explicitly pass in a new URL via the options
        const isDetailsPage = router.asPath.match(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/);
        if (options?.path || isDetailsPage) {
          await router.push({ pathname: options?.path || '/dashboard/home' });
        }
      } catch (e) {
        errorNotification.show(e);
      }
    },
    [switchAccount, event, errorNotification, auth, options, router]
  );
}

export { useSwitchAccount };
