import { lazy } from 'react';

import { useModal } from '~/components/modal';

const ShareQRCodeModal = lazy(() => import('~/features/share/modals/ShareQRCodeModal'));

function useShareQRCode() {
  const { open } = useModal(ShareQRCodeModal);
  return open;
}

export { useShareQRCode };
