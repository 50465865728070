import { useEffect, useRef } from 'react';

import { Icon } from '~/components/icon';
import { LoadingMessage } from '~/components/loading-spinner/LoadingMessage';
import { Menu, useMenuItems } from '~/components/popout';
import { P } from '~/components/text';
import { TextInput } from '~/components/text-input';
import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';
import type { Item } from '~/components/popout';

const Container = styled('div', {
  minWidth: '10rem',
  maxWidth: '20rem'
});

const Search = styled('div', {
  width: '100%',
  padding: '$wee',
  position: 'sticky',
  top: 0,
  background: '$light-1000'
});

const SearchInput = styled(TextInput, {
  border: '0 none',
  background: '$grey-100',
  paddingLeft: '2rem'
});

const SearchIcon = styled(Icon, {
  position: 'absolute',
  top: 'calc(50% - .7rem)',
  left: '$tiny',
  height: '1.4rem',
  width: 'auto',
  color: '$dark-600'
});

const Empty = styled(P, {
  padding: '$small $small $medium',
  textAlign: 'center'
});

type Items = Item[] | Promise<Item[]> | ((searchTerm: string | null) => Item[] | Promise<Item[]>);

type ActionMenuContentProps = {
  element: ComponentProps<typeof Menu>['element'];
  setVisible: ComponentProps<typeof Menu>['setVisible'];
  menuHeight?: ComponentProps<typeof Menu>['height'];
  items: Items;
  searchPlaceholder?: string;
  loading?: boolean;
  analyticsId?: string;
  onContentChange?: () => any;
};

function ActionMenuContent({
  element,
  setVisible,
  items: itemsProp,
  searchPlaceholder,
  menuHeight,
  loading: loadingProp,
  analyticsId,
  onContentChange
}: ActionMenuContentProps) {
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { items, loading, searchTerm, setSearchTerm, isSearchable } = useMenuItems({
    items: itemsProp,
    ref: searchRef
  });

  useEffect(() => {
    const input = inputRef?.current;
    if (input) {
      // NOTE: we delay the focus for one frame here, because it would otherwise
      // trigger the popout to immediately close again :/
      setTimeout(() => input.focus(), 0);
    }
  }, []);

  // HACK: we want to update the popper positioning every time the content changes
  useEffect(
    () => {
      onContentChange?.();
    },
    // eslint-disable-next-line
    [loading, items]
  );

  return (
    <Container>
      {isSearchable && (
        <Search ref={searchRef}>
          <SearchIcon name="search" />
          <SearchInput
            ref={inputRef}
            value={searchTerm || ''}
            onChange={(e: any) => setSearchTerm(e.target.value)}
            placeholder={searchPlaceholder}
          />
        </Search>
      )}

      {loading || loadingProp ? (
        <LoadingMessage message="Loading" />
      ) : !items.length ? (
        <Empty color="grey">No items found.</Empty>
      ) : (
        <Menu items={items} setVisible={setVisible} element={element} height={menuHeight} analyticsId={analyticsId} />
      )}
    </Container>
  );
}

export { ActionMenuContent };
export type { Items };
