import { authenticateVisitor } from '~/utils/visitor';

type LoginWithProviderArg = {
  provider: string;
  state?: any;
};

async function signInWithProvider({ provider, state = {} }: LoginWithProviderArg) {
  const { Auth } = await import('aws-amplify');
  // Typescript is screaming here because of the different types `federatedSignIn` has with different
  // function arguments, we only use `FederatedSignInOptions` here
  // eslint-disable-next-line
  // @ts-ignore
  const user = await Auth.federatedSignIn({ provider, customState: JSON.stringify(state) });
  authenticateVisitor();

  return user;
}

export { signInWithProvider };
