import { isMac } from '~/utils/storage/utils';
import { styled } from '~/utils/styling';

import type { ComponentProps, ReactNode } from 'react';

import { Icon } from '../icon';

const Container = styled('span', {
  display: 'flex',
  alignItems: 'center',
  gap: '$tiny'
});

const Label = styled('span', {
  fontSize: '$small',
  lineHeight: '$xs'
});

const KeyContainer = styled('span', {
  display: 'flex',
  gap: '$wee',
  alignItems: 'center'
});

const Key = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$wee',
  borderRadius: '$wee',
  background: '$light-400',
  fontSize: '$xs',
  lineHeight: '.5rem',
  fontFamily: '$fontFamilies$mono',

  '& svg': {
    width: 'auto',
    height: '.75rem'
  },

  variants: {
    icon: {
      true: {
        padding: '.125rem'
      }
    }
  }
});

type HotkeyProps = ComponentProps<typeof Container> & {
  meta?: boolean;
  shift?: boolean;
  hotkey: ReactNode;
};

function Hotkey({ hotkey, meta = false, shift = false, children, ...props }: HotkeyProps) {
  return (
    <Container {...props}>
      <Label>{children}</Label>
      <KeyContainer>
        {meta && (
          <>
            <Key icon>{isMac ? <Icon name="command" /> : <Icon name="control" />}</Key>
            <span>+</span>
          </>
        )}
        {shift && (
          <>
            <Key icon>
              <Icon name="shift" />
            </Key>
            <span>+</span>
          </>
        )}
        <Key icon={typeof hotkey !== 'string'}>{hotkey}</Key>
      </KeyContainer>
    </Container>
  );
}

export { Hotkey };
