type Arg = {
  url: string;
  title: string;
};

function getFacebookShareUrl({ url, title }: Arg) {
  if (typeof window === 'undefined') {
    return '#';
  }
  const encodedUrl = window.encodeURIComponent(url);
  const encodedTitle = window.encodeURIComponent(title);
  return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&p[title]=${encodedTitle}`;
}

function getLinkedInShareUrl({ url }: Arg) {
  if (typeof window === 'undefined') {
    return '#';
  }
  const encodedUrl = window.encodeURIComponent(url);
  return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
}

function getTwitterShareUrl({ url, title }: Arg) {
  if (typeof window === 'undefined') {
    return '#';
  }
  const encodedTitle = window.encodeURIComponent(title);
  const encodedUrl = window.encodeURIComponent(url);
  return `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}&hashtags=&via=VouchHQ`;
}

export { getFacebookShareUrl, getLinkedInShareUrl, getTwitterShareUrl };
