import { authenticateVisitor } from '~/utils/visitor';
type LoginArg = {
  email: string;
  password: string;
};

async function signIn({ email, password }: LoginArg) {
  const { Auth } = await import('aws-amplify');
  const user = await Auth.signIn(email, password);
  authenticateVisitor();

  return user;
}

export { signIn };
