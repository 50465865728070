import { Modal } from '~/components/modal/Modal';
import { Progress } from '~/components/progress';
import { Text } from '~/components/text';
import { styled } from '~/utils/styling';

import type { ComponentProps, ReactNode } from 'react';

const WrapProgress = styled('div', {
  width: '100%',
  marginRight: '$medium',
  display: 'flex',
  flexDirection: 'column'
});

type Props = ComponentProps<typeof Modal> & {
  showProgress: boolean;
  progress: number;
  progressText?: ReactNode;
  progressColor?: ComponentProps<typeof Progress>['color'];
};

function ProgressModal({ actions, showProgress, progress, progressText, progressColor = 'teal', ...props }: Props) {
  return (
    <Modal
      {...props}
      actions={
        <>
          {showProgress && (
            <WrapProgress>
              <Progress color={progressColor} value={progress} max={1} css={{ width: '100%' }} />
              {progressText && (
                <Text color="grey" size="small">
                  {progressText}
                </Text>
              )}
            </WrapProgress>
          )}
          {actions}
        </>
      }
    />
  );
}

export { ProgressModal };
