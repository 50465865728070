import { lazy } from 'react';

import { useModal } from '~/components/modal';

const UploadVideoModal = lazy(() => import('~/features/global/modals/UploadVideoModal'));

function useUploadVideo() {
  const { open } = useModal(UploadVideoModal);
  return open;
}

export { useUploadVideo };
