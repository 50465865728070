import { useCallback, useEffect, useRef, useState } from 'react';

import type { RefObject } from 'react';
import type { FieldPassthroughProps } from '~/components/form';

type UseMenuItemsArg = {
  items: any[] | Promise<any[]> | ((searchTerm: string | null) => any[] | Promise<any[]>);
  lazy?: boolean;
  setValue?: FieldPassthroughProps['setValue'];
  multi?: boolean;
  freeform?: boolean;
  ref?: RefObject<any>;
};

function useMenuItems({ items: itemsProp, lazy, setValue, multi, freeform, ref }: UseMenuItemsArg) {
  const isSearchable = typeof itemsProp === 'function';

  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [loading, setLoading] = useState('then' in itemsProp ? true : false);

  const initialItems = typeof itemsProp === 'function' ? itemsProp('') : itemsProp;
  const [items, setItems] = useState<any[]>(!initialItems || 'then' in initialItems ? [] : initialItems);

  const handleSearchTermChange = useCallback(
    (items: any[] = []) => {
      // If the search term was set through the browsers autofill, we want to make sure
      // we're actually selecting the corresponding item properly, or in case there is no
      // match that we clear the search field if it's not currently focused
      if (searchTerm) {
        const exactMatch = items.find((item: any) => item.text === searchTerm || item.label === searchTerm);
        if (exactMatch) {
          setValue?.(multi ? [exactMatch] : exactMatch);
        } else if (!items.length && window.document.activeElement !== ref?.current?.querySelector('input')) {
          // TODO: handle freeform properly as well
          if (!freeform) {
            setSearchTerm('');
            setValue?.(multi ? [] : null);
          }
        }
      }
    },
    [searchTerm, setValue, multi, ref, freeform]
  );

  // If the passed in `items` is a promise, we just want to resolve it here and
  // use the result to set the final items otherwise we want to sync the new
  // items from props into our items state
  useEffect(() => {
    if ('then' in itemsProp) {
      itemsProp
        .then((items: any) => {
          setItems(items);
          setLoading(false);
        })
        .catch((e: any) => {
          console.error(e);
          setLoading(false);
        });
    } else {
      // The case where itemsProp is a function is handled by the effect below
      if (typeof itemsProp !== 'function') {
        setItems(itemsProp);
      }
    }
  }, [itemsProp]);

  // If the passed in `items` is a function, we want to execute it (potentially lazily)
  // whenever the search term changes
  const firstRender = useRef(true);
  useEffect(() => {
    const isFirstRender = firstRender.current;
    firstRender.current = false;

    if (isFirstRender && lazy) {
      return;
    }

    if (typeof itemsProp === 'function') {
      const loaded = itemsProp(searchTerm);
      if ('then' in loaded) {
        setLoading(true);
        loaded
          .then((items: any) => {
            setItems(items);
            handleSearchTermChange(items);
            setLoading(false);
          })
          .catch((e: any) => {
            console.error(e);
            setLoading(false);
          });
      } else {
        setItems(loaded);
        handleSearchTermChange(loaded);
      }
    }
  }, [lazy, searchTerm, itemsProp, handleSearchTermChange]);

  return { items, loading, setLoading, searchTerm, setSearchTerm, isSearchable };
}

export { useMenuItems };
