import { useMemo } from 'react';

import { useShareEmailEmbed } from '~/features/share/actions/useShareEmailEmbed';
import { useShareEmbedCode } from '~/features/share/actions/useShareEmbedCode';
import { useShareQRCode } from '~/features/share/actions/useShareQRCode';

function useShareActions() {
  const shareQRCode = useShareQRCode();
  const shareEmbedCode = useShareEmbedCode();
  const shareEmailEmbed = useShareEmailEmbed();

  return useMemo(
    () => ({
      shareQRCode,
      shareEmbedCode,
      shareEmailEmbed
    }),
    [shareQRCode, shareEmbedCode, shareEmailEmbed]
  );
}

export { useShareActions };
