import { lazy } from 'react';

import { useModal } from '~/components/modal';

const CreatePlaylistModal = lazy(() => import('~/features/playlists/modals/CreatePlaylistModal'));

function useCreatePlaylist() {
  const { open } = useModal(CreatePlaylistModal);
  return open;
}

export { useCreatePlaylist };
