import { styled } from '~/utils/styling';

import { createSpacingVariant } from './Box';

const Spacer = styled('div', {
  width: '.1rem',
  height: '.1rem',
  display: 'flex',
  flexShrink: 0,

  variants: {
    w: createSpacingVariant('width'),
    h: createSpacingVariant('height')
  }
});

export { Spacer };
