import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import jsonStringify from 'safe-json-stringify';

import { useAnalyticsEvent } from '~/utils/analytics';
import { usePersistedState } from '~/utils/state';

import type { PropsWithChildren } from 'react';
import type { UseFormReturn, UseFormProps, SubmitHandler, FieldValues } from 'react-hook-form';

type CacheSettings = {
  version: number;
  omitKeys?: string[];
};

type UseFormArg<Values extends FieldValues> = UseFormProps<Values> & {
  onSubmit: SubmitHandler<Values>;
  id: string;
  cache?: CacheSettings;
  loading?: boolean;
};

type Return = UseFormReturn<any> & {
  id: string;
  submit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  loading: boolean;
};

function useForm<Values extends FieldValues>({
  onSubmit,
  id,
  cache,
  defaultValues,
  ...options
}: UseFormArg<Values>): Return {
  const event = useAnalyticsEvent();

  const [persistedState, setPersistedState] = usePersistedState<any>({
    // This is a bit naughty, we don't have a way to bypass the persisted state logic conditionally
    // atm, so I'm just falling back to a `noop` dummy localstorage entry in case the form is not
    // supposed to be persisted
    key: cache?.version ? `form/${id}/values` : `noop`,
    version: cache?.version,
    fallback: {}
  });

  const cleanPersistedState = Object.keys(persistedState).reduce<any>((all, key) => {
    if (!cache?.omitKeys?.includes(key)) {
      all[key] = persistedState[key];
    }
    return all;
  }, {});

  const mergedDefaultValues = useMemo(() => {
    return cache?.version ? { ...defaultValues, ...cleanPersistedState } : defaultValues;
  }, [cache?.version, cleanPersistedState, defaultValues]);

  const form = useHookForm<Values>({
    ...options,
    defaultValues: mergedDefaultValues
  });

  const values = form.watch();
  const cacheKey = cache?.version ? jsonStringify(values) : false;
  useEffect(
    () => {
      if (cache?.version) {
        setPersistedState(values);
      }
    },
    // `values` seems to be changing reference on re-renders :|
    // eslint-disable-next-line
    [cache?.version, cacheKey, setPersistedState]
  );

  // We use the `loading` property to tell the form to reset once any async default values
  // have been loaded
  const [loading, setLoading] = useState(!!options.loading);
  useEffect(() => {
    if (loading && !options.loading) {
      form.reset(mergedDefaultValues as Values);
      setLoading(false);
    }
  }, [options.loading, mergedDefaultValues, form, loading]);

  const handleSubmit = useCallback(
    (data: Values, e: any) => {
      if (id) {
        event('form_submitted', { type: id, form_id: id });
      }
      return onSubmit?.(data, e);
    },
    [event, id, onSubmit]
  );

  return useMemo(
    () => ({ submit: form.handleSubmit(handleSubmit), id, loading, ...form }),
    [id, form, loading, handleSubmit]
  );
}

type FormStateContextValue = null | UseFormReturn;

const FormStateContext = createContext<FormStateContextValue>(null);

type FormStateContextProviderProps = PropsWithChildren<{
  form: FormStateContextValue;
}>;

function FormStateProvider({ form, children }: FormStateContextProviderProps) {
  return <FormStateContext.Provider value={form}>{children}</FormStateContext.Provider>;
}

function useFormState<Values extends FieldValues>() {
  return useContext(FormStateContext) as UseFormReturn<Values>;
}

export { useForm, FormStateProvider, useFormState };
