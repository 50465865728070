import { lazy } from 'react';

import { useModal } from '~/components/modal';

const TeamSignupLinkModal = lazy(() => import('~/features/team/modals/TeamSignupLinkModal'));

function useTeamSignupLink() {
  const { open } = useModal(TeamSignupLinkModal);
  return (props: Parameters<typeof open>[0] = {}) => {
    open(props);
  };
}

export { useTeamSignupLink };
