import { forwardRef, useEffect, useRef } from 'react';
import mergeRefs from 'react-merge-refs';

import { styled } from '~/utils/styling';

import type { ForwardedRef, ComponentProps } from 'react';
import type { FieldPassthroughProps } from '~/components/form/Field';

const TextArea = styled('textarea', {
  width: '100%',
  padding: '.6rem $small',
  borderRadius: '$tiny',
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: '$dark-200',
  background: '$light-1000',
  minHeight: '$medium',

  '&[disabled], fieldset[disabled] &': {
    background: '$dark-80',
    color: '$dark-400'
  },

  variants: {
    error: {
      true: {
        borderColor: '$s-danger-500',
        background: '$s-danger-100'
      }
    },
    resize: {
      both: { resize: 'both' },
      horizontal: { resize: 'horizontal' },
      none: { resize: 'none' }
    }
  },

  defaultVariants: {
    resize: 'horizontal'
  }
});

type TextAreaInputProps = Omit<ComponentProps<typeof TextArea>, 'error'> &
  FieldPassthroughProps & {
    autoSize?: boolean;
  };

export const TextAreaInput = forwardRef(function TextAreaInput(
  { autoSize, ...props }: TextAreaInputProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  // Clean up props before spreading onto input
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { getValue, setValue, error, resize, setError, clearError, ...rest } = props;

  // Auto size functionality
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    const ref = textAreaRef.current;

    if (ref && autoSize) {
      const handleInput = () => {
        ref.style.height = 'auto';
        ref.style.height = `${ref?.scrollHeight}px`;
      };

      const handleResize = () => {
        requestAnimationFrame(() => {
          handleInput();
        });
      };

      handleResize();

      ref.addEventListener('input', handleInput, false);
      window.addEventListener('resize', handleResize, false);

      return () => {
        ref.removeEventListener('input', handleInput);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [autoSize]);

  return (
    <TextArea
      ref={mergeRefs([textAreaRef, ref]) as any}
      error={!!error}
      resize={autoSize ? 'none' : resize}
      {...rest}
    />
  );
});
