import { styled } from '~/utils/styling';

import type { ReactNode } from 'react';

const ShowOnMobileHideOnDesktop = styled('div', {
  display: 'inherit',
  width: '100%',

  '@bp-medium': {
    display: 'none'
  }
});

const HideOnMobileShowOnDesktop = styled('div', {
  display: 'none',
  justifyContent: 'inherit',
  width: '100%',

  '@bp-medium': {
    display: 'inherit'
  }
});

type ResponsivePropsBoth = {
  desktop: ReactNode;
  mobile: ReactNode;
};

type ResponsivePropsDesktop = {
  desktop: ReactNode;
  mobile?: ReactNode;
};

type ResponsivePropsMobile = {
  desktop?: ReactNode;
  mobile: ReactNode;
};

type ResponsiveProps = ResponsivePropsBoth | ResponsivePropsDesktop | ResponsivePropsMobile;

const Responsive = ({ desktop, mobile, ...rest }: ResponsiveProps & { as?: string }) =>
  desktop && mobile ? (
    <>
      <HideOnMobileShowOnDesktop {...rest}>{desktop}</HideOnMobileShowOnDesktop>
      <ShowOnMobileHideOnDesktop {...rest}>{mobile}</ShowOnMobileHideOnDesktop>
    </>
  ) : desktop ? (
    <HideOnMobileShowOnDesktop {...rest}>{desktop}</HideOnMobileShowOnDesktop>
  ) : mobile ? (
    <ShowOnMobileHideOnDesktop {...rest}>{mobile}</ShowOnMobileHideOnDesktop>
  ) : null;

export { Responsive };
