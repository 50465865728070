import { useCallback } from 'react';

import { useShareActions } from '~/features/share/actions';
import { useAnalyticsEvent } from '~/utils/analytics';
import { getFacebookShareUrl, getLinkedInShareUrl, getTwitterShareUrl } from '~/utils/share';
import { getAppUrl } from '~/utils/urls';

import type { ShareItems } from '~/features/share/types';

// To access features on the public recorder
// For details, see: https://help.vouchfor.com/en/articles/6587839-pre-populate-user-details-for-a-campaign-request
type Query = {
  callback?: string;
  name?: string;
  email?: string;
  company?: string;
  url?: string;
};

function useGetShareItems() {
  const event = useAnalyticsEvent();
  const shareActions = useShareActions();

  return useCallback(
    ({ data, query }: { data: any; query?: Query }) => {
      const baseUrl = data?.settings?.urls?.request
        ? getAppUrl(data?.settings?.urls?.request)
        : `${process.env.NEXT_PUBLIC_APP_URL}/c/${data?.hashId}`;

      const url = query ? `${baseUrl}?${new URLSearchParams(query).toString()}` : baseUrl;

      const title = `We’d love to hear what you think!`;

      const twitterUrl = getTwitterShareUrl({ url, title });
      const facebookUrl = getFacebookShareUrl({ url, title });
      const linkedInUrl = getLinkedInShareUrl({ url, title });

      const canShare = navigator?.canShare?.({ url, title });

      return {
        url,
        title,
        qrCode: {
          onClick: () => {
            event('request_shared', { campaign_id: data?.id, medium: 'QR Code', vouch_id: null, type: null });
            shareActions.shareQRCode({ url });
          }
        },
        otherApps: {
          onClick: canShare
            ? () => {
                event('request_shared', { campaign_id: data?.id, medium: 'Native apps', vouch_id: null, type: null });
                navigator?.share?.({ title, url }).catch(() => {
                  // Do nothing, `navigator.share` throws when the user cancels
                });
              }
            : undefined
        },
        twitter: {
          onClick: () => {
            event('request_shared', { campaign_id: data?.id, medium: 'Twitter', vouch_id: null, type: null });
            window.open(twitterUrl);
          }
        },
        facebook: {
          onClick: () => {
            event('request_shared', { campaign_id: data?.id, medium: 'Facebook', vouch_id: null, type: null });
            window.open(facebookUrl);
          }
        },
        linkedIn: {
          onClick: () => {
            event('request_shared', { campaign_id: data?.id, medium: 'LinkedIn', vouch_id: null, type: null });
            window.open(linkedInUrl);
          }
        },
        urlShare: () => {
          event('request_shared', { campaign_id: data?.id, medium: 'Url', vouch_id: null, type: null });
        }
      } as ShareItems;
    },
    [event, shareActions]
  );
}

export { useGetShareItems };
