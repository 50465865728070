import maisonNeueMonoEotPath from '~/assets/fonts/MaisonNeue/Mono.eot';
import maisonNeueMonoWoffPath from '~/assets/fonts/MaisonNeue/Mono.woff';
import maisonNeueMonoWoff2Path from '~/assets/fonts/MaisonNeue/Mono.woff2';
import roobertRegularEotPath from '~/assets/fonts/Roobert/Regular.eot';
import roobertRegularWoffPath from '~/assets/fonts/Roobert/Regular.woff';
import roobertRegularWoff2Path from '~/assets/fonts/Roobert/Regular.woff2';
import roobertSemiBoldEotPath from '~/assets/fonts/Roobert/SemiBold.eot';
import roobertSemiBoldWoffPath from '~/assets/fonts/Roobert/SemiBold.woff';
import roobertSemiBoldWoff2Path from '~/assets/fonts/Roobert/SemiBold.woff2';

const fonts = {
  '@font-face': [
    {
      fontFamily: 'Roobert',
      src:
        `url('${roobertRegularEotPath}'), ` +
        `url('${roobertRegularEotPath}?#iefix') format('embedded-opentype'), ` +
        `url('${roobertRegularWoff2Path}') format('woff2'), ` +
        `url('${roobertRegularWoffPath}') format('woff')`,
      fontWeight: 400,
      fontStyle: 'normal',
      fontDisplay: 'swap' as const
    },
    {
      fontFamily: 'Roobert',
      src:
        `url('${roobertSemiBoldEotPath}'), ` +
        `url('${roobertSemiBoldEotPath}?#iefix') format('embedded-opentype'), ` +
        `url('${roobertSemiBoldWoff2Path}') format('woff2'), ` +
        `url('${roobertSemiBoldWoffPath}') format('woff')`,
      fontWeight: 600,
      fontStyle: 'normal',
      fontDisplay: 'swap' as const
    },
    {
      fontFamily: 'Maison Neue Mono',
      src:
        `url('${maisonNeueMonoEotPath}'), ` +
        `url('${maisonNeueMonoEotPath}?#iefix') format('embedded-opentype'), ` +
        `url('${maisonNeueMonoWoff2Path}') format('woff2'), ` +
        `url('${maisonNeueMonoWoffPath}') format('woff')`,
      fontWeight: 400,
      fontStyle: 'normal',
      fontDisplay: 'swap' as const
    }
  ]
};

export { fonts };
