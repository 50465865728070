import { lazy } from 'react';

import { useModal } from '~/components/modal';

const InviteMembersModal = lazy(() => import('~/features/team/modals/InviteMembersModal'));

function useInviteMembers() {
  const { open } = useModal(InviteMembersModal);
  return (props: Parameters<typeof open>[0] = {}) => {
    open(props);
  };
}

export { useInviteMembers };
