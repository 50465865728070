import { createStitches, defaultThemeMap } from '@stitches/react';

const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  prefix: 'glimpse',

  theme: {
    colors: {
      // Semantic colors
      's-brand-50': '$colors$teal-50',
      's-brand-100': '$colors$teal-100',
      's-brand-200': '$colors$teal-200',
      's-brand-300': '$colors$teal-300',
      's-brand-400': '$colors$teal-400',
      's-brand-500': '$colors$teal-500',
      's-brand-600': '$colors$teal-600',
      's-brand-700': '$colors$teal-700',
      's-brand-800': '$colors$teal-800',
      's-brand-900': '$colors$teal-900',
      's-brand-950': '$colors$teal-950',

      's-danger-50': '$colors$red-50',
      's-danger-100': '$colors$red-100',
      's-danger-200': '$colors$red-200',
      's-danger-300': '$colors$red-300',
      's-danger-400': '$colors$red-400',
      's-danger-500': '$colors$red-500',
      's-danger-600': '$colors$red-600',
      's-danger-700': '$colors$red-700',
      's-danger-800': '$colors$red-800',
      's-danger-900': '$colors$red-900',

      // Core color palette
      'red-50': '#fff0f1',
      'red-100': '#ffbbbe',
      'red-200': '#ff9b9f',
      'red-300': '#ff767e',
      'red-400': '#f55461',
      'red-500': '#de384c',
      'red-600': '#c51c3a',
      'red-700': '#a70226',
      'red-800': '#860010',
      'red-900': '#650000',

      'teal-50': '#f3f7f8',
      'teal-100': '#e0eaec',
      'teal-200': '#accacd',
      'teal-300': '#8eb6ba',
      'teal-400': '#73a2a7',
      'teal-500': '#5a8d93',
      'teal-600': '#45797f',
      'teal-700': '#34656b',
      'teal-800': '#275156',
      'teal-900': '#1f3d42',
      'teal-950': '#0d2126',

      'purple-50': '#f4f1ff',
      'purple-100': '#dfd4f0',
      'purple-200': '#cbb8e1',
      'purple-300': '#b69dd3',
      'purple-400': '#a182c4',
      'purple-500': '#8c67b5',
      'purple-600': '#774da6',
      'purple-700': '#613398',
      'purple-800': '#491789',
      'purple-900': '#2b006e',

      'blue-50': '#e6f1ff',
      'blue-100': '#cbe3ff',
      'blue-200': '#a2cdff',
      'blue-300': '#71b8ff',
      'blue-400': '#509ced',
      'blue-500': '#2984d2',
      'blue-600': '#056bb6',
      'blue-700': '#005199',
      'blue-800': '#00397c',
      'blue-900': '#00225f',

      'yellow-100': '#f8e08e',
      'yellow-200': '#fdd26c',
      'yellow-300': '#ffc551',
      'yellow-400': '#ffb73c',
      'yellow-500': '#ffa92a',
      'yellow-600': '#ff9a1b',

      'grey-100': '#fafafa',

      'darkgrey-100': '#858585',

      'light-100': 'rgba(255, 255, 255, .1)',
      'light-200': 'rgba(255, 255, 255, .2)',
      'light-300': 'rgba(255, 255, 255, .3)',
      'light-400': 'rgba(255, 255, 255, .4)',
      'light-500': 'rgba(255, 255, 255, .5)',
      'light-600': 'rgba(255, 255, 255, .6)',
      'light-700': 'rgba(255, 255, 255, .7)',
      'light-800': 'rgba(255, 255, 255, .8)',
      'light-900': 'rgba(255, 255, 255, .9)',
      'light-1000': 'rgba(255, 255, 255, 1)',

      'dark-80': 'rgba(0, 0, 0, .08)',
      'dark-100': 'rgba(0, 0, 0, .1)',
      'dark-200': 'rgba(0, 0, 0, .2)',
      'dark-300': 'rgba(0, 0, 0, .3)',
      'dark-400': 'rgba(0, 0, 0, .4)',
      'dark-500': 'rgba(0, 0, 0, .5)',
      'dark-600': 'rgba(0, 0, 0, .6)',
      'dark-700': 'rgba(0, 0, 0, .7)',
      'dark-800': 'rgba(0, 0, 0, .8)',
      'dark-900': 'rgba(0, 0, 0, .9)',
      'dark-1000': 'rgba(0, 0, 0, 1)'
    },

    // Spacing & sizes
    space: {
      wee: '.25rem',
      tiny: '.5rem',
      small: '.75rem',
      medium: '1rem',
      large: '1.5rem',
      huge: '2.5rem'
    },

    sizes: {
      wee: '1rem',
      tiny: '1.5rem',
      small: '2rem',
      medium: '2.5rem',
      large: '3rem'
    },

    // Typography
    // NOTE: the typography tokens are diverging from out general token naming convention,
    // since they are not meant to be used directly, instead we should be using the typography
    // helpers for `text` and `heading` which will return the right combination of style
    // attribute groups (e.g. font size, color, line height, etc)
    fontSizes: {
      xs: '.625rem',
      small: '.75rem',
      medium: '.875rem',
      large: '1rem',
      xl: '1.25rem',
      xxl: '1.5rem',
      xxxl: '2rem',
      '4xl': '2.75rem',
      '5xl': '4rem'
    },

    lineHeights: {
      xs: '.75rem',
      small: '1rem',
      medium: '1.125rem',
      large: '1.25rem',
      xl: '1.625rem',
      xxl: '2rem',
      xxxl: '2.5rem',
      '4xl': '3rem',
      '5xl': '4.25rem'
    },

    letterSpacings: {
      xxxs: '-.04rem',
      xxs: '-.03rem',
      xs: '-.02rem',
      small: '-.01rem',
      medium: 0,
      large: '.02rem'
    },

    fontWeights: {
      regular: 400,
      bold: 700
    },

    fontFamilies: {
      sans:
        `Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,` +
        `"Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
      mono: `"Maison Neue Mono", Menlo, Monaco, Consolas, Liberation Mono, "Courier New", monospace`
    },

    // Borders
    borderWidths: {
      thin: '.0625rem'
    },

    radii: {
      wee: '.125rem',
      tiny: '.25rem',
      small: '.5rem',
      medium: '.75rem',
      large: '1rem',
      huge: '1.5rem'
    },

    // Shadows
    shadows: {
      none: '0 0 0 rgba(0, 0, 0, 0)',
      small: '0 0 .25rem rgba(0, 0, 0, 0.1)',
      medium: '0 0 .5rem rgba(0, 0, 0, 0.1)'
    },

    // Animations
    timings: {
      fast: '.1s',
      normal: '.2s',
      slow: '.3s'
    },

    easings: {
      linear: 'linear'
    },

    transitions: {
      'fade-in-out': 'opacity $timings$normal',
      'slide-in-out': 'transform $timings$normal',
      'box-shadow': 'box-shadow $timings$normal'
    },

    // Focus outline helper
    focusOutline: {
      width: '.2rem',
      color: '$colors$dark-400'
    }
  },

  media: {
    'bp-tiny': '(min-width: 340px)',
    'bp-small': '(min-width: 600px)',
    'bp-medium': '(min-width: 905px)',
    'bp-large': '(min-width: 1240px)',
    'bp-huge': '(min-width: 1440px)',

    'reduced-motion': '(prefers-reduced-motion: reduce)',
    'is-touch-device': '(hover: none)'
  },

  themeMap: {
    ...defaultThemeMap
  }
});

export { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config };
