import { gql } from '~/utils/codegen';

export const GET_ENTITY_BY_HASH_QUERY = gql(/* GraphQL */ `
  query GetEntityByHash($hashId: String!) {
    entityByHash(hashId: $hashId) {
      id
      hashId
      name
      logoSrc
    }
  }
`);
