import copy from 'copy-to-clipboard';
import { useCallback } from 'react';

import { useErrorNotification, useNotification } from '~/components/notification';
import { useAnalyticsEvent } from '~/utils/analytics';
import { getAppUrl } from '~/utils/urls';

type UseCopyShareLinkArgs = {
  id: string;
  url: string;
  successMessage?: string;
};

function useCopyShareLink() {
  const event = useAnalyticsEvent();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  return useCallback(
    ({ id, url, successMessage }: UseCopyShareLinkArgs) => {
      try {
        copy(getAppUrl(url), { format: 'text/plain' });
        event('request_link_copied', { campaign_id: id, vouch_id: null, client_name: null });
        notification.show({ message: successMessage ?? 'Copied Playlink to clipboard' });
      } catch (e: any) {
        errorNotification.show(new Error(e.message || 'Copying to clipboard failed'));
      }
    },
    [event, notification, errorNotification]
  );
}

export { useCopyShareLink };
