import { forwardRef } from 'react';

import type { ComponentProps } from 'react';

import { Text } from './Text';

type PProps = Omit<ComponentProps<typeof Text>, 'ref'>;

const P = forwardRef<HTMLParagraphElement, PProps>(function P(props, ref) {
  return <Text ref={ref} as="p" {...props} />;
});

export { P };
