import { Button } from '~/components/button';
import { Field, Fieldset, Form, useForm } from '~/components/form';
import { Modal } from '~/components/modal';
import { useErrorNotification, useNotification } from '~/components/notification';
import { TextAreaInput } from '~/components/text-input';
import awsExports from '~/config/aws-exports';
import { useAuth } from '~/utils/auth';

import type { ModalPassthroughProps } from '~/components/modal';

function TeamsEnquiryModal({ close }: ModalPassthroughProps) {
  const auth = useAuth();

  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const publicApi = awsExports?.aws_cloud_logic_custom?.find?.((apis) => apis?.name === 'vouchpublicapi');
  const baseUrl = publicApi?.endpoint ? `${publicApi?.endpoint}/api` : undefined;

  const form = useForm({
    id: 'teams-enquiry-form',
    onSubmit: async (values) => {
      try {
        await fetch(`${baseUrl}/teamsenquiry`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({ externalId: auth?.account?.id, note: values.note })
        });
        notification.show({ message: 'Your Teams enquiry has been submitted' });
        close?.();
      } catch (e) {
        errorNotification.show(e);
      }
    }
  });

  return (
    <Modal
      id="teams-enquiry-modal"
      title="Teams enquiry"
      onClose={close}
      actions={
        <Button color="brand" form={form.id} loading={form.formState.isSubmitting}>
          Submit
        </Button>
      }
    >
      <Form form={form}>
        <Fieldset>
          <Field
            name="note"
            label="Leave us a note and our team will get back to you"
            Input={TextAreaInput}
            inputProps={{ autoSize: true }}
            required
          />
        </Fieldset>
      </Form>
    </Modal>
  );
}

export default TeamsEnquiryModal;
