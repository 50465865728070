import { marker, styled } from '~/utils/styling';

const Marker = styled('span', {
  ...marker(),

  variants: {
    color: {
      grey: {
        color: '$dark-600'
      }
    },
    truncate: {
      true: {
        minWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    }
  }
});

export { Marker };
