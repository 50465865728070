import { styled, textVariants, truncate } from '~/utils/styling';

const Text = styled('span', {
  variants: {
    size: textVariants,
    weight: {
      bold: {
        fontWeight: '$bold'
      }
    },
    color: {
      grey: {
        color: '$dark-600'
      },
      brand: {
        color: '$s-brand-800'
      }
    },
    truncate: {
      true: {
        ...truncate({ lines: 1 })
      }
    }
  },

  defaultVariants: {
    size: 'medium'
  }
});

export { Text };
