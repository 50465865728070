import { v4 as uuidv4 } from 'uuid';

import { getVisitor } from '~/utils/visitor';

let cacheClientId: string | undefined = undefined;
let cacheTabId: string | undefined = undefined;
let cachePageloadId: string | undefined = undefined;

function getUids() {
  if (typeof window === 'undefined') {
    return {
      client: null,
      tab: null,
      request: uuidv4()
    };
  }

  // Persisted in the backend to attempt to link users across multiple browsers and devices
  const visitorId = getVisitor();

  // Persisted for a user for the same device + browser, so we can e.g. search for all logs related to that browser
  const clientId = cacheClientId || window.localStorage?.getItem?.('vouch-uid-client') || uuidv4();

  // Persisted in session storage, so we can search for everything the user has done in a specific tab
  const tabId = cacheTabId || window.sessionStorage?.getItem?.('vouch-uid-tab') || uuidv4();

  // Unique ID only persisted in memory for the current page load
  const pageloadId = cachePageloadId || uuidv4();

  // No persisted, allows us to search for any logs related to a single FE request
  // E.g. BE should pass this request ID through all other services to be able to group logs
  const requestId = uuidv4();

  // Cache and persist uids
  if (clientId !== cacheClientId) {
    cacheClientId = clientId;
    window.localStorage?.setItem?.('vouch-uid-client', clientId);
  }

  if (tabId !== cacheTabId) {
    cacheTabId = tabId;
    window.sessionStorage?.setItem?.('vouch-uid-tab', tabId);
  }

  if (pageloadId !== cachePageloadId) {
    cachePageloadId = pageloadId;
  }

  return {
    client: clientId,
    tab: tabId,
    pageload: pageloadId,
    request: requestId,
    visitor: visitorId
  };
}

export { getUids };
