import { useMemo } from 'react';

import { useContactSales } from './useContactSales';
import { useContactUs } from './useContactUs';
import { useCreateRecording } from './useCreateRecording';
import { useSwitchAccount } from './useSwitchAccount';
import { useUploadVideo } from './useUploadVideo';

function useGlobalActions() {
  const switchAccount = useSwitchAccount();
  const uploadVideo = useUploadVideo();
  const createRecording = useCreateRecording();
  const contactUs = useContactUs();
  const contactSales = useContactSales();

  return useMemo(
    () => ({
      switchAccount,
      uploadVideo,
      createRecording,
      contactUs,
      contactSales
    }),
    [createRecording, switchAccount, uploadVideo, contactUs, contactSales]
  );
}

export { useGlobalActions };
