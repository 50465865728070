import { useCallback, useContext } from 'react';

import type { ModalPassthroughProps } from './ModalContext';
import type { ComponentPropsWithoutRef, ElementType } from 'react';

import { ModalContext } from './ModalContext';

type UseModalOptions = {
  onClose?: () => void | Promise<void>;
};

let uid = 0;

function useModal<T extends ElementType>(Component: T, options: UseModalOptions = {}) {
  const { addModal } = useContext(ModalContext);

  const open = useCallback(
    // We're inferring the props from the passed in component and are omitting
    // the modal props that are passed through by the provider
    (props?: Omit<ComponentPropsWithoutRef<T>, keyof ModalPassthroughProps>) => {
      return addModal?.({
        id: `${++uid}`,
        Component,
        props: props || {},
        onClose: options.onClose
      });
    },
    [Component, options.onClose, addModal]
  );

  return { open };
}

export { useModal };
