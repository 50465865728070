import { lazy } from 'react';

import { useRoutedModal } from '~/components/modal';
import { useRouter } from '~/utils/routing/useRouter';

const CreateWorkspaceModal = lazy(() => import('~/features/workspaces/modals/CreateWorkspaceModal'));
const CreateCampaignModal = lazy(() => import('~/features/campaigns/modals/CreateCampaignModal'));
const UpgradePlanModal = lazy(() => import('~/features/billing/modals/UpgradePlanModal'));
const EditorModal = lazy(() => import('~/features/editor/modals/NewEditorModal'));

function useRoutedModals() {
  // Globally routable modals
  const router = useRouter();

  useRoutedModal(UpgradePlanModal, { queryParam: 'secretUpgradePlan' });
  useRoutedModal(CreateWorkspaceModal, { queryParam: 'showAddWorkspace' });
  useRoutedModal(CreateCampaignModal, {
    queryParam: 'showAddCampaign',
    props: { initialTab: router.query.useCase },
    close: ({ route }) => {
      delete route.query.useCase;
    }
  });
  useRoutedModal(EditorModal, {
    queryParam: 'editId',
    close: ({ route }) => {
      delete route.query.editorSidebar;
    }
  });
}

export { useRoutedModals };
