import { gql } from '~/utils/codegen';

export const GET_SUBSCRIPTION_QUERY = gql(/* GraphQL */ `
  query GetSubscription {
    subscription {
      id
      endDate
      address {
        city
        country
        postCode
        street
        state
      }
      payment {
        id
        digits
        expiry
      }
      price {
        id
        amount
        currency
        frequency
        name
        productId
      }
      update {
        startDate
        price {
          id
          amount
          currency
          frequency
          name
          productId
        }
      }
    }
  }
`);
