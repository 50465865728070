import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import awsExports from '~/config/aws-exports';

import type { S3ProviderPutConfig } from '@aws-amplify/storage';
import type { useAuth } from '~/utils/auth';
import type { Maybe } from '~/utils/codegen/graphql';

Storage.configure({
  AWSS3: {
    region: awsExports.aws_user_files_s3_bucket_region
  }
});

type GetUrlArgs = {
  key: string;
  bucket?: string;
  region?: string;
};

function getUrl({
  key,
  bucket = awsExports.aws_user_files_s3_bucket,
  region = awsExports.aws_user_files_s3_bucket_region
}: GetUrlArgs) {
  return `https://${bucket}.s3-${region}.amazonaws.com/public/${key}`;
}

function basename(path?: string) {
  return path?.split?.('/').reverse()[0];
}

type UploadFileArg = S3ProviderPutConfig & {
  file: File | Blob;
  filePath: string;
  auth?: ReturnType<typeof useAuth>;
};

async function uploadFile({ file, filePath, progressCallback, bucket, customPrefix, ...options }: UploadFileArg) {
  // We want to enhance the progress information given to the `progressCallback` by
  // always providing the percentage as well
  const opts = {
    ...options,
    customPrefix,
    bucket,
    progressCallback: (progress: Omit<Progress, 'percent'>) => {
      progressCallback?.({
        loaded: progress.loaded,
        total: progress.total,
        percent: progress.loaded / progress.total
      });
    }
  };

  const { key } = await Storage.put(filePath, file, opts);

  // Assert that a video upload has resolved correctly, known issue with aws-amplify client
  // see https://github.com/aws-amplify/amplify-js/issues/8781
  const url = await Storage.get(key, { bucket, customPrefix });
  const exists = await window.fetch(url, { headers: { Range: 'bytes=0-0' } });
  if (!exists.ok) {
    throw new Error(`Upload assertion failed for key ${key}.`);
  }

  return { key, url: getUrl({ key, bucket }) };
}

type UseUploadFileArg = Partial<UploadFileArg> & {
  uploadType?: string;
};

type Progress = {
  loaded: number;
  total: number;
  percent: number;
};

type UseUploadFnArg = Omit<Partial<UploadFileArg>, 'file'> & { file: File | Blob };

type GetFilePathArgs = {
  uploadType?: string;
  entityHashId?: Maybe<string>;
  vouchHashId?: Maybe<string>;
  accountId?: Maybe<string>;
  answerId?: Maybe<string>;
  file: File | Blob;
  fileType?: string;
};

function getFilePath({
  entityHashId,
  uploadType,
  accountId,
  vouchHashId,
  answerId,
  file,
  fileType = basename(file.type)
}: GetFilePathArgs) {
  if (uploadType === 'thumbnail') {
    return `${entityHashId}/${vouchHashId}/${answerId}_custom.${fileType}`;
  }
  return `${entityHashId}/${uploadType}/${accountId}/${uuidv4()}.${fileType}`;
}

const isMac =
  typeof window !== 'undefined' &&
  (window.navigator?.userAgentData?.platform === 'macOS' || window.navigator?.platform === 'MacIntel');

export { basename, uploadFile, getFilePath, isMac };

export type { UseUploadFnArg, UseUploadFileArg, Progress };
