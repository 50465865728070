import { lazy } from 'react';

import { useModal } from '~/components/modal';
import { usePermissions } from '~/utils/permissions';

const DuplicatePlaylistModal = lazy(() => import('~/features/playlists/modals/DuplicatePlaylistModal'));
const DuplicateVouchModal = lazy(() => import('~/features/vouches/modals/DuplicateVouchModal'));

function useDuplicatePlaylist() {
  const permissions = usePermissions();

  const { open } = useModal(permissions.canUseFeature('entity:spaces') ? DuplicateVouchModal : DuplicatePlaylistModal);
  return open;
}

export { useDuplicatePlaylist };
