import { gql } from '~/utils/codegen';

export const GET_ACCOUNTS_QUERY = gql(/* GraphQL */ `
  query GetAccounts($limit: Int, $nextToken: String) {
    accounts(limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        email
        name
        role
        useCases
        showOnboarding
        status
        updatedAt
        avatarSrc
        entity {
          id
          name
        }
      }
      count
      nextToken
    }
  }
`);
