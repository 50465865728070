import * as Sentry from '@sentry/nextjs';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth } from '~/utils/auth';

import type { Dispatch, SetStateAction } from 'react';

type UsePersistedStateArg<T> = {
  key: string;
  version?: number;
  fallback?: T;
  forceInitialState?: T;
  persistGlobal?: boolean;
};

type UseGetStorageKeyArgs = {
  key: string;
  persistGlobal?: boolean;
};

function useGetStorageKey({ key, persistGlobal }: UseGetStorageKeyArgs) {
  const auth = useAuth();
  return persistGlobal ? key : `${auth?.entity?.id}/${key}`;
}

// TODO: technically unsafe generic type in the case that the type is updated in a non-compatible way with the existing value in
// local storage. We should guard against this by adding a `parse` method; alternatively we make it good practice to update the
// `key` to include a version which changes if ever the Type change is breaking
function usePersistedState<S = undefined>({
  key,
  version = 1,
  fallback,
  forceInitialState,
  persistGlobal
}: UsePersistedStateArg<S>): [S, Dispatch<SetStateAction<S>>, () => void] {
  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        // This is a kill-switch, to completely reset the localstorage when we need to, just bump the version and it
        // will clear things - it should be treated carefully and only used as last resort, as a kill-switch should!
        const version = '1';
        const storageVersion = window.localStorage.getItem(`usePersistedState/version`);
        if (version !== storageVersion) {
          window.localStorage.clear();
          window.localStorage.setItem(`usePersistedState/version`, version);
        }
      }
    } catch (e: any) {
      e.message = `usePersistedState: version check failed - ${e.message}`;
      Sentry.captureException(e);
    }
  }, []);

  const storageKey = useGetStorageKey({ key, persistGlobal });

  const storageState = useMemo(() => {
    try {
      let fromStorage = undefined;
      try {
        fromStorage = typeof window !== 'undefined' ? window.localStorage?.getItem(storageKey) : undefined;
      } catch (e: any) {
        e.message = `usePersistedState: failed to read from localstorage - ${e.message}`;
        Sentry.setContext('custom', { storageKey });
        Sentry.captureException(e);
      }

      if (!fromStorage) {
        return;
      }

      // We only want to return the value if it's up-to-date, if it's associated with a different
      // version, ignore it
      const parsed = JSON.parse(fromStorage);
      if (parsed.version?.toString() !== version.toString()) {
        return;
      }

      return parsed.value;
    } catch (e: any) {
      e.message = `usePersistedState: failed to parse localstorage - ${e.message}`;
      Sentry.setContext('custom', { storageKey });
      Sentry.captureException(e);
    }
  }, [storageKey, version]);

  const [state, setState] = useState<S>(forceInitialState ? forceInitialState : storageState ?? fallback);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        window.localStorage?.setItem(storageKey, JSON.stringify({ value: state, version }));
      } catch (e: any) {
        e.message = `usePersistedState: failed to set localstorage - ${e.message}`;

        // The user's local storage is full, ideally we prevent this from happening, but if it does, we try to
        // recovery by clearing all local storage entries and starting fresh 💀
        if (e.type === 'QuotaExceededError') {
          try {
            Sentry.addBreadcrumb({ level: 'info', message: 'QuotaExceededError: Attempt to clear local storage' });
            window.localStorage.clear();
          } catch {
            // Do nothing, we're already sending an exception to sentry below anyway
            Sentry.addBreadcrumb({ level: 'error', message: 'QuotaExceededError: Clearing local storage failed' });
          }
        }

        Sentry.setContext('custom', { storageKey });
        Sentry.captureException(e);
      }
    }
  }, [state, storageKey, version]);

  const resetState = useCallback(() => {
    try {
      window.localStorage?.removeItem(storageKey);
    } catch (e: any) {
      e.message = `usePersistedState: failed to reset localstorage - ${e.message}`;
      Sentry.addBreadcrumb({ data: { storageKey } });
      Sentry.captureException(e);
    }
  }, [storageKey]);

  return [state, setState, resetState];
}

export { useGetStorageKey, usePersistedState };
