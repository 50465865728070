import { gql } from '~/utils/codegen';

export const GET_CAMPAIGN_COVER_SETTINGS_QUERY = gql(/* GraphQL */ `
  query GetCampaignCoverSettings($id: ID!) {
    campaign(id: $id) {
      id
      hashId
      status
      settings {
        cover {
          message
          vouch {
            ...TemplateVouch
            status
          }
        }
      }
      questions {
        items {
          id
        }
      }
    }
  }
`);
