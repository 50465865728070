import { createFocusTrap } from 'focus-trap';
import { useEffect, useRef } from 'react';

import { ButtonBar, IconButton } from '~/components/button';
import { LoadingMessage } from '~/components/loading-spinner';
import { useAnalyticsOnMount } from '~/utils/analytics/useAnalyticsOnMount';
import { styled, truncate } from '~/utils/styling';

import type { ModalPassthroughProps } from './ModalContext';
import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';

const Container = styled('div', {
  $$modalPadding: '$space$large',
  $$modalMargin: '$space$wee',
  $$modalRadius: '$radii$small',
  $$modalHeaderHeight: '4rem',
  $$modalFooterHeight: '5rem',

  position: 'fixed',
  inset: 0,
  display: 'flex',
  flexDirection: 'column',
  background: '$light-1000',
  color: '$dark-1000',
  zIndex: 200,
  margin: '$$modalMargin',
  borderRadius: '$$modalRadius',

  '@bp-small': {
    $$modalMargin: '$space$medium',
    $$modalRadius: '$radii$medium'
  },

  variants: {
    dark: {
      true: {
        color: '$light-1000',
        // NOTE: not using theme tokens because the opacity in the dark colors causes issues
        // when e.g. adding backgrounds for sticky elements :|
        background: '#333'
      }
    },

    overflowHidden: {
      true: {
        overflow: 'hidden'
      }
    }
  }
});

const Header = styled('header', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 $$modalPadding',
  height: '$$modalHeaderHeight',
  margin: 0,
  borderBottomStyle: 'solid',
  borderBottomWidth: '$thin',
  borderBottomColor: '$dark-80',
  top: 0,

  variants: {
    dark: {
      true: {
        borderBottomColor: '$dark-200'
      }
    }
  }
});

const Title = styled('h1', {
  flex: 1,
  fontSize: '$large',
  fontWeight: '$bold',
  padding: '$wee $small $wee 0',
  ...truncate({ lines: 1 })
});

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
  position: 'relative'
});

const Main = styled('main', {
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 0,

  variants: {
    padded: {
      true: {
        padding: '$$modalPadding'
      }
    },
    overflowHidden: {
      true: {
        overflow: 'hidden'
      }
    }
  },

  defaultVariants: {
    padded: true
  }
});

const Footer = styled('footer', {
  padding: '0 $$modalPadding',
  height: '$$modalFooterHeight',
  margin: 0,
  position: 'sticky',
  zIndex: 10,
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  justifyContent: 'center',
  bottom: 0,
  width: '100%',
  borderRadius: '$medium $medium 0 0',
  boxShadow: '$medium',
  background: '$light-1000',

  variants: {
    dark: {
      true: {
        background: '#262626'
      }
    }
  }
});

type FullScreenModalProps = PropsWithChildren<{
  /**
   * Unique identifier for this modal, used e.g. for analytics events
   */
  id: string;
  title?: ReactNode;
  actions?: ReactNode;
  onClose?: ModalPassthroughProps['close'];
  loading?: boolean;
  dark?: boolean;
  showHeader?: boolean;
  padded?: boolean;
  margin?: boolean;
  overflowHidden?: ComponentProps<typeof Main>['overflowHidden'];
  autoFocus?: boolean;
}>;

function FullScreenModal({
  id,
  title,
  onClose,
  actions,
  loading,
  dark,
  showHeader = true,
  padded = true,
  overflowHidden = false,
  children,
  autoFocus = true
}: FullScreenModalProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // NOTE: this is the equivalent to the standard `page_view` navigation event
  useAnalyticsOnMount('modal_viewed', {
    type: id,
    // TODO: ideal future event attributes
    modal_id: id
  });

  useEffect(() => {
    if (containerRef.current) {
      const trap = createFocusTrap(containerRef.current, {
        // Outside click should be allowed as clicks only happens on the Modal backdrop and avoid
        // blocking shadow DOM elements
        allowOutsideClick: true,
        fallbackFocus: containerRef.current,
        initialFocus: containerRef.current
      });
      trap.activate();
      return () => {
        trap.deactivate();
      };
    }
  }, []);

  // Auto focus the first input element or button if available
  useEffect(() => {
    if (autoFocus) {
      const firstFocusable = contentRef?.current?.querySelector<any>(
        '[contentEditable], input, textarea, button, [tabindex="0"]'
      );
      firstFocusable?.focus?.();
    }
  }, [loading, autoFocus]);

  // Close modal when user presses `escape`
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.();
      }
    };
    window.document.addEventListener('keydown', handleKeydown);
    return () => window.document.removeEventListener('keydown', handleKeydown);
  }, [onClose]);

  return (
    <Container id={id} ref={containerRef} data-tether-target tabIndex={-1} dark={dark} overflowHidden={overflowHidden}>
      {showHeader && (
        <Header dark={dark}>
          {title && <Title>{title}</Title>}
          <IconButton color={dark ? 'dark' : 'grey'} icon="cross" aria-label="Close modal" onClick={onClose} />
        </Header>
      )}
      <Content ref={contentRef}>
        {loading ? (
          <LoadingMessage message="Loading content..." />
        ) : (
          <>
            <Main padded={padded} overflowHidden={overflowHidden}>
              {children}
            </Main>
            {actions && (
              <Footer dark={dark}>
                <ButtonBar align="end">{actions}</ButtonBar>
              </Footer>
            )}
          </>
        )}
      </Content>
    </Container>
  );
}

export { FullScreenModal };
