import { usePlanUsage } from '~/features/billing/utils/usePlanUsage';

type UseTeamMembersLimitArg = {
  emails?: string[];
};

function useTeamMembersLimit({ emails = [] }: UseTeamMembersLimitArg = {}) {
  const { loading, usage, limit, refresh } = usePlanUsage();

  const userCount = usage?.users || 0;

  const limitReached = !!limit?.users && emails.length + userCount >= limit.users;
  const limitOverreached = !!limit?.users && emails.length + userCount > limit.users;

  return {
    loading,
    limitReached,
    limitOverreached,
    currentCount: emails.length + userCount,
    limitCount: limit?.users,
    refresh
  };
}

export { useTeamMembersLimit };
