function getAppUrl(urlString: string) {
  // For easier testing in the local environment, we replace any absolute preview/watch
  // urls we get from the BE with local ones
  if (process.env.NEXT_PUBLIC_ENV !== 'production' && process.env.NEXT_PUBLIC_APP_URL) {
    if (!urlString) {
      return urlString;
    }

    const urlObject = new URL(urlString);
    return urlString.replace(urlObject.origin, process.env.NEXT_PUBLIC_APP_URL);
  }

  return urlString;
}

export { getAppUrl };
