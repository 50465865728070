/**
 * This is a simple wrapper around `react-hooks-form` to allow render a context provider around
 * each individual form so that e.g. the `Field` component can access form functionality for that
 * specific form
 */

import { forwardRef } from 'react';

import type { useForm } from './FormContext';
import type { ForwardedRef, HTMLProps, PropsWithChildren } from 'react';

import { FormStateProvider } from './FormContext';

type FormProps = PropsWithChildren<
  Omit<HTMLProps<HTMLFormElement>, 'id' | 'form' | 'onSubmit'> & {
    form: ReturnType<typeof useForm>;
  }
>;

const Form = forwardRef(function Form({ form, children, ...props }: FormProps, ref: ForwardedRef<HTMLFormElement>) {
  const { submit, id, ...rest } = form;
  return (
    <FormStateProvider form={rest}>
      <form {...props} ref={ref} id={id} onSubmit={submit} noValidate>
        {children}
      </form>
    </FormStateProvider>
  );
});

export { Form };
